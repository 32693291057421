<template>
  <div class="createChariteerAccountFormBox">
    <form class="createChariteerAccountForm" @submit.prevent="onSubmit">
      <!-- Introduction -->
      <p>
        Welcome to Charity Quest, a free network of volunteers and charities in
        Hudson County, NJ! Please fill out the information below and click the
        "Create this account" button to create your account.<br /><br />Once you
        create it, your account can also be used to access the free Charity
        Quest mobile app.
      </p>
      <br />

      <!-- First Name -->
      <div class="labelInputRow">
        <label for="chariteerInputFirstName">First Name</label>
        <input
          id="chariteerInputFirstName"
          v-model="chariteerInputFirstName"
          type="text"
          placeholder="Jane"
          required
        />
      </div>

      <!-- Last Name -->
      <div class="labelInputRow">
        <label for="chariteerInputLastName">Last Name</label>
        <input
          id="chariteerInputLastName"
          v-model="chariteerInputLastName"
          type="text"
          placeholder="Smith"
          required
        />
      </div>

      <!-- Phone Number -->
      <div class="labelInputRow">
        <label for="chariteerInputPhone">Cell Phone Number</label>
        <p>
          Phone numbers must be exactly 10 digits long. Include numbers only,
          please. Do not include the leading "1" at the beginning.
        </p>
        <input
          id="chariteerInputPhone"
          v-model="chariteerInputPhone"
          type="text"
          placeholder="800-123-4567"
          maxlength="10"
          @input="validatePhoneNumberInput"
          required
        />
      </div>

      <!-- Date of Birth -->
      <div class="labelInputRow">
        <label for="chariteerInputDOB">Date of Birth</label>
        <input
          id="chariteerInputDOB"
          v-model="chariteerInputDOB"
          type="date"
          max="2010-01-01"
          min="1920-12-31"
          required
        />
      </div>

      <!-- Email Address -->
      <div class="labelInputRow">
        <label for="chariteerInputEmail">Email Address</label>
        <input
          id="chariteerInputEmail"
          v-model="chariteerInputEmail"
          type="email"
          placeholder="myemail@website.com"
          required
        />
      </div>

      <!-- Username -->
      <div class="labelInputRow">
        <label for="chariteerInputUsername">Username</label>
        <p>
          We'll display this Username to other volunteers on event listings and
          leaderboards instead of your real name. Please don't include any
          sensitive personal information in your Username.
        </p>
        <ul>
          <li>The minimum Username length is 3 characters.</li>
          <li>The maximum Username length is 20 characters.</li>
          <li>
            Uppercase and lowercase letters, as well as and numbers, are
            allowed.
          </li>
          <li>
            No special characters like spaces, underscores, or symbols are
            allowed.
          </li>
        </ul>
        <input
          id="chariteerInputUsername"
          v-model="chariteerInputUsername"
          type="text"
          placeholder="CoolVolunteer64"
          minlength="3"
          maxlength="20"
          required
        />
      </div>

      <!-- Password 1 -->
      <div class="labelInputRow">
        <label for="chariteerInputPassword1">Password</label>
        <p>Your password must be at least 6 characters long.</p>
        <input
          id="chariteerInputPassword1"
          v-model="chariteerInputPassword1"
          type="password"
          placeholder="Password"
          minlength="6"
          required
        />
      </div>

      <!-- Password 2 -->
      <div class="labelInputRow">
        <label for="chariteerInputPassword2">Confirm Password</label>
        <p>Enter the password above again, just to be sure.</p>
        <input
          id="chariteerInputPassword2"
          v-model="chariteerInputPassword2"
          type="password"
          placeholder="Password"
          minlength="6"
          required
        />
      </div>

      <input
        class="registerButton"
        type="submit"
        value="CREATE THIS ACCOUNT   ►"
      />
    </form>
  </div>
</template>

<script>
export default {
  name: "CreateChariteerAccountForm",
  data() {
    return {
      chariteerInputFirstName: "",
      chariteerInputLastName: "",
      chariteerInputPhone: "",
      chariteerInputDOB: "",
      chariteerInputEmail: "",
      chariteerInputUsername: "",
      chariteerInputPassword1: "",
      chariteerInputPassword2: "",
      chariteerResultingID: ""
    };
  },
  methods: {
    onSubmit() {
      // Breaks without this:
      window.PlayFab.settings.titleId = "FEAAC";

      // Little checks
      if (
        this.chariteerInputEmail.substring(
          this.chariteerInputEmail.length - 4,
          this.chariteerInputEmail.length
        ) == ".con"
      ) {
        alert(
          "Your email includes a possible typo: " +
            this.chariteerInputEmail +
            "\n\nDid you mean .com instead of .con?"
        );
        return;
      }

      if (this.chariteerInputPhone.length != 10) {
        alert("Your phone number must be exactly 10 digits!");
        return;
      }

      // Time to validate this input!
      if (this.chariteerInputPassword1 == this.chariteerInputPassword2) {
        // alert ("Valid registration. No errors.")

        var registrationRequest = {
          DisplayName: this.chariteerInputUsername,
          Email: this.chariteerInputEmail,
          Password: this.chariteerInputPassword1,
          Username: this.chariteerInputUsername
        };

        window.PlayFabClientSDK.RegisterPlayFabUser(
          registrationRequest,
          this.RegisterNewChariteerCallback
        );
      } else {
        alert(
          "Your passwords don't match. Please check them again. Both password boxes must be identical."
        );
      }
    },
    RegisterNewChariteerCallback(result, error) {
      // console.log("Callback from registration begins.")

      if (result != null) {
        // console.log("New account created!")
        // console.log(result)
        // console.log("PlayFabID? " + result.data.PlayFabId);
        this.chariteerResultingID = result.data.PlayFabId;
        this.$store.state.playerProfile.userAuthorizationToken =
          result.data.EntityToken.EntityToken;

        // console.log("The DOB comes out as " + this.chariteerInputDOB )

        // Time for default account values
        var params = {
          firstName: this.chariteerInputFirstName,
          lastName: this.chariteerInputLastName,
          phoneNumber: this.chariteerInputPhone,
          dob: this.chariteerInputDOB
        };

        var executeCloudScriptRequest = {
          FunctionName: "giveNewChariteerAccountDefaultValues",
          FunctionParameter: params,
          GeneratePlayStreamEvent: true
        };

        window.PlayFabClientSDK.ExecuteCloudScript(
          executeCloudScriptRequest,
          this.DefaultAccountValuesCallback
        );

        // Add the contact email to the account
        var addOrUpdateContactEmailRequest = {
          EmailAddress: this.chariteerInputEmail
        };
        window.PlayFabClientSDK.AddOrUpdateContactEmail(
          addOrUpdateContactEmailRequest,
          this.AddOrUpdateContactEmailCallback
        );

        // We're not on the database.
        this.$store.state.playerProfile.chariteerExistsInDatabase = false;
        this.createChariteerAccountOnDatabase();
        // Screw this this.createChariteerAccountOnDatabase();
        // This didn't work and I ran out of patience. They'll have to do the thing.

        // Route the player to Login now
        alert(
          "Thank you for creating your account! You'll be taken to the login page now. Check your email later for a verification message."
        );
        this.$router.push("/login");
      } else if (error != null) {
        // console.log("Registration caused some kind of error.")
        window.PlayFab.GenerateErrorReport(error);
        // send up an alert I guess

        if (error.error == "InvalidParams") {
          alert(
            "Either your Email Address or your Username could not be validated. Check the spelling and make sure the information you entered follows the rules for Username creation."
          );
        } else if (
          error.error == "NameNotAvailable" ||
          error.error == "UsernameNotAvailable"
        ) {
          alert(
            "Another user claimed this username already. Usernames in Charity Quest must be unique. Did you already create a Charity Quest account with this name?\n\nPlease enter a different username and try again."
          );
        } else if (error.error == "EmailAddressNotAvailable") {
          alert(
            "Another user is using this email address already, so you can't use it for your account. Did you already create a Charity Quest account with this email?\n\nPlease enter a different email address and try again."
          );
        }
      }
    },
    DefaultAccountValuesCallback(result, error) {
      if (result != null) {
        // console.log("Default values set!")
      } else if (error != null) {
        // console.log("Couldn't set default values.")
      }
    },
    AddOrUpdateContactEmailCallback(result, error) {
      if (result != null) {
        // console.log("Contact email added to account!")
      } else if (error != null) {
        // console.log("Problem initially adding contact email to account.")
        // console.log(error)
      }
    },
    async createChariteerAccountOnDatabase() {
      // console.log("Creating account on database...");

      // Execution
      const result = await this.mutationCreateChariteerAccount();

      if (result.errors?.length > 0) {
        // console.log("Error in creating Chariteer account!");
        const errorMessage = result.errors[0].extensions.response.message;
        // console.log("ERROR: " + errorMessage); These messages are more for me than them, they're essentially sub-codes.
        // (This is how you include stuff) this.notifyError(`${errorMessage} \n\n Sorry, this Quest already ended. For some reason, the system is still letting you sign up even though it ended. Sorry about that!`);
        // this.notifyError(`<div>`)

        const errorCode = result.errors[0].extensions.response.statusCode;
        // console.log("CODE: " + errorCode);

        /*
          // Missing Items
          if ( errorCode == 404 && errorMessage == "Quest Not Found" )
            this.notifyError("ERROR! This is a glitch. For some reason, we can't find the Quest you're trying to interact with, but the system still allowed you to try. Sorry about that!");
          else if ( errorCode == 404 && errorMessage == "Provider Not Found" )
            this.notifyError("ERROR! This is a glitch. For some reason, we can't find the Quest Provider you're trying to interact with, but the system still allowed you to try. Sorry about that!");
          else if ( errorCode == 404 && errorMessage == "Chariteer Not Found" )
            this.notifyError("ERROR! This is a glitch. For some reason, we can't find the person you're trying to interact with, but the system still allowed you to try. Sorry about that!");
          else if ( errorCode == 404 && errorMessage == "Contract Not Found" )
            this.notifyError("ERROR! This is a glitch. For some reason, we can't find the item you're trying to interact with, but the system still allowed you to try. Sorry about that!");

          // Logical Issues
          else if ( errorCode == 406 && errorMessage == "Chariteer Is Already On This Quest" )
            this.notifyError("ERROR! This is a glitch, you're already signed up for this Quest. For some reason, the system is still letting you sign up again. Sorry about that!");
          else if ( errorCode == 406 && errorMessage == "Quest Is Full" )
            this.notifyError("ERROR! Sorry, this Quest already has enough volunteers. For some reason, the system is still letting you sign up even though it's full. Sorry about that!");
          else if ( errorCode == 410 && errorMessage == "Quest Already Over, Can't Sign Up")
            this.notifyError("ERROR! Sorry, this Quest already ended. For some reason, the system is still letting you sign up even though it ended. Sorry about that!");
          else
            this.notifyError("UNKNOWN ERROR! An unexpected error occurred. Sometimes the problem is the Wi-Fi network you're using, which could be blocking Charity Quest. Try switching over just to Mobile Data and try that again. If you keep seeing this error, email me at frank@charityquest.io");
          */
      } else {
        // console.log("Chariteer Account created.");
        this.$store.state.playerProfile.chariteerExistsInDatabase = true;
      }
    },
    async mutationCreateChariteerAccount() {
      const result = await fetch(process.env.VUE_APP_DATABASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.playerProfile.userAuthorizationToken
        },
        body: JSON.stringify({
          query: `
              mutation CreateChariteer ($CCI: CreateChariteerInput!) {
                createChariteer(createChariteerInput: $CCI) {
                  id,
                  username,
                  emailAddress,
                  phoneNumber,
                  dateOfBirth,
                  playerAccountType,
                  nameFirst,
                  nameLast
                }
              }
            `,
          variables: {
            CCI: {
              id: this.chariteerResultingID,
              username: this.chariteerInputUsername,
              emailAddress: "" + this.chariteerInputEmail,
              phoneNumber: this.chariteerInputPhone,
              dateOfBirth: this.chariteerInputDOB,
              playerAccountType: "Chariteer",
              nameFirst: this.chariteerInputFirstName,
              nameLast: this.chariteerInputLastName
            }
          }
        })
      });

      // console.log(result);
      const data = await result.json();
      // console.log(data); //data can have data.errors

      return data;
    },
    validatePhoneNumberInput() {
      // Ensure that this is not over 10 digits long, and is numbers only
      if (this.chariteerInputPhone.match(/^[0-9]+$/) != null) {
        console.log("It's a number!");
      } else {
        console.log("Birn, hellfield!");
        this.chariteerInputPhone = this.chariteerInputPhone.substring(
          0,
          this.chariteerInputPhone.length - 1
        );
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.createChariteerAccountFormBox {
  background-color: #00000000;
  width: 600px;
  padding: 0px;
  margin: 0px auto; /* Set the box to be centered */
}

p {
  text-align: justify;
  margin: 0px;
}

h4 {
  color: #ffffff;
  font-weight: 500;
}

.createChariteerAccountForm {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  background-color: #ffffff;
  text-align: left;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.25);
}

.labelInputRow {
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 15px;
  padding-right: 20px;
  padding-left: 15px;
  padding-bottom: 15px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
}

.labelInputRow label {
  color: #444444;
  font-weight: 600;
}

.labelInputRow p {
  color: #44444491;
  font-size: 75%;
}

.labelInputRow ul {
  color: #44444491;
  font-size: 75%;
  margin: 0px;
  padding-left: 30px;
}

::placeholder {
  color: #aaaaaa;
}

/* go back to this if things like the date get screwed up [type=text] */
.labelInputRow input {
  color: #444444;
  border: none;
  border-bottom: 2px solid #838383;
  margin-top: 5px;
  padding-top: 10px;
  padding-left: 5px;
  padding-bottom: 10px;
  width: 100%;
}

.registerButton {
  background-color: #ffa94e;
  border: none;
  border-radius: 4px;
  margin-top: 30px;
  padding: 10px 50px;
  outline: none;

  color: #ffffff;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.15s;
  cursor: pointer;
  /* box-shadow: 0 1px 1px 1px rgba(0,0,0,0.1); */
}

.registerButton:hover {
  background-color: #fdbd78;
}

.registerButton:active {
  background-color: #ffa13c;
  transform: translateY(1px);
}

@media only screen and (max-width: 600px) {
  .createChariteerAccountFormBox {
    width: 100%;
  }
}
</style>
