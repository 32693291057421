<template>
  <div class="grow">
    <!-- Modals Live Here -->
    <!--
    <div v-show="singleQuestDetailModalIsVisible" class="fixed">
      <div class="z-40 inset-0 bg-black opacity-75 flex items-center justify-center" @click="handleQuestModalClose()">
      </div>
      <div class="z-50 mx-auto bg-white opacity-100">
          <p>This is {{ clickedQuestData?.eventTitle }}</p>
        </div>
    </div>
    -->

    <!-- Top Control Panel -->
    <!-- <div> -->
    <!-- RBAC TEST: {{ "Ability: " + guildRBACPolicyObject.groupRBAC[0].ability }} -->
    <!-- Dropdown: {{ this.managedProviderMemberships }}
      <div v-for="groupInfo in this.managedProviderMemberships" :key="groupInfo.Group.Id">
        <button @click="(e) => handleProviderGroupClick(groupInfo, e)">{{ groupInfo.GroupName }}</button>
      </div> -->
    <!-- <GuildSelector :onSelection="handleGuildSelection" /> -->
    <!-- </div> -->

    <!-- The Calendar -->
    <div class="shadow-lg w-full w-[80vw] overflow-x-auto" v-show="!loading">
      <Calendar
        :masks="masks"
        :attributes="attributes"
        disable-page-swipe
        is-expanded
        trim-weeks
        transparent
        @update:fromPage="userSwitchesCalendarPage"
        ref="calendar"
      >
        <template v-slot:day-content="{ day, attributes }">
          <!-- The "Day" Boxes -->
          <div
            class="min-h-[160px] h-full bg-transparent"
            clickable="dayBox"
            @click="e => handleDayClick(day, e)"
          >
            <!-- The numerical day appearing -->
            <span class="text-sm text-center text-gray-900">{{ day.day }}</span>
            <!-- The Line Items-->
            <div v-for="attr in attributes" :key="attr.key">
              <Popper @open:popper="openCalendarQuest">
                <!-- This is a clickable Calendar Event (e.g. a Quest) -->
                <div
                  class="overflow-y-auto overflow-x-auto bg-transparent border-none p-0 mx-1 my-1.5 text-left cursor-pointer"
                  :class="attr.customData.class"
                >
                  <p
                    class="text-xs leading-tight rounded-sm p-0 m-0 cursor-pointer"
                    clickable="calendarQuest"
                  >
                    <span
                      :class="
                        determinePublishingStatusBubbleStyle(
                          attr.customData.questInfo?.publishingStatus
                        )
                      "
                    >
                      {{
                        crunchPublishingStatusIntoShortCalendarVersion(
                          attr.customData.questInfo?.publishingStatus
                        )
                      }}
                    </span>
                    {{
                      crunchTimeIntoShortCalendarVersion(
                        attr.customData.questInfo?.startedAt
                      )
                    }}
                    {{ attr.customData.title }}
                    <template
                      v-if="
                        attr.customData.questInfo?.publishingStatus ==
                          'Cancelled'
                      "
                    >
                      {{ "✕" }}
                    </template>
                    <template
                      v-else-if="
                        attr.customData.questInfo?.publishingStatus == 'Draft'
                      "
                    >
                      {{ "" }}
                    </template>
                    <template v-else>
                      <span
                        class="rounded-full px-2"
                        :class="
                          determineVolunteerBubbleColor(
                            attr.customData.questInfo
                          )
                        "
                      >
                        {{
                          determineActualVolunteerCount(
                            attr.customData.questInfo?.pledgedContracts
                          )
                        }}
                      </span>
                    </template>
                  </p>
                  <!-- This smaller card is for the name of a Raiding Guild -->
                  <template
                    v-if="
                      determineIfQuestIsBeingRaided(
                        attr.customData.questInfo?.pledgedContracts
                      )
                    "
                  >
                    <p class="bg-cq-orange-100 rounded m-0 px-2 text-xs">
                      {{
                        determineRaidingGuildName(
                          attr.customData.questInfo?.pledgedContracts
                        )
                      }}
                    </p>
                  </template>
                  <!-- Also I think Feasts should display the vol name, similar to raiding above -->
                </div>
                <template #content="{ close }">
                  <div
                    class="bg-white z-90 p-2 drop-shadow-lg rounded min-w-[450px]"
                  >
                    <!-- The Control Row -->
                    <div class="flex flex-row-reverse">
                      <button
                        class="bg-gray-200 hover:bg-gray-300 rounded-full p-2 px-3 border-none"
                        @click="close"
                      >
                        ✕
                      </button>
                      <!-- Does this user have RBAC permission to "Update" (Edit) a Quest? -->
                      <button
                        v-if="
                          determineIfQuestHasNotStartedYet(
                            attr.customData.questInfo
                          ) && userCanEditQuests
                        "
                        class="bg-gray-200 hover:bg-cq-orange text-gray-800 font-bold py-2 px-4 mr-3 rounded border-none inline-flex items-center"
                        @click="
                          e => {
                            handleEditQuestClick(attr.customData.questInfo, e);
                            close(e);
                          }
                        "
                      >
                        <img
                          class="w-4 h-4 mr-2"
                          alt="A little pencil, meant for editing the Quest."
                          src="../../assets/navicons/CQ-Navicon_Pencil.png"
                        />
                        <span>Edit Quest</span>
                      </button>
                      <!-- Does this user have RBAC permission to "Delete" (Cancel) a Quest? -->
                      <button
                        v-if="
                          determineIfQuestHasNotStartedYet(
                            attr.customData.questInfo
                          ) && userCanCancelQuests
                        "
                        class="bg-gray-200 hover:bg-red-400 text-gray-800 font-bold py-2 px-4 mr-3 rounded border-none inline-flex items-center"
                        @click="
                          e =>
                            handleCancelQuestClick(attr.customData.questInfo, e)
                        "
                      >
                        <img
                          class="w-4 h-4 mr-2"
                          alt="A big X, meant for cancelling the Quest."
                          src="../../assets/navicons/CQ-Navicon_X.png"
                        />
                        <span>Cancel Quest</span>
                      </button>
                      <!--
                      <select @change="handlePublishingStatusChange">
                        <option disabled value="disabled">None</option>
                        <option value="Draft">Draft</option>
                        <option value="Published">Published</option>
                        <option value="Unlisted">Unlisted</option>
                        <option value="Cancelled">Cancelled</option>
                        <option value="Private">Private</option>
                      </select>
                      -->
                    </div>
                    <br />

                    <!-- The Title Block -->
                    <div class="text-black ml-10 mt-2">
                      <!-- Title -->
                      <p class="font-black text-lg m-0">
                        {{ attr.customData.title }}
                      </p>

                      <!-- The Time Row is right underneath the Title. -->
                      <p class="m-0">
                        {{
                          questDayDisplay(
                            attr.customData.questInfo?.startedAt
                          ) +
                            " ⋅ " +
                            questStartTimeDisplay(
                              attr.customData.questInfo?.startedAt
                            ) +
                            " " +
                            optionalTimeHyphen(
                              attr.customData.questInfo?.endedAt
                            ) +
                            " " +
                            questEndTimeDisplay(
                              attr.customData.questInfo?.endedAt
                            )
                        }}
                      </p>

                      <!-- TEMP -->
                      <p>
                        {{
                          "Status: " +
                            attr.customData.questInfo?.publishingStatus
                        }}
                      </p>
                    </div>

                    <!-- The Info Block -->
                    <div class="mt-5">
                      <!-- The Location Row, which is optional, displays a map tick icon + the name of the address + has a button for CHANGE LOCATION -->
                      <div class="flex items-center">
                        <img
                          class="w-10 h-10 m-2"
                          alt="A map pin."
                          src="../../assets/navicons/CQ-Navicon_MapTick.png"
                        />
                        <div>
                          <p class="text-gray-700 m-0">
                            {{ attr.customData.questInfo?.location.name }}
                          </p>
                          <p class="text-gray-700 text-xs m-0">
                            {{ attr.customData.questInfo?.location.address }}
                          </p>
                        </div>
                      </div>

                      <!-- The Group Row, which depends on the type of Quest -->
                      <div
                        v-if="attr.customData.questInfo?.eventType == 'OSV'"
                        class="flex items-center"
                      >
                        <img
                          class="w-10 h-10 m-2"
                          alt="A guild standard."
                          src="../../assets/quest-guild-flag.png"
                        />
                        <!-- This should also be one of those sexy dropdowns -->
                        <div
                          v-if="
                            determineRaidingGuildName(
                              attr.customData.questInfo?.pledgedContracts
                            ) == 'No Group'
                          "
                          class="flex items-center justify-between"
                        >
                          <p class="text-gray-400 italic m-0">
                            {{
                              determineRaidingGuildName(
                                attr.customData.questInfo?.pledgedContracts
                              )
                            }}
                          </p>
                          <template v-if="guildSelectorIsVisible">
                            <GuildSelector
                              class="ml-5"
                              :onSelection="handleGuildSelection"
                              :questData="attr.customData.questInfo"
                            />
                          </template>
                        </div>
                        <div v-else class="flex flex-row items-center">
                          <div id="The Text">
                            <p class="text-black m-0">
                              {{
                                determineRaidingGuildName(
                                  attr.customData.questInfo?.pledgedContracts
                                ) +
                                  " (" +
                                  determineRaidingGuildCode(
                                    attr.customData.questInfo?.pledgedContracts
                                  ) +
                                  ")"
                              }}
                            </p>
                            <p class="text-gray-500 text-xs m-0">
                              Reserved by
                              {{
                                determineRaidingGuildPointOfContact(
                                  attr.customData.questInfo?.pledgedContracts
                                )
                              }}
                            </p>
                          </div>
                          <div id="The Button">
                            <button
                              class="bg-gray-200 hover:bg-red-400 text-gray-800 font-bold py-2 px-4 ml-3 rounded border-none inline-flex items-center"
                              @click="
                                e =>
                                  handleReleaseGroupReservation(
                                    attr.customData.questInfo,
                                    e
                                  )
                              "
                            >
                              Release Reservation
                            </button>
                          </div>
                        </div>
                      </div>

                      <!-- The "Resource" Row, which is complex but is typically volunteers. Could also be Supply Requests, or money raised -->
                      <div class="flex items-center">
                        <!-- This should probably be a WILL CIRCLE -->
                        <img
                          class="w-10 h-10 m-2"
                          alt="A picture of three people."
                          src="../../assets/navicons/CQ-Navicon_People.png"
                        />
                        <div>
                          <!-- Supervisor Row -->
                          <p
                            v-if="attr.customData.questInfo?.giverName == ''"
                            class="text-red-400 m-0"
                          >
                            No Supervisor Assigned!
                          </p>
                          <p v-else class="text-gray-500 m-0">
                            Supervisor:
                            {{ attr.customData.questInfo?.giverName }}
                          </p>
                          <!-- There should be a calendar filter that's like "highlight all the Quests without supervisors please" -->

                          <p
                            v-if="
                              attr.customData.questInfo?.publishingStatus ==
                                'Cancelled'
                            "
                            class="text-gray-400 text-xs font-normal m-0"
                          >
                            Volunteers cannot sign up for cancelled Quests.
                          </p>
                          <p
                            v-else-if="
                              determineActualVolunteerCount(
                                attr.customData.questInfo?.pledgedContracts
                              ) == 0
                            "
                            class="text-gray-400 text-xs font-normal m-0"
                          >
                            No volunteers yet
                            {{
                              "(Maximum: " +
                                attr.customData.questInfo?.resourceMaxNumber +
                                ")"
                            }}
                          </p>
                          <p
                            v-else
                            class="text-gray-500 text-xs font-normal m-0"
                          >
                            {{
                              determineTopVolunteers(
                                attr.customData.questInfo?.pledgedContracts
                              ) +
                                " (Maximum: " +
                                attr.customData.questInfo?.resourceMaxNumber +
                                ")"
                            }}
                          </p>
                          <!-- Supply Request that hasn't been claimed: say "Not Claimed Yet" -->
                        </div>
                      </div>

                      <!-- Test buttons
                      <div>
                        <button @click="(e) => handleReserveQuestForGroup(attr.customData.questInfo, e)">Reserve Quest</button>
                        <button @click="(e) => handleReleaseGroupReservation(attr.customData.questInfo, e)">Drop Reservation</button>
                      </div>
                      -->
                    </div>
                  </div>
                </template>
              </Popper>
            </div>
          </div>
        </template>
      </Calendar>
      <!-- <DatePicker /> -->
    </div>
    <!-- Loading, please wait... -->
    <div
      v-show="loading"
      class="text-white text-center bg-cq-orange min-h-[500px]"
    >
      <p class="pt-40 text-4xl font-black">
        Loading the Calendar, please wait...
      </p>
    </div>

    <!-- Quest Stamping Modal -->
    <div
      class="bg-white p-5 z-50 rounded fixed z-90 top-0 left-0"
      v-if="addSingleQuestModalIsVisible"
    >
      <!-- Blackground -->
      <div
        class="bg-black opacity-50 fixed top-0 left-0 w-screen h-screen"
      ></div>

      <!-- Modal content -->
      <div
        class="bg-gray-100 fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 p-5 min-w-[750px] rounded"
      >
        <!-- Close -->
        <button
          class="cursor-pointer bg-gray-200 hover:bg-gray-200 font-bold px-3 py-2 m-0 float-right border-none rounded-full"
          @click="e => handleAddSingleQuestModalClose(e)"
        >
          ⨯
        </button>
        <!-- Check 1: Is this Date in the past? -->
        <template
          class=""
          v-if="isDateInPresentOrFuture(this.currentlySelectedDay) == false"
        >
          <h2>{{ this.currentlySelectedDay.ariaLabel }} is in the past.</h2>
          <p>
            Quests must be posted on a date when volunteers can sign up for
            them, but the date you selected is in the past.
          </p>
          <p>
            If there's an issue with your volunteer records you need to resolve,
            send me an email at frank@charityquest.io and I'll be happy to help!
          </p>
        </template>
        <!-- Check 2: Is this Date too far in the future? -->
        <template
          class=""
          v-else-if="isDateBeyondVeilOfTime(this.currentlySelectedDay) == true"
        >
          <h2>{{ this.currentlySelectedDay.ariaLabel }} is unavailable.</h2>
          <p>
            To keep all volunteer listings relevant & short-term, you are not
            allowed to post Quests past the "Veil of Time", which is currently
            October 1st, 2024.
          </p>
          <p>
            Need to post something special, like a fundraiser, far in advance?
            Send me an email at frank@charityquest.io, I'm happy to help!
          </p>
          <p>
            Otherwise, please wait until the end of this month, when the Veil of
            Time will extend further into the future.
          </p>
        </template>
        <!-- Tab 1: Choosing a Stamp -->
        <template class="" v-else-if="addSingleQuestModalTab1IsVisible">
          <h2>
            Adding a Draft Quest to {{ this.currentlySelectedDay.ariaLabel }}
          </h2>
          <br />
          <p>
            Select a Quest from the stamp collection on the left, then choose a
            date!
          </p>
          <p>
            Do you need to edit a stamp, or add a new one? Send me an email at
            frank@charityquest.io and I'll handle that for you!
          </p>
          <!--
          <div class="">
            <button class="" @click="(e) => handleToggleStampSelectDropDown(e)">Select a Stamp</button>
            <div class="" v-show="addSingleQuestModalStampDropDownIsVisible">
              <ul v-for="stamp in this.$store.state.currentlySelectedStampCollection.stamps" v-bind:key="stamp.index">
                <li class="list-none">
                  <a href="#" v-on:click="handleAddSingleQuestModalChooseStamp(stamp.index)">{{ stamp.eventTitle }}</a>
                </li>
              </ul>
            </div>
          </div>
          -->
        </template>
        <!-- Tab 2: Choosing times and a location -->
        <template class="" v-else-if="addSingleQuestModalTab2IsVisible">
          <h3>
            Adding the Quest
            {{
              "\“" + this.$store.state.currentlySelectedStamp?.stampTitle + '"'
            }}
            to {{ this.currentlySelectedDay?.ariaLabel }}
          </h3>
          <div class="">
            <!-- Start and End Time -->
            <div class="flex flex-row gap-x-2">
              <div>
                <label>Start Time: </label>
                <input
                  type="time"
                  v-model="temporarilyChosenStartTime"
                  id="appt"
                  name="appt"
                  step="300"
                />
              </div>
              <div class="pl-5">
                <label>End Time: </label>
                <input
                  type="time"
                  v-model="temporarilyChosenEndTime"
                  id="appt"
                  name="appt"
                  step="300"
                  :class="
                    determineEndTimeInputStyle(
                      temporarilyChosenStartTime,
                      temporarilyChosenEndTime
                    )
                  "
                />
              </div>
            </div>
            <div
              v-if="
                temporarilyChosenEndTime != null &&
                  temporarilyChosenEndTime <= temporarilyChosenStartTime
              "
            >
              <p class="text-red-500 font-bold">
                End Time must be after Start Time!
              </p>
            </div>

            <!-- Quest Giver Name -->
            <div>
              <label>On-site Supervisor Name: </label>
              <input
                type="text"
                v-model="temporarilyChosenSupervisorName"
                :placeholder="
                  this.$store.state.currentlySelectedStamp?.giverName
                "
                id="giverName"
                name="giverName"
              />
            </div>

            <!-- Where do "approved locations" come from, exactly? Well, it's a field in Provider. approvedLocations -->
            <!-- For now, much like Stamps, we can hardcode this -->

            <!-- Hardcoded Locations based on Provider ID -->
            <div v-if="this.currentlySelectedLocationConfig?.length > 0">
              <div
                v-for="category in this.currentlySelectedLocationConfig"
                :key="category.name"
              >
                <p class="text-black font-bold text-left">
                  {{ category.name }}:
                </p>
                <div v-for="location in category.locations" :key="location.id">
                  <button
                    class="w-48 text-gray-700 text-left p-1 border border-gray-100 hover:border-gray-400 rounded-md shadow-md cursor-pointer"
                    :class="determineApprovedLocationButtonStyle(location)"
                    @click="e => handleLocationClick(location, e)"
                  >
                    <p class="font-bold">{{ location.name }}</p>
                    <p class="text-xs">{{ location.address }}</p>
                    <p class="text-xs">{{ location.detail }}</p>
                  </button>
                </div>
              </div>
            </div>
            <!-- Legacy Hardcoded way -->
            <div
              v-else-if="
                this.$store.state.currentlySelectedApprovedLocations
                  ?.questLocations?.length > 0
              "
              class="flex flex-col"
            >
              <p class="text-black font-bold text-left">
                Approved Locations:
              </p>
              <div class="grid grid-cols-4 mr-5">
                <div
                  class="mx-5 my-1 py-1"
                  v-for="location in this.$store.state
                    .currentlySelectedApprovedLocations.questLocations"
                  :key="location.index"
                >
                  <button
                    class="w-48 text-gray-700 text-left p-1 border border-gray-100 hover:border-gray-400 rounded-md shadow-md cursor-pointer"
                    :class="determineApprovedLocationButtonStyle(location)"
                    @click="e => handleLocationClick(location, e)"
                  >
                    <p class="font-bold">{{ location.name }}</p>
                    <p class="text-xs">{{ location.address }}</p>
                    <p class="text-xs">{{ location.detail }}</p>
                  </button>
                </div>
              </div>
            </div>
            <div v-else>
              <p>Your charity does not have any approved locations yet.</p>
            </div>

            <!-- Maybe Giver? IDK... that will probably be above -->
          </div>

          <!-- The Stamp Button OR the Disclaimer-->
          <div class="flex justify-center pt-5">
            <template
              v-if="
                userCanCreateQuests &&
                  this.singleQuestValidAndReadyToPost == true
              "
            >
              <button
                class="cursor-pointer bg-green-400 hover:bg-green-500 text-gray-800 font-bold py-2 px-4 mr-3 rounded border-none inline-flex items-center"
                v-on:click="createSingleQuest()"
              >
                Add this Quest
              </button>
            </template>
            <template
              v-else-if="
                userCanCreateQuests &&
                  this.singleQuestValidAndReadyToPost == false
              "
            >
              <button
                class="cursor-not-allowed bg-gray-400 text-gray-800 font-bold py-2 px-4 mr-3 rounded border-none inline-flex items-center"
                disabled
              >
                To Add this Quest, please fill out the details above.
              </button>
            </template>
            <template v-else>
              <button class="" disabled>
                You don't have permission to post Quests
              </button>
            </template>
          </div>
        </template>
      </div>
    </div>

    <!-- Edit Quest Modal -->
    <EditQuestModal
      v-if="editQuestModalIsVisible"
      :handleSubmitChanges="handleSubmitQuestUpdate"
      :close="handleCloseEditQuestModal"
      :questData="editQuestInfo"
    />

    <!-- MOAR! -->
  </div>

  <!-- Loader goes here -->
  <!-- TO DO -->
</template>

<script>
import { Calendar, DatePicker } from "v-calendar";
import "../../vcalendar_style.css";
import { useQuery } from "@vue/apollo-composable";
import { useRoute } from "vue-router";
import gql from "graphql-tag";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import {
  siftVolunteerContracts,
  siftGuildContracts,
  isChariteerAllowedToPerformAction
} from "@/helpers/index.js";
import GuildSelector from "../selectors/GuildSelector.vue";
import EditQuestModal from "../modals/EditQuestModal.vue";
import { defineComponent, ref } from "vue";
import Popper from "vue3-popper";
import { DateTime } from "luxon";
import { ProvidedRequiredArgumentsRule } from "graphql";

const selectedLocation = ref("");

export default defineComponent({
  components: {
    Calendar,
    Popper,
    // DatePicker,
    EditQuestModal,
    GuildSelector
  },
  props: ["managedProviderMemberships", "selectedManagedProviderId"],
  watch: {
    selectedManagedProviderId: {
      async handler(newProviderID) {
        await this.chooseApprovedLocationsCollection(newProviderID);
        this.refetch({
          GQI: {
            specificProvider: newProviderID,
            dateRangeBeginning: this.$refs.calendar
              .calendarDisplayStartDateTime,
            dateRangeEnding: this.$refs.calendar.calendarDisplayEndDateTime
          }
        });
      },
      immediate: true
    }
  },
  // watch: {
  //   selectedManagedProviderId: {
  //     async handler(newProviderID) {
  //       await this.chooseApprovedLocationsCollection(newProviderID);
  //     },
  //     immediate: true
  //   }
  // },
  setup(props) {
    const notifyError = text => {
      toast.error(text, {
        autoClose: false
      }); // ToastOptions
    };

    const notifySuccess = text => {
      toast.success(text, {
        autoClose: 10000
      }); // ToastOptions
    };

    // console.log("Props: " + JSON.stringify(props));
    // var initialProviderID = props.managedProviderMemberships.find(item => true)?.ManagedProviderId;
    // var initialProviderID = props.selectedManagedProviderId;
    // if initialProviderID is null, redirect the user elsewhere

    // HARDCODE
    // initialProviderID = "847F21FAD0FFC647";
    // console.log("Initial Calendar to display is from charity " + initialProviderID);

    let initialDate = new Date();
    console.log("The initial date would be " + initialDate + ".");
    let year = initialDate.getFullYear();
    let month = initialDate.getMonth();

    let startOfMonth = new Date(year, month, 1); // Start of the current month
    startOfMonth.setDate(startOfMonth.getDate() - 6);
    let endOfMonth = new Date(year, month + 1, 0); // End of the current month
    endOfMonth.setDate(endOfMonth.getDate() + 7);
    console.log(
      "We could query from " + startOfMonth + " to " + endOfMonth + "..."
    );

    let calendarDisplayStartDateTime = ref(startOfMonth);
    let calendarDisplayEndDateTime = ref(endOfMonth);
    console.log(
      "Fetching Quests between " +
        calendarDisplayStartDateTime.value +
        " and " +
        calendarDisplayEndDateTime.value
    );
    const route = useRoute();

    // THIS IS A HELPFUL WAY TO TEST QUERIES
    // fetch(process.env.VUE_APP_DATABASE_URL, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({
    //       query: `
    //         query GetSpecificQuests ($GQI: GetQuestsInput!) {
    //         getSpecificQuests (getQuestsInput: $GQI) {
    //           id
    //           providerId
    //           provider {
    //             name
    //           }
    //           eventTitle
    //           description
    //           serviceTags
    //           startedAt
    //           endedAt
    //           flexibleMinimumTime
    //           locationId
    //           location {
    //             id
    //             name
    //             address
    //             detail
    //             latitude
    //             longitude
    //           }
    //           resourceMaxNumber
    //           pledgedContracts {
    //             id
    //             chariteerId
    //             chariteer {
    //               username
    //               nameFirst
    //             }
    //             claimedAt
    //             abandonedAt
    //             guild {
    //               id
    //               name
    //             }
    //             guildId
    //             status
    //             formJSON
    //           }
    //         }
    //       }
    //         `,
    //       variables: {
    //         "GQI": {
    //           "specificProvider": "1234FAKECHARITY", // props.selectedManagedProviderId
    //         }
    //       }
    //     })
    //   }).then(res => res.json()).then(data => {
    //     console.log("TEST DATA!");
    //     console.log(data);
    //   })

    console.log(
      "Selected Managed Provider ID is " + props.selectedManagedProviderId
    );
    const { result, loading, error, refetch, onResult } = useQuery(
      gql`
        query GetSpecificQuests($GQI: GetQuestsInput!) {
          getSpecificQuests(getQuestsInput: $GQI) {
            id
            providerId
            provider {
              name
            }
            eventType
            description
            publishingStatus
            eventTitle
            startedAt
            endedAt
            flexibleMinimumTime
            locationId
            location {
              id
              name
              address
              detail
            }
            resourceMaxNumber
            pledgedContracts {
              id
              chariteerId
              chariteer {
                username
                nameFirst
                nameLast
              }
              claimedAt
              abandonedAt
              guild {
                id
                name
                easyJoinCode
              }
              guildId
              status
              formJSON
            }
            giverName
          }
        }
      `,
      // This is variables - and it has to look like this
      {
        GQI: {
          specificProvider: props.selectedManagedProviderId,
          dateRangeBeginning: calendarDisplayStartDateTime.value,
          dateRangeEnding: calendarDisplayEndDateTime.value
        }
      }
      // headers maybe?
      // AUTHORIZATION
    );

    console.log("Refetching Quests!");
    console.log(result);
    //  console.log("Can we see the Quests we refetched?");
    console.log(result.quests);
    //  console.log(result._value.quests);
    console.log(error);

    const calendar = ref(null);

    return {
      databaseQuests: result || null,
      loading,
      error,
      refetch,
      onResult,
      notifyError,
      notifySuccess,
      ourManagedProviderID: props.selectedManagedProviderId,
      calendar,
      calendarDisplayStartDateTime,
      calendarDisplayEndDateTime
    };
  },
  created() {
    console.log("Time to do a lot of things!");

    this.onResult(newResult => {
      console.log("Data loaded.");
      this.isInitialLoadComplete = true;
      // console.log("Dollar sign watch:");
      // console.log(newResult);
      const now = new Date();

      const mappedCalendarArray = newResult.data.getSpecificQuests.map(
        (quest, index) => {
          const questDate = new Date(quest.startedAt);
          const isBeforeRightThisMoment = now.getTime() - questDate.getTime();
          // console.log(now.getTime());
          // console.log(questDate.getTime());

          // This is the default Quest: Published, Current, and Relevant
          let questLineStyle = `
          ${isBeforeRightThisMoment > 0 ? "opacity-25" : "opacity-100"}
          ${
            quest.publishingStatus == "Cancelled"
              ? "text-red-500"
              : "text-black"
          }
          bg-white hover:bg-gray-100 font-bold cursor-pointer pl-1 pr-1
        `;

          return {
            key: index, // SORT IT FROM THE BACK END, NOT HERE! OR v-calendar WILL MESS UP
            customData: {
              title: quest.eventTitle,
              questInfo: quest,
              class: questLineStyle
            },
            dates: questDate
          };
        }
      );
      // console.log("Mapped Calendar Array!");
      // console.log(mappedCalendarArray);
      this.attributes = mappedCalendarArray;
    });
  },
  data() {
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    return {
      masks: {
        weekdays: "WWW"
      },
      attributes: [],
      currentlyViewedProvider: {},
      isInitialLoadComplete: false,
      currentlySelectedDay: null,
      clickedQuestData: null,
      editQuestModalIsVisible: false,
      editQuestInfo: null,
      singleQuestDetailModalIsVisible: false,
      addSingleQuestModalIsVisible: false,
      addSingleQuestModalTab1IsVisible: false,
      addSingleQuestModalTab2IsVisible: false,
      addSingleQuestModalStampDropDownIsVisible: false,
      temporarilyChosenStartTime: null,
      temporarilyChosenEndTime: null,
      temporarilyChosenLocation: null,
      temporarilyChosenSupervisorName: "",
      guildSelectorIsVisible: false,
      currentVeilOfTime: new Date("01-01-2025"),
      hardcodedApprovedLocationsEmpty: {
        questLocations: []
      },
      hardcodedApprovedLocationsHobokenShelter: {
        questLocations: [
          {
            index: 14,
            selected: false,
            name: "The Hoboken Shelter",
            address: "300 Bloomfield St, Hoboken, NJ 07030",
            detail: "Enter through side door, and check-in at the front desk."
          },
          {
            index: 8,
            selected: false,
            name: "Pier A Park",
            address: "1st & Sinatra Dr, Hoboken, NJ 07030",
            detail:
              "Large outdoor pier park with walking paths and a grass field."
          }
        ]
      },
      hardcodedApprovedLocationsHCCPantry: {
        questLocations: [
          {
            index: 4,
            selected: false,
            name: "Hoboken Community Center",
            address: "1301 Washington St, Hoboken, NJ 07030",
            detail: "Enter through the purple double doors on 13th Street."
          },
          {
            index: 5,
            selected: false,
            name: "Hoboken Multi Service Center",
            address: "124 Grand St, Hoboken, NJ 07030",
            detail: "Meet inside the Senior Room."
          },
          {
            index: 15,
            selected: false,
            name: "Monroe Gardens",
            address: "221 Jackson St, Hoboken, NJ 07030",
            detail: "Enter through the back entrance via the parking lot."
          },
          {
            index: 6,
            selected: false,
            name: "Hoboken Housing Authority Courtyard",
            address: "311 Harrison, Hoboken, NJ 07030",
            detail: "Look for our table outside."
          },
          {
            index: 21,
            selected: false,
            name: "Main Street Pops",
            address: "1399 Jefferson St, Hoboken, NJ 07030",
            detail: "Pop-up festival held under the 14th street viaduct"
          },
          {
            index: 24,
            selected: false,
            name: "Church Square Park",
            address: "400 Garden St, Hoboken, NJ 07030",
            detail: "Large outdoor park with playgrounds and a gazeebo"
          },
          {
            index: 27,
            selected: false,
            name: "Hoboken Arts & Music Festival",
            address: "1st & Washington Street, Hoboken, NJ 07030",
            detail: "Street festival on Washington Street"
          },
          {
            index: 53,
            selected: false,
            name: "Hoboken Public Library",
            address: "500 Park Ave, Hoboken, NJ 07030",
            detail: "Enter through main entrance across from Church Square Park"
          }
        ]
      },
      hardcodedApprovedLocationsC4P: {
        questLocations: [
          {
            index: 13,
            selected: false,
            name: "Computers 4 People",
            address: "321 Newark St. #32, Hoboken, NJ 07030",
            detail:
              "Go up the stairs to the 3rd floor. Follow our posted signs."
          },
          {
            index: 25,
            selected: false,
            name: "Church of the Holy Innocents",
            address: "524 Willow Ave, Hoboken, NJ 07030",
            detail: "Historic Hoboken church with a large lawn used for events."
          }
        ]
      },
      hardcodedApprovedLocationsC4PMassachusetts: {
        questLocations: [
          {
            index: 75,
            selected: false,
            name: "Computers 4 People",
            address: "87 Beaver Street, Unit A, Waltham, MA 02453",
            detail: "Parking available on-site."
          }
        ]
      },
      hardcodedApprovedLocationsDvineKonektion: {
        questLocations: [
          {
            index: 11,
            selected: false,
            name: "Dvine Konektion",
            address: "611 56th Street, West New York, NJ 07093",
            detail: "Enter through the back steps in the parking lot."
          }
        ]
      },
      hardcodedApprovedLocationsTheOpenDoorNJNY: {
        questLocations: [
          {
            index: 54,
            selected: false,
            name: "Nazareth Baptist Church",
            address: "5800 Palisade Ave, West New York, NJ 07093",
            detail:
              "Go upstairs either via the parking lot or the main entrance."
          },
          {
            index: 55,
            selected: false,
            name: "The Cityline Church",
            address: "435 59th Street, West New York, NJ 07093",
            detail: "..."
          }
        ]
      },
      hardcodedApprovedLocationsStFrancisHoboken: {
        questLocations: [
          {
            index: 40,
            selected: false,
            name: "Fr. Michael Food Pantry",
            address: "308 Jefferson St, Hoboken, NJ 07030",
            detail:
              "Meet us outside the rectory, the door is below street level."
          }
        ]
      },
      hardcodedApprovedLocationsTempleBethElJC: {
        questLocations: [
          {
            index: 3,
            selected: false,
            name: "Temple Beth-El",
            address: "2419 John F. Kennedy Blvd, Jersey City, NJ 07304",
            detail: "Enter through the main temple doors.."
          }
        ]
      },
      hardcodedApprovedLocationsAmazingTruthSociety: {
        questLocations: [
          {
            index: 20,
            selected: false,
            name: "HHA Community Room",
            address: "411 Marshall Drive, Hoboken, NJ 07030",
            detail: "Look for the back entrance near the playground"
          }
        ]
      },
      hardcodedApprovedLocationsStMaryAdvocates: {
        questLocations: [
          {
            index: 65,
            selected: false,
            name: "St. Mary Advocates Thrift Store",
            address: "536 Garden St, Hoboken, NJ 07030",
            detail: "Our volunteer entrance is a back door on 6th Street."
          },
          {
            index: 24,
            selected: false,
            name: "Church Square Park",
            address: "400 Garden St, Hoboken, NJ 07030",
            detail: "Large outdoor park with playgrounds and a gazeebo"
          },
          {
            index: 26,
            selected: false,
            name: "Hoboken Arts & Music Festival",
            address: "3rd & Washington Street, Hoboken, NJ 07030",
            detail: "Street festival on Washington Street"
          },
          {
            index: 66,
            selected: false,
            name: "Monroe Center Plaza",
            address: "770 Jackson Street Hoboken, NJ 07030",
            detail: "Outdoor park near the Monroe Center."
          }
        ]
      },
      hardcodedApprovedLocationsVolunteerFoodRescue: {
        questLocations: [
          {
            index: 69,
            selected: false,
            name: "Prato Bakery",
            address: "201 Washington St, Hoboken, NJ 07030",
            detail: "Enter through the front entrance on Washington Street."
          },
          {
            index: 70,
            selected: false,
            name: "O'Bagel",
            address: "600 Washington St, Hoboken, NJ 07030",
            detail: "Enter through the side entrance on 6th Street."
          }
        ]
      },
      hardcodedApprovedLocationsLetsCelebrate: {
        questLocations: [
          {
            index: 42,
            selected: false,
            name: "Let's Celebrate",
            address: "46 Fairview Avenue, Jersey City, NJ 07030",
            detail: "Knock on the double wooden doors at the top of the steps."
          }
        ]
      },
      hardcodedApprovedLocationsGoodSamaritanGroup: {
        questLocations: [
          {
            index: 63,
            selected: false,
            name: "St. Abanoub & St. Antonious Coptic Orthodox Church",
            address: "1325 John F. Kennedy Blvd, Bayonne, NJ 07002",
            detail: "..."
          },
          {
            index: 64,
            selected: false,
            name: "Noah's Ark Daycare Center",
            address: "735 John F. Kennedy Blvd, Bayonne, NJ 07002",
            detail: "..."
          }
        ]
      },
      hardcodedApprovedLocationsCrossRoadFoundation: {
        questLocations: [
          {
            index: 49,
            selected: false,
            name: "Assumption Rectory",
            address: "15 Webster Ave, Staten Island, NY, 10301",
            detail: "Enter through the glass doors of the rectory."
          }
        ]
      },
      hardcodedApprovedLocationsStatenIslandCatholicWorker: {
        questLocations: [
          {
            index: 47,
            selected: false,
            name: "St. Peter's Church Farrell Hall",
            address: "53 St Marks Pl, Staten Island, NY 10301",
            detail: "Enter the church basement through the side door."
          }
        ]
      },
      hardcodedApprovedLocationsSistersOfLife: {
        questLocations: [
          {
            index: 62,
            selected: false,
            name: "St. Elizabeth Ann Seton Shrine",
            address: "7 State St, New York, NY 10004",
            detail: "This historic shrine is home to the Sisters of Life."
          }
        ]
      },
      hardcodedApprovedLocationsStPhilipNeriBronxNY: {
        questLocations: [
          {
            index: 61,
            selected: false,
            name: "St. Philip Neri Church",
            address: "3021 Grand Concourse, Bronx, NY 10468",
            detail: "Go through the iron gate and head down to the basement."
          }
        ]
      },
      hardcodedApprovedLocationsShirleyLArmstrongScholarshipFund: {
        questLocations: [
          {
            index: 80,
            selected: false,
            name: "Snyder Academy",
            address: "42 Broad St, Elizabeth, NJ 07201",
            detail: "Meet us inside the old Academy building."
          },
          {
            index: 79,
            selected: false,
            name: "Heard AME Church",
            address: "310 E 8th Ave, Roselle, NJ 07203",
            detail: "Look for us inside the church."
          }
        ]
      },
      hardcodedApprovedLocationsForMyGoodWorks: {
        questLocations: [
          {
            index: 78,
            selected: false,
            name: "St Paul's Episcopal Church",
            address: "214 Church St, Bound Brook, NJ 08805",
            detail:
              "Please park on the street so our clients can use the parking lot."
          }
        ]
      },
      hardcodedApprovedLocationsRadiantChurchPopUpFoodPantry: {
        questLocations: [
          {
            index: 84,
            selected: false,
            name: "Embassy Suites Piscataway",
            address: "121 Centennial Ave, Piscataway, NJ 08854",
            detail: "Look for our truck & tents in the parking lot."
          }
        ]
      },
      hardcodedApprovedLocationsNewLifePantry: {
        questLocations: [
          {
            index: 87,
            selected: false,
            name: "Faith Baptist Church",
            address: "611 Raritan Rd, Cranford, NJ, 07016",
            detail: "Enter through the front doors of the church."
          }
        ]
      },
      hardcodedApprovedLocationsMomsFoodPantryBergenfield: {
        questLocations: [
          {
            index: 90,
            selected: false,
            name: "Mom's Food Pantry",
            address: "7 W Main Street, Bergenfield, NJ 07621",
            detail: "Look for our storefront entrance on W Main Street."
          },
          {
            index: 93,
            selected: false,
            name: "Overpeck County Park Amphitheater",
            address: "Ridgefield Park, NJ 07660",
            detail: "Large outdoor amphitheater on the lawn."
          }
        ]
      },
      hardcodedApprovedLocationsZionCaresJC: {
        questLocations: [
          {
            index: 91,
            selected: false,
            name: "Zion Baptist Church",
            address: "521 Bramhall Avenue Jersey City, NJ 07304",
            detail:
              "Our parish hall is behind the church, look for the grey door."
          }
        ]
      },
      hardcodedApprovedLocationsTransitionProfessionals: {
        questLocations: [
          {
            index: 88,
            selected: false,
            name: "Transition Professionals",
            address: "269 Passaic St, Hackensack, NJ 07601",
            detail:
              "Parking is available on-site, please enter through our front door."
          }
        ]
      },
      hardcodedApprovedLocationsTestProvider: {
        questLocations: [
          {
            index: 28,
            selected: false,
            name: "Northgard",
            address: "Island of Nunarsuit, Iceland (Earth)",
            detail: "Somewhere in Iceland, on an island"
          },
          {
            index: 31,
            selected: false,
            name: "Stephen R. Gregg Park",
            address: "930 John F. Kennedy Blvd, Bayonne, NJ 07002",
            detail: "Bayfront park in Bayonne, NJ"
          },
          {
            index: 1,
            selected: false,
            name: "LocalHost School",
            address: "1100 Willow Ave, Hoboken, NJ 07030",
            detail: "Check in with security at the front door."
          }
        ]
      }
    };
  },
  methods: {
    userSwitchesCalendarPage(e) {
      // Intro
      console.log(
        "This user has switched the page. Looks like this value might need to change..."
      );
      console.log(this.calendarDisplayEndDateTime);

      console.log(e);
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      const now = new Date();
      const month = now.getMonth();
      console.log(
        "We think it's " +
          month +
          " (" +
          months[month] +
          ") right now. Should we refetch?"
      );
      console.log("Is " + e.month + " the same as " + (month + 1) + "?");

      if (!this.loading && e.month != month + 1) {
        // Figuring out the new dates
        let startOfMonth = new Date(e.year, e.month - 1, 1); // Start of the current month
        startOfMonth.setDate(startOfMonth.getDate() - 6);
        let endOfMonth = new Date(e.year, e.month, 0); // End of the current month
        endOfMonth.setDate(endOfMonth.getDate() + 7);
        console.log(
          "We'll requery from " + startOfMonth + " to " + endOfMonth + "..."
        );

        this.calendarDisplayStartDateTime = startOfMonth;
        this.calendarDisplayEndDateTime = endOfMonth;
        console.log(
          "Fetching Quests between " +
            this.calendarDisplayStartDateTime +
            " and " +
            this.calendarDisplayEndDateTime +
            " for " +
            this.selectedManagedProviderId
        );

        this.refetch({
          GQI: {
            specificProvider: this.selectedManagedProviderId,
            dateRangeBeginning: this.calendarDisplayStartDateTime,
            dateRangeEnding: this.calendarDisplayEndDateTime
          }
        });
      } else if (this.isInitialLoadComplete) {
        console.log(
          "In this case we came back to the initial month after navigating to a different one."
        );

        // Figuring out the new dates
        let startOfMonth = new Date(e.year, e.month - 1, 1); // Start of the current month
        startOfMonth.setDate(startOfMonth.getDate() - 6);
        let endOfMonth = new Date(e.year, e.month, 0); // End of the current month
        endOfMonth.setDate(endOfMonth.getDate() + 7);
        console.log(
          "We'll requery from " + startOfMonth + " to " + endOfMonth + "..."
        );

        this.calendarDisplayStartDateTime = startOfMonth;
        this.calendarDisplayEndDateTime = endOfMonth;
        console.log(
          "Fetching Quests between " +
            this.calendarDisplayStartDateTime +
            " and " +
            this.calendarDisplayEndDateTime +
            " for " +
            this.selectedManagedProviderId
        );

        this.refetch({
          GQI: {
            specificProvider: this.selectedManagedProviderId,
            dateRangeBeginning: this.calendarDisplayStartDateTime,
            dateRangeEnding: this.calendarDisplayEndDateTime
          }
        });
      } else {
        console.log("Do not refetch yet!");
      }
    },
    openCalendarQuest() {
      console.log("We've opened a Popper");
      this.guildSelectorIsVisible = true;
    },
    async queryProvider(providerID) {
      //TODO: refactor this and the similar function in stamps.vue to do all this on the calendar file once instead of individually twice.
      const { data: provider } = await this.$apollo.query({
        query: gql`
          {
            provider(id: "${providerID}") {
              questConfiguration
              approvedLocations {
                id
                name
                address
                detail
              }
            }
          }
        `
      });
      const config = JSON.parse(provider.provider?.questConfiguration);
      const approvedLocations = provider.provider?.approvedLocations;
      if (!config || config.questConfiguration?.locationBook?.length === 0) {
        if (approvedLocations.length === 0) {
          return null;
        }
        const modifiedLocations = approvedLocations.map(location => {
          return {
            ...location,
            selected: false
          };
        });

        return { questLocations: modifiedLocations };
      }
      config.questConfiguration?.locationBook.forEach(category => {
        category.locations?.forEach(location => {
          location.selected = false;
          const approvedLocation = approvedLocations.find(
            loc => loc.id === location.id
          );
          if (approvedLocation) {
            Object.keys(approvedLocation).forEach(key => {
              location[key] = approvedLocation[key];
            });
          } else {
            //remove it from the list if it's not approved
            category.locations = category.locations.filter(
              loc => loc.id !== location.id
            );
          }
        });
      });
      return config.questConfiguration.locationBook;
    },
    async handleDayClick(dayData, e) {
      // console.log(this.calendar);

      // console.log("Is this a day click, or a Quest click?");
      if (e.target.getAttribute("clickable") != "dayBox") {
        // console.log("We clicked something besides a dayBox!");
        // console.log(e.target);

        // Was it a Quest? That's interesting.
        if (e.target.getAttribute("clickable") == "calendarQuest") {
          console.log("Clicked a Quest!");
          console.log(e.target);
        }

        // this.calendar.move(2);
        return false;
      }

      // When you click a Day, we're either going to bring up the "Stamp Picker" or "Time / Location Confirmer" tab
      // of the Add Single Quest pop-up. That depends on whether we have a stamp selected...
      console.log(dayData);
      console.log("Setting currently selected Day to " + dayData.ariaLabel);
      this.currentlySelectedDay = dayData;

      // "Instantiate" a Modal
      console.log("Creating a stamp pop-up...");
      this.addSingleQuestModalIsVisible = true;

      // Do we have a Stamp?
      console.log(
        "Current Stamp / Bundle: " +
          this.$store.state.currentlySelectedStamp?.stampTitle
      );
      if (
        this.$store.state.currentlySelectedStamp == undefined ||
        this.$store.state.currentlySelectedStamp == null
      ) {
        this.addSingleQuestModalTab1IsVisible = true;
        this.addSingleQuestModalTab2IsVisible = false;
      } else {
        this.addSingleQuestModalTab1IsVisible = false;
        this.addSingleQuestModalTab2IsVisible = true;

        // If our stamp has no LocationId... panic?
        // Frank you don't need to do this everytime you open a window!
        // this.chooseApprovedLocationsCollection(this.selectedManagedProviderId);
      }
    },
    async chooseApprovedLocationsCollection(providerID) {
      let theProviderID = null;

      if (providerID == null) {
        // We probably just loaded the scene. Everything is fine!
        theProviderID = this.selectedManagedProviderId;
        console.log("ProviderID is null, setting it to " + theProviderID);
      } else {
        // We probably chose a new provider from the drop down.
        theProviderID = providerID;
      }

      const locationBook = await this.queryProvider(theProviderID);
      if (locationBook == null) {
        this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsEmpty;
        this.$store.state.currentlySelectedLocationConfig = {};
        // console.log("Choosing a stamp collection...");
        console.log(
          "Selecting the approved locations for Provider " + theProviderID + "?"
        );
        // this.$store.state.currentlySelectedLocationConfig = {};
        // Select the right stamp collection from our hardcoded options
        if (theProviderID == "6979BBE94C41FFA5")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsHobokenShelter;
        else if (theProviderID == "847F21FAD0FFC647")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsHCCPantry;
        else if (theProviderID == "4DD7296EFD66BEB9")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsC4P;
        else if (theProviderID == "B092AC88B3BBFC01")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsC4PMassachusetts;
        else if (theProviderID == "32666A142E725B16")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsDvineKonektion;
        else if (theProviderID == "14D28C7C8E55F14A")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsTheOpenDoorNJNY;
        else if (theProviderID == "20527FEBCC2CDFC5")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsStFrancisHoboken;
        else if (theProviderID == "29A2D4143AD32231")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsTempleBethElJC;
        else if (theProviderID == "1BBBF60741F9E7F3")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsAmazingTruthSociety;
        else if (theProviderID == "7F6860372DB1C8E0")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsStMaryAdvocates;
        else if (theProviderID == "EEE6EFD9AF6AA248")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsVolunteerFoodRescue;
        else if (theProviderID == "5378D57B798F2822")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsLetsCelebrate;
        else if (theProviderID == "AC0D97CB181D32B8")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsGoodSamaritanGroup;
        else if (theProviderID == "C0AB2EFF8CF5A32")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsCrossRoadFoundation;
        else if (theProviderID == "3B9DE08B449B39D0")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsStatenIslandCatholicWorker;
        else if (theProviderID == "946902D69E55037D")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsSistersOfLife;
        else if (theProviderID == "DA683CCDD07621BE")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsStPhilipNeriBronxNY;
        else if (theProviderID == "53E56B7FF5ABE676")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsShirleyLArmstrongScholarshipFund;
        else if (theProviderID == "779B67D089D0E093")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsForMyGoodWorks;
        else if (theProviderID == "1312EC849B2C70D2")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsRadiantChurchPopUpFoodPantry;
        else if (theProviderID == "F14001AE1164A6F8")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsNewLifePantry;
        else if (theProviderID == "911E74C3DAF9F8B6")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsMomsFoodPantryBergenfield;
        else if (theProviderID == "A484E05A5358B7CF")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsZionCaresJC;
        else if (theProviderID == "AA315C88EF59E453")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsTransitionProfessionals;
        else if (theProviderID == "FD0320648E13DFB1")
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsTestProvider;
        else {
          console.log("Unrecognized Provider, or no hardcoded locations.");
        }
      } else {
        console.log(
          "Selecting the approved locations for Provider " + theProviderID + "?"
        );
        if (locationBook.questLocations) {
          this.$store.state.currentlySelectedApprovedLocations = locationBook;
          this.$store.state.currentlySelectedLocationConfig = {};
        } else {
          this.$store.state.currentlySelectedApprovedLocations = this.hardcodedApprovedLocationsEmpty;
          this.$store.state.currentlySelectedLocationConfig = locationBook;
        }
      }
    },
    handleQuestClick(questData, e) {
      e.stopPropagation();
      console.log("Clicking on a Quest, doot do doo!");
      console.log(questData);
      // console.log( e );

      this.clickedQuestData = questData;
      this.singleQuestDetailModalIsVisible = true;
      console.log(
        "singleQuestDetailModalIsVisible is " +
          this.singleQuestDetailModalIsVisible
      );
    },
    handleLocationClick(location, e) {
      // If we had a currently selected stamp, make it not selected anymore
      if (
        this.temporarilyChosenLocation != undefined &&
        this.temporarilyChosenLocation != null
      ) {
        this.temporarilyChosenLocation.selected = false;

        // Are we actually just deselecting a Location?
        if (
          this.temporarilyChosenLocation.index == location.index ||
          this.temporarilyChosenLocation.id == location.id
        ) {
          this.temporarilyChosenLocation = null;
          console.log(
            "This is a deselect action. Location is now " +
              this.temporarilyChosenLocation
          );
          return;
        }
      }

      location.selected = true;
      console.log('Chosen Location is now "' + location.name + '"');
      this.temporarilyChosenLocation = location;
    },
    handleQuestModalClose() {
      this.singleQuestDetailModalIsVisible = false;
    },
    handleAddSingleQuestModalClose() {
      this.addSingleQuestModalIsVisible = false;
    },
    handleToggleStampSelectDropDown(e) {
      console.log("Opening the drop down...");
      this.addSingleQuestModalStampDropDownIsVisible = true;
    },
    handleProviderGroupClick(groupInfo, e) {
      // The user wants to switch the Calendar they are viewing.
      this.currentlyViewedProvider = groupInfo;
    },
    handlePublishingStatusChange(e) {
      console.log("Changed Publishing Status to ");
    },
    handleEditQuestClick(quest, e) {
      console.log("Creating a Modal for " + quest.eventTitle + "...");
      this.editQuestModalIsVisible = true;
      this.editQuestInfo = quest;
    },
    handleCloseEditQuestModal() {
      console.log("Closing with no action.");
      this.editQuestModalIsVisible = false;
      this.editQuestInfo = null;
    },
    async handleSubmitQuestUpdate(changesObject) {
      console.log("Do we have some changes here?");
      console.log(changesObject);
      this.editQuestModalIsVisible = false;

      // Some error checking I guess
      if (changesObject != undefined && changesObject?.id != undefined) {
        console.log("A quest is being updated!");

        // Execution
        const result = await this.mutationUpdateQuest(changesObject);

        if (result.errors?.length > 0) {
          // console.log("Displaying Notification...");
          const errorMessage = result.errors[0].extensions.response.message;
          // console.log("ERROR: " + errorMessage); These messages are more for me than them, they're essentially sub-codes.
          // (This is how you include stuff) this.notifyError(`${errorMessage} \n\n Sorry, this Quest already ended. For some reason, the system is still letting you sign up even though it ended. Sorry about that!`);
          // this.notifyError(`<div>`)

          const errorCode = result.errors[0].extensions.response.statusCode;
          // console.log("CODE: " + errorCode);

          // Missing Items
          if (errorCode == 404 && errorMessage == "Quest Not Found")
            this.notifyError(
              "ERROR! This is a glitch. For some reason, we can't find the Quest you're trying to interact with, but the system still allowed you to try. Sorry about that!"
            );
          else if (errorCode == 404 && errorMessage == "Provider Not Found")
            this.notifyError(
              "ERROR! This is a glitch. For some reason, we can't find the Quest Provider you're trying to interact with, but the system still allowed you to try. Sorry about that!"
            );
          else if (errorCode == 404 && errorMessage == "Chariteer Not Found")
            this.notifyError(
              "ERROR! This is a glitch. For some reason, we can't find the person you're trying to interact with, but the system still allowed you to try. Sorry about that!"
            );
          else if (errorCode == 404 && errorMessage == "Contract Not Found")
            this.notifyError(
              "ERROR! This is a glitch. For some reason, we can't find the item you're trying to interact with, but the system still allowed you to try. Sorry about that!"
            );
          // Logical Issues
          //
          //  REVIEW THESE
          // AND CONSIDER MOVING THIS TO A UTILITY.
          //
          else if (
            errorCode == 406 &&
            errorMessage == "Chariteer Is Already On This Quest"
          )
            this.notifyError(
              "ERROR! This is a glitch, you're already signed up for this Quest. For some reason, the system is still letting you sign up again. Sorry about that!"
            );
          else if (errorCode == 406 && errorMessage == "Quest Is Full")
            this.notifyError(
              "ERROR! Sorry, this Quest already has enough volunteers. For some reason, the system is still letting you sign up even though it's full. Sorry about that!"
            );
          else if (
            errorCode == 410 &&
            errorMessage == "Quest Already Over, Can't Sign Up"
          )
            this.notifyError(
              "ERROR! Sorry, this Quest already ended. For some reason, the system is still letting you sign up even though it ended. Sorry about that!"
            );
          else
            this.notifyError(
              "UNKNOWN ERROR! An unexpected error occurred. Sometimes the problem is the Wi-Fi network you're using, which could be blocking Charity Quest. Try switching over just to Mobile Data and try that again. If you keep seeing this error, email me at frank@charityquest.io"
            );
        } else {
          // UI and Data Refresh
          this.refetch();

          // Toast
          this.notifySuccess("Quest has been modified.");
        }
      } else {
        console.log("There are no changes!");
      }
    },
    async mutationUpdateQuest(changes) {
      // Make some changes to a Quest
      console.log("User wants to update the Quest (" + changes.id + ")");
      console.log("The new Supervisor will be " + changes.giverName);
      console.log(
        "The new Publishing Status will be " + changes.publishingStatus
      );
      console.log("The new volunteer max will be " + changes.resourceMaxNumber);
      console.log("The new description will be " + changes.description);
      console.log("The new starting time will be " + changes.startedAt);
      console.log("The new ending time will be " + changes.endedAt);

      const result = await fetch(process.env.VUE_APP_DATABASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.playerProfile.userAuthorizationToken
        },
        body: JSON.stringify({
          query: `
              mutation UpdateQuest ($UQI: UpdateQuestInput!) {
                updateQuest(updateQuestInput: $UQI) {
                  id
                  providerId
                  eventTitle
                  eventType
                  publishingStatus
                  description
                  agePolicyIndex
                  serviceTags
                  startedAt
                  endedAt
                  flexibleMinimumTime
                  resourceMaxNumber
                  locationId
                }
              }
            `,
          variables: {
            UQI: {
              id: changes.id,
              giverName: changes.giverName,
              publishingStatus: changes.publishingStatus,
              resourceMaxNumber: changes.resourceMaxNumber,
              description: changes.description,
              startedAt: changes.startedAt,
              endedAt: changes.endedAt
            }
          }
        })
      });

      // console.log(result);
      const data = await result.json();
      // console.log(data); //data can have data.errors

      return data;
    },
    async handleCancelQuestClick(quest, e) {
      // Cancelling a Single Quest. It's unlikely someone would update a bunch
      // of Quest info at the same time they are cancelling it...
      // We're handling it as it's own thing.
      console.log("Quest " + quest.eventTitle + " is being cancelled!");

      // Execution
      const result = await this.mutationCancelQuest(quest);

      if (result.errors?.length > 0) {
        // console.log("Displaying Notification...");
        const errorMessage = result.errors[0].extensions.response.message;
        // console.log("ERROR: " + errorMessage); These messages are more for me than them, they're essentially sub-codes.
        // (This is how you include stuff) this.notifyError(`${errorMessage} \n\n Sorry, this Quest already ended. For some reason, the system is still letting you sign up even though it ended. Sorry about that!`);
        // this.notifyError(`<div>`)

        const errorCode = result.errors[0].extensions.response.statusCode;
        // console.log("CODE: " + errorCode);

        // Missing Items
        if (errorCode == 404 && errorMessage == "Quest Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the Quest you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == 404 && errorMessage == "Provider Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the Quest Provider you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == 404 && errorMessage == "Chariteer Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the person you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == 404 && errorMessage == "Contract Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the item you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        // Logical Issues
        //
        //  REVIEW THESE
        // AND CONSIDER MOVING THIS TO A UTILITY.
        //
        else if (
          errorCode == 406 &&
          errorMessage == "Chariteer Is Already On This Quest"
        )
          this.notifyError(
            "ERROR! This is a glitch, you're already signed up for this Quest. For some reason, the system is still letting you sign up again. Sorry about that!"
          );
        else if (errorCode == 406 && errorMessage == "Quest Is Full")
          this.notifyError(
            "ERROR! Sorry, this Quest already has enough volunteers. For some reason, the system is still letting you sign up even though it's full. Sorry about that!"
          );
        else if (
          errorCode == 410 &&
          errorMessage == "Quest Already Over, Can't Sign Up"
        )
          this.notifyError(
            "ERROR! Sorry, this Quest already ended. For some reason, the system is still letting you sign up even though it ended. Sorry about that!"
          );
        else
          this.notifyError(
            "UNKNOWN ERROR! An unexpected error occurred. Sometimes the problem is the Wi-Fi network you're using, which could be blocking Charity Quest. Try switching over just to Mobile Data and try that again. If you keep seeing this error, email me at frank@charityquest.io"
          );
      } else {
        // UI and Data Refresh
        this.refetch();

        // Toast
        this.notifySuccess(
          'Quest "' + quest.eventTitle + '" has been Cancelled.'
        ); // have any chariteers been notified?
      }
    },
    async mutationCancelQuest(quest) {
      // You need to take the times they picked and translate them into UTC
      console.log(
        "User (admin?) wants to cancel the Quest " +
          quest.eventTitle +
          " (" +
          quest.id +
          ")"
      );

      const result = await fetch(process.env.VUE_APP_DATABASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.playerProfile.userAuthorizationToken
        },
        body: JSON.stringify({
          query: `
              mutation UpdateQuest ($UQI: UpdateQuestInput!) {
                updateQuest(updateQuestInput: $UQI) {
                  id
                  providerId
                  eventTitle
                  eventType
                  publishingStatus
                  description
                  agePolicyIndex
                  serviceTags
                  startedAt
                  endedAt
                  flexibleMinimumTime
                  resourceMaxNumber
                  locationId
                }
              }
            `,
          variables: {
            UQI: {
              id: quest.id,
              publishingStatus: "Cancelled",
              locationId: quest.locationId // This is just here because of an API issue...
            }
          }
        })
      });

      // console.log(result);
      const data = await result.json();
      // console.log(data); //data can have data.errors

      return data;
    },
    handleGuildSelection(groupSelected, questInfo) {
      // TODO: do whatever.
      console.log("We have chosen this Guild: ");
      console.log(groupSelected);
      console.log("To reserve this Quest:");
      console.log(questInfo);

      // reserve the Quest or maybe spawn a modal
      this.handleReserveQuestForGroup(questInfo, groupSelected);
    },
    async handleReserveQuestForGroup(quest, guild) {
      // Reserving a Quest for a Guild. I guess... just one Guild.
      console.log(
        "Quest " + quest.eventTitle + " is being reserved for " + guild.name
      );

      // Execution
      const result = await this.mutationReserveQuestForGroup(quest, guild);

      if (result.errors?.length > 0) {
        // console.log("Displaying Notification...");
        const errorMessage = result.errors[0].extensions.response.message;
        // console.log("ERROR: " + errorMessage); These messages are more for me than them, they're essentially sub-codes.
        // (This is how you include stuff) this.notifyError(`${errorMessage} \n\n Sorry, this Quest already ended. For some reason, the system is still letting you sign up even though it ended. Sorry about that!`);
        // this.notifyError(`<div>`)

        const errorCode = result.errors[0].extensions.response.statusCode;
        // console.log("CODE: " + errorCode);

        // Missing Items
        if (errorCode == 404 && errorMessage == "Quest Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the Quest you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == 404 && errorMessage == "Provider Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the Quest Provider you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == 404 && errorMessage == "Chariteer Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the person you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == 404 && errorMessage == "Contract Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the item you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        // Logical Issues
        //
        //  REVIEW THESE
        // AND CONSIDER MOVING THIS TO A UTILITY.
        //
        else if (
          errorCode == 406 &&
          errorMessage == "Guild Has Already Reserved This Quest"
        )
          this.notifyError(
            "ERROR! That Guild has already reserved that Quest."
          );
        else if (
          errorCode == 406 &&
          errorMessage == "Chariteer Is Already On This Quest"
        )
          this.notifyError(
            "ERROR! This is a glitch, you're already signed up for this Quest. For some reason, the system is still letting you sign up again. Sorry about that!"
          );
        else if (errorCode == 406 && errorMessage == "Quest Is Full")
          this.notifyError(
            "ERROR! Sorry, this Quest already has enough volunteers. For some reason, the system is still letting you sign up even though it's full. Sorry about that!"
          );
        else if (
          errorCode == 410 &&
          errorMessage == "Quest Already Over, Can't Sign Up"
        )
          this.notifyError(
            "ERROR! Sorry, this Quest already ended. For some reason, the system is still letting you sign up even though it ended. Sorry about that!"
          );
        else
          this.notifyError(
            "UNKNOWN ERROR! An unexpected error occurred. Sometimes the problem is the Wi-Fi network you're using, which could be blocking Charity Quest. Try switching over just to Mobile Data and try that again. If you keep seeing this error, email me at frank@charityquest.io"
          );
      } else {
        // UI and Data Refresh
        this.refetch();

        // Toast
        this.notifySuccess(
          'Quest "' +
            quest.eventTitle +
            '" has been reserved for ' +
            guild.name +
            "!"
        ); // have any chariteers been notified?
      }
    },
    async mutationReserveQuestForGroup(quest, guild) {
      console.log("Reserving a Quest!");

      const result = await fetch(process.env.VUE_APP_DATABASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.playerProfile.userAuthorizationToken
        },
        body: JSON.stringify({
          query: `
            mutation CreateContract ($CCI: CreateContractInput!) {
              createContract(createContractInput: $CCI) {
                id,
                chariteerId,
                quest {
                  id
                  eventTitle
                  pledgedContracts {
                    id
                    questId
                    chariteerId
                    abandonedAt
                  }
                },
                questId
                claimedAt
                abandonedAt
                abandonReason
                formJSON
              }
            }
          `,
          variables: {
            CCI: {
              chariteerId: this.$store.state.playerProfile.masterPlayerID,
              questId: parseInt(quest.id),
              guildId: guild.id,
              expectedArrivalAt: quest.startedAt,
              expectedDepartureAt: quest.endedAt
            }
          }
        })
      });

      // console.log(result);
      const data = await result.json();
      // console.log(data); //data can have data.errors

      return data;
    },
    async handleReleaseGroupReservation(quest, e) {
      console.log("Releasing the reservation on Quest " + quest.name);

      // Before we call anything, let's find the Active Guild Contract
      const guildContractArray = siftGuildContracts(quest.pledgedContracts);

      if (guildContractArray.length > 0) {
        console.log("Abandoning contract " + guildContractArray[0].id);
      } else {
        console.log("No one is raiding this!");
        // Give an error, somehow. This button shouldn't even be visible or clickable right now.
        return;
      }

      // Execution
      const result = await this.mutationReleaseGroupReservation(
        quest,
        guildContractArray[0].id
      );

      if (result.errors?.length > 0) {
        // console.log("Displaying Notification...");
        const errorMessage = result.errors[0].extensions.response.message;
        // console.log("ERROR: " + errorMessage); These messages are more for me than them, they're essentially sub-codes.
        // (This is how you include stuff) this.notifyError(`${errorMessage} \n\n Sorry, this Quest already ended. For some reason, the system is still letting you sign up even though it ended. Sorry about that!`);
        // this.notifyError(`<div>`)

        const errorCode = result.errors[0].extensions.response.statusCode;
        // console.log("CODE: " + errorCode);

        // Missing Items
        if (errorCode == 404 && errorMessage == "Quest Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the Quest you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == 404 && errorMessage == "Provider Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the Quest Provider you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == 404 && errorMessage == "Chariteer Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the person you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == 404 && errorMessage == "Contract Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the item you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        // Logical Issues
        //
        //  REVIEW THESE
        // AND CONSIDER MOVING THIS TO A UTILITY.
        //
        else if (
          errorCode == 406 &&
          errorMessage == "Guild Has Already Reserved This Quest"
        )
          this.notifyError(
            "ERROR! That Guild has already reserved that Quest."
          );
        else if (
          errorCode == 406 &&
          errorMessage == "Chariteer Is Already On This Quest"
        )
          this.notifyError(
            "ERROR! This is a glitch, you're already signed up for this Quest. For some reason, the system is still letting you sign up again. Sorry about that!"
          );
        else if (errorCode == 406 && errorMessage == "Quest Is Full")
          this.notifyError(
            "ERROR! Sorry, this Quest already has enough volunteers. For some reason, the system is still letting you sign up even though it's full. Sorry about that!"
          );
        else if (
          errorCode == 410 &&
          errorMessage == "Quest Already Over, Can't Sign Up"
        )
          this.notifyError(
            "ERROR! Sorry, this Quest already ended. For some reason, the system is still letting you sign up even though it ended. Sorry about that!"
          );
        else
          this.notifyError(
            "UNKNOWN ERROR! An unexpected error occurred. Sometimes the problem is the Wi-Fi network you're using, which could be blocking Charity Quest. Try switching over just to Mobile Data and try that again. If you keep seeing this error, email me at frank@charityquest.io"
          );
      } else {
        // UI and Data Refresh
        this.refetch();

        // Toast
        this.notifySuccess(
          'Reservation on Quest "' + quest.eventTitle + '" has been released.'
        ); // have any chariteers been notified?
      }
    },
    async mutationReleaseGroupReservation(quest, contractID) {
      const result = await fetch(process.env.VUE_APP_DATABASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.playerProfile.userAuthorizationToken
        },
        body: JSON.stringify({
          query: `
            mutation AbandonContract ($UCI: UpdateContractInput!) {
              abandonContract(updateContractInput: $UCI) {
                id
                claimedAt
                abandonReason
                abandonedAt
              }
            }
          `,
          variables: {
            UCI: {
              id: parseInt(contractID),
              abandonReason: "Released by manager"
            }
          }
        })
      });

      // console.log(result);
      const data = await result.json();
      // console.log(data); //data can have data.errors

      return data;
    },
    crunchTimeIntoShortCalendarVersion(startedAt) {
      var startTime = new Date(startedAt);
      var startTimeText = startTime.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });

      // Shave the whitespace
      startTimeText = startTimeText.replace(" ", "");

      return startTimeText.toLowerCase();
    },
    crunchPublishingStatusIntoShortCalendarVersion(status) {
      // If this Quest is Published, it should just look normal.
      if (status == "Published") return "•";
      // And now, the weird stuff
      else if (status == "Draft") return "D";
      else if (status == "Unlisted") return "U";
      else if (status == "Cancelled") return "⦸";
      else if (status == "Private") return "P";
      else return "?";
    },
    isDateBeyondVeilOfTime(dateObject) {
      // We select a hardcoded Veil of Time date and update it periodically when one month ends.
      // console.log("The veil of time is");
      // console.log(this.currentVeilOfTime);
      // console.log("Let's see if this date is beyond that:");
      // console.log(dateObject);

      if (dateObject.date >= this.currentVeilOfTime) return true;
      else return false;
    },
    isDateInPresentOrFuture(dateObject) {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      // console.log("Yesterday was " + yesterday);

      if (dateObject.date > yesterday) {
        // console.log(dateObject.date + " is either today, or in the future!");
        return true;
      } else {
        // console.log(dateObject.date + " is in the past.");
        return false;
      }
    },
    determineActualVolunteerCount(contractArray) {
      // If there's an array, show the length. If not, show 0
      // console.log("This is the array we're going to use to detemine the count! Length is " + contractArray ? contractArray.length : 0);

      const activeVolunteerContractArray = siftVolunteerContracts(
        contractArray
      );
      // console.log("Number of volunteers is " + activeVolunteerContractArray.length);

      // return contractArray.length;
      if (activeVolunteerContractArray != undefined)
        return activeVolunteerContractArray.length;
      else return 0;
    },
    determineVolunteerBubbleColor(questInfo) {
      const activeVolunteerContractArray = siftVolunteerContracts(
        questInfo?.pledgedContracts
      );

      if (activeVolunteerContractArray.length == 0) return "bg-red-400";
      else if (
        activeVolunteerContractArray.length >= questInfo.resourceMaxNumber
      )
        return "bg-green-400";
      else return "bg-cq-orange";
    },
    determinePublishingStatusBubbleStyle(status) {
      // If this Quest is Published, it should just look normal.
      if (status == "Published") return "text-gray-400";
      // And now, the weird stuff
      else if (status == "Draft") return "rounded-full px-1 bg-gray-300";
      else if (status == "Unlisted") return "rounded-full px-1 bg-gray-300";
      else if (status == "Cancelled")
        return "rounded-full px-1 bg-transparent text-md";
      else if (status == "Private") return "rounded-full px-1 bg-gray-300";
      else return "bg-black";
    },
    determineEndTimeInputStyle(start, end) {
      console.log("Start Time is ... ");
      console.log(start);
      console.log("End Time is ... ");
      console.log(end);

      if (end != null && end <= start) {
        return "bg-red-500";
      } else return "bg-white";
    },
    determineApprovedLocationButtonStyle(location) {
      console.log(location.name + " is selected: " + location.selected);
      if (location.selected == undefined) return "bg-red-500";
      else if (location.selected) return "bg-cq-orange";
      else return "bg-white";
    },
    determineIfQuestIsBeingRaided(contractArray) {
      const guildContractArray = siftGuildContracts(contractArray);

      if (guildContractArray != undefined && guildContractArray.length == 1) {
        return true;
      } else return false;
    },
    determineIfQuestHasNotStartedYet(quest) {
      const startTime = new Date(quest.startedAt);
      const now = new Date();

      if (now > startTime) {
        // console.log("This Quest has Already Begun");
        return false;
      } else {
        // console.log("This Quest hasn't started yet.");
        return true;
      }
    },
    determineRaidingGuildName(contractArray) {
      const guildContractArray = siftGuildContracts(contractArray);

      if (guildContractArray != undefined && guildContractArray.length == 1) {
        // console.log(guildContractArray);
        return guildContractArray[0].guild.name;
      } else return "No Group";
    },
    determineRaidingGuildCode(contractArray) {
      const guildContractArray = siftGuildContracts(contractArray);

      if (guildContractArray != undefined && guildContractArray.length == 1) {
        // console.log(guildContractArray);
        return guildContractArray[0].guild.easyJoinCode;
      } else return "";
    },
    determineRaidingGuildPointOfContact(contractArray) {
      const guildContractArray = siftGuildContracts(contractArray);

      if (guildContractArray != undefined && guildContractArray.length == 1) {
        // console.log(guildContractArray);
        return (
          guildContractArray[0].chariteer.nameFirst +
          " " +
          guildContractArray[0].chariteer.nameLast
        );
      } else return "None";
    },
    determineTopVolunteers(contractArray) {
      // A cool use for this could be to show an admin the best volunteers that have signed up for the shift.
      // There's only so much room, so you have space to say "Burt W, Frank D, and 3 more" or something like that.
      // We won't do that now - right now it's purely first come first serve.

      const activeVolunteerContractArray = siftVolunteerContracts(
        contractArray
      );
      let numberOfVolunteersLeft = activeVolunteerContractArray.length;

      if (numberOfVolunteersLeft == 1) {
        return (
          activeVolunteerContractArray[0].chariteer.nameFirst +
          " " +
          activeVolunteerContractArray[0].chariteer.nameLast
        );
      } else if (numberOfVolunteersLeft == 2) {
        // I decided to show last names, the text is small enough!
        // let lastName1 = activeVolunteerContractArray[0].chariteer.nameLast.charAt(0) + ".";
        // let lastName2 = activeVolunteerContractArray[1].chariteer.nameLast.charAt(0) + ".";
        return (
          activeVolunteerContractArray[0].chariteer.nameFirst +
          " " +
          activeVolunteerContractArray[0].chariteer.nameLast +
          " & " +
          activeVolunteerContractArray[1].chariteer.nameFirst +
          " " +
          activeVolunteerContractArray[1].chariteer.nameLast
        );
      } else if (numberOfVolunteersLeft == 3) {
        let lastName1 =
          activeVolunteerContractArray[0].chariteer.nameLast.charAt(0) + ".";
        let lastName2 =
          activeVolunteerContractArray[1].chariteer.nameLast.charAt(0) + ".";
        let lastName3 =
          activeVolunteerContractArray[2].chariteer.nameLast.charAt(0) + ".";
        return (
          activeVolunteerContractArray[0].chariteer.nameFirst +
          " " +
          lastName1 +
          ", " +
          activeVolunteerContractArray[1].chariteer.nameFirst +
          " " +
          lastName2 +
          ", & " +
          activeVolunteerContractArray[2].chariteer.nameFirst +
          " " +
          lastName3
        );
      } else if (numberOfVolunteersLeft == 4) {
        let lastName1 =
          activeVolunteerContractArray[0].chariteer.nameLast.charAt(0) + ".";
        let lastName2 =
          activeVolunteerContractArray[1].chariteer.nameLast.charAt(0) + ".";
        let lastName3 =
          activeVolunteerContractArray[2].chariteer.nameLast.charAt(0) + ".";
        return (
          activeVolunteerContractArray[0].chariteer.nameFirst +
          " " +
          lastName1 +
          ", " +
          activeVolunteerContractArray[1].chariteer.nameFirst +
          " " +
          lastName2 +
          ", & " +
          activeVolunteerContractArray[2].chariteer.nameFirst +
          " " +
          lastName3 +
          ", and 1 more volunteer"
        );
      } else {
        let lastName1 =
          activeVolunteerContractArray[0].chariteer.nameLast.charAt(0) + ".";
        let lastName2 =
          activeVolunteerContractArray[1].chariteer.nameLast.charAt(0) + ".";
        let lastName3 =
          activeVolunteerContractArray[2].chariteer.nameLast.charAt(0) + ".";
        numberOfVolunteersLeft = numberOfVolunteersLeft - 3;
        return (
          activeVolunteerContractArray[0].chariteer.nameFirst +
          " " +
          lastName1 +
          ", " +
          activeVolunteerContractArray[1].chariteer.nameFirst +
          " " +
          lastName2 +
          ", & " +
          activeVolunteerContractArray[2].chariteer.nameFirst +
          " " +
          lastName3 +
          ", and " +
          numberOfVolunteersLeft +
          " more volunteers"
        );
      }
    },
    comparePublishingStatus(quest, comparison) {
      if (quest.publishingStatus == comparison) return true;
      else return false;
    },
    selectedPublishingStatusOption(string) {
      return string;
    },
    questDayDisplay(questStarttime) {
      var date = new Date(questStarttime);

      // TO DO - only show the year (2023) if it's a different year than the current year.
      // two different returns - with and without year

      return date.toLocaleString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
      });
    },
    questStartTimeDisplay(questStartTime) {
      var startTime = new Date(questStartTime);
      var startTimeText = startTime.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });
      return startTimeText.toLowerCase();
    },
    questEndTimeDisplay(questEndTime) {
      // Supply Requests don't have an End Time...
      if (questEndTime == null) {
        return "";
      } else {
        var endTime = new Date(questEndTime);
        var endTimeText = endTime.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true
        });
        return endTimeText.toLowerCase();
      }
    },
    optionalTimeHyphen(questEndTime) {
      // Supply Requests don't have an End Time...
      if (questEndTime == null) {
        return "";
      } else {
        return "–";
      }
    },
    async createSingleQuest() {
      // console.log("Creating a Quest for charity " + this.props.selectedManagedProviderId);
      // ERROR because we don't have access to props.

      // Are we absolutely certain we know who is posting this Quest?
      if (
        this.$store.state.playerProfile?.masterPlayerID != undefined &&
        this.$store.state.playerProfile?.masterPlayerID != null &&
        this.$store.state.playerProfile?.masterPlayerID != ""
      ) {
        console.log(this.$store.state.playerProfile);
        console.log(
          "Recognized poster as " +
            this.$store.state.playerProfile.masterPlayerID
        );
      } else {
        // Show a popup saying "WHO ARE YOU PEOPLE?!"
        console.log("Unrecognized poster, we will not post this Quest.");
        this.addSingleQuestModalIsVisible = false;
        return;
      }

      // Execution
      const result = await this.mutationCreateQuest();
      this.addSingleQuestModalIsVisible = false;

      if (result.errors?.length > 0) {
        // console.log("Displaying Notification...");
        // console.log(result);
        const errorMessage = result.errors[0].extensions.response.message;
        // console.log("ERROR: " + errorMessage); These messages are more for me than them, they're essentially sub-codes.
        // (This is how you include stuff) this.notifyError(`${errorMessage} \n\n Sorry, this Quest already ended. For some reason, the system is still letting you sign up even though it ended. Sorry about that!`);
        // this.notifyError(`<div>`)

        const errorCode = result.errors[0].extensions.response.statusCode;
        // console.log("CODE: " + errorCode);

        // Missing Items
        if (errorCode == 404 && errorMessage == "Quest Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the Quest you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == 404 && errorMessage == "Provider Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the Quest Provider you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == 404 && errorMessage == "Chariteer Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the person you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == 404 && errorMessage == "Contract Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the item you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        // Logical Issues
        else if (
          errorCode == 406 &&
          errorMessage == "Chariteer Is Already On This Quest"
        )
          this.notifyError(
            "ERROR! This is a glitch, you're already signed up for this Quest. For some reason, the system is still letting you sign up again. Sorry about that!"
          );
        else if (errorCode == 406 && errorMessage == "Quest Is Full")
          this.notifyError(
            "ERROR! Sorry, this Quest already has enough volunteers. For some reason, the system is still letting you sign up even though it's full. Sorry about that!"
          );
        else if (
          errorCode == 410 &&
          errorMessage == "Quest Already Over, Can't Sign Up"
        )
          this.notifyError(
            "ERROR! Sorry, this Quest already ended. For some reason, the system is still letting you sign up even though it ended. Sorry about that!"
          );
        else
          this.notifyError(
            "UNKNOWN ERROR! An unexpected error occurred. Sometimes the problem is the Wi-Fi network you're using, which could be blocking Charity Quest. Try switching over just to Mobile Data and try that again. If you keep seeing this error, email me at frank@charityquest.io"
          );
      } else {
        // UI and Data Refresh
        this.refetch();

        // Toast
        this.notifySuccess(
          "Quest " +
            this.$store.state.currentlySelectedStamp.eventTitle +
            " created."
        );
      }
    },
    async mutationCreateQuest() {
      console.log("Provider ID is " + this.selectedManagedProviderId);

      // You need to take the times they picked and translate them into UTC
      var startDateTime = new Date(
        this.temporarilyChosenStartTime + " " + this.currentlySelectedDay.id
      );
      var endDateTime = new Date(
        this.temporarilyChosenEndTime + " " + this.currentlySelectedDay.id
      );
      var startDateTimeString = startDateTime.toISOString();
      var endDateTimeString = endDateTime.toISOString();
      console.log(
        "Chariteer wants to create a single Quest:\n" +
          this.$store.state.currentlySelectedStamp.stampTitle +
          "\n" +
          startDateTimeString +
          "\n" +
          endDateTimeString +
          "\n" +
          this.$store.state.currentlySelectedStamp.description +
          "\nLocation ID: " +
          this.temporarilyChosenLocation.index ||
          this.temporarilyChosenLocation.id +
            "\nFlex Time: " +
            this.$store.state.currentlySelectedStamp.flexibleMinimumTime +
            "\nPrerequisite Mins: " +
            this.$store.state.currentlySelectedStamp.prerequisiteMinutes
      );

      // Did we provide an override Giver Name?
      let giverName = "";
      if (this.temporarilyChosenSupervisorName != "") {
        giverName = this.temporarilyChosenSupervisorName;
      } else {
        giverName = this.$store.state.currentlySelectedStamp.giverName;
      }

      const result = await fetch(process.env.VUE_APP_DATABASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.playerProfile.userAuthorizationToken
        },
        body: JSON.stringify({
          query: `
              mutation CreateSingleQuest ($CQI: CreateQuestInput!) {
                createQuest(createQuestInput: $CQI) {
                  id
                  providerId
                  eventTitle
                  eventType
                  publishingStatus
                  description
                  agePolicyIndex
                  serviceTags
                  startedAt
                  endedAt
                  flexibleMinimumTime
                  resourceMaxNumber
                  locationId
                }
              }
            `,
          variables: {
            CQI: {
              providerId: this.selectedManagedProviderId,
              postedById: this.$store.state.playerProfile.masterPlayerID,
              eventTitle: this.$store.state.currentlySelectedStamp.eventTitle,
              publishingStatus: "Draft",
              description: this.$store.state.currentlySelectedStamp.description,
              serviceTags: this.$store.state.currentlySelectedStamp.serviceTags,
              startedAt: startDateTimeString,
              endedAt: endDateTimeString,
              flexibleMinimumTime: this.$store.state.currentlySelectedStamp
                .flexibleMinimumTime,
              locationId:
                parseInt(this.temporarilyChosenLocation.index) ||
                this.temporarilyChosenLocation.id,
              resourceMinNumber: this.$store.state.currentlySelectedStamp
                .resourceMinNumber,
              resourceDesiredNumber: this.$store.state.currentlySelectedStamp
                .resourceDesiredNumber,
              resourceMaxNumber: this.$store.state.currentlySelectedStamp
                .resourceMaxNumber,
              prerequisiteMinutes: this.$store.state.currentlySelectedStamp
                .prerequisiteMinutes,
              agePolicyIndex: parseInt(
                this.$store.state.currentlySelectedStamp.agePolicyIndex
              ),
              equipment: this.$store.state.currentlySelectedStamp.equipment,
              giverName: giverName
            }
          }
        })
      });

      // console.log(result);
      const data = await result.json();
      // console.log(data); //data can have data.errors

      return data;
    }
  },
  computed: {
    actualVolunteerCount() {
      return 3;
    },
    singleQuestValidAndReadyToPost() {
      if (
        this.$store.state.currentlySelectedStamp == undefined ||
        this.$store.state.currentlySelectedStamp == null
      )
        return false;

      if (
        this.temporarilyChosenLocation == undefined ||
        this.temporarilyChosenLocation == null
      )
        return false;

      // You need to choose times, or we're not sending a request
      if (
        this.temporarilyChosenStartTime == undefined ||
        this.temporarilyChosenStartTime == null
      )
        return false;

      // what about SUPPLY-REQUESTS?
      if (
        this.temporarilyChosenEndTime == undefined ||
        this.temporarilyChosenEndTime == null
      )
        return false;

      console.log("Start Time is ... ");
      console.log(this.temporarilyChosenStartTime);
      console.log("End Time is ... ");
      console.log(this.temporarilyChosenEndTime);

      // End Time must be AFTER the Start Time.
      if (this.temporarilyChosenEndTime <= this.temporarilyChosenStartTime) {
        console.log("End Time cannot be earlier than the Start Time.");
        return false;
      }

      // Veil of Time limitations would go here, related to the Date.
      // Anything else you need to check like # of Quests on same day, etc goes here

      return true;
    },
    guildRBACPolicyObject() {
      // var testRBACstring = "{\"groupRBAC\":[{\"ability\":\"memberFullNames\",\"roleCRUDS\":[{\"role\":\"admins\",\"CRUD\":[\"R\"]},{\"role\":\"boardMembers\",\"CRUD\":[\"R\"]},{\"role\":\"employees\",\"CRUD\":[\"R\"]}]},{\"ability\":\"memberContactInfo\",\"roleCRUDS\":[{\"role\":\"boardMembers\",\"CRUD\":[\"R\"]},{\"role\":\"employees\",\"CRUD\":[\"R\"]}]},{\"ability\":\"chariteerAttendance\",\"roleCRUDS\":[{\"role\":\"employees\",\"CRUD\":[\"C\",\"R\",\"U\",\"D\"]}]},{\"ability\":\"bypassAgePolicy\",\"roleCRUDS\":[{\"role\":\"trustedMinors\",\"CRUD\":[\"C\",\"R\",\"U\",\"D\"]}]},{\"ability\":\"quest\",\"roleCRUDS\":[{\"role\":\"employees\",\"CRUD\":[\"R\",\"D\"]},{\"role\":\"managers\",\"CRUD\":[\"C\",\"R\",\"D\"]},{\"role\":\"captains\",\"CRUD\":[\"R\",\"U\"]},{\"role\":\"trustedMinors\",\"CRUD\":[\"R\"]}]}]}";
      var testRBACstring = `
      {
        \"groupRBAC\":
        [
          {\"ability\":\"memberFullNames\",\"roleCRUDS\":[{\"role\":\"admins\",\"CRUD\":[\"R\"]},{\"role\":\"boardMembers\",\"CRUD\":[\"R\"]},{\"role\":\"employees\",\"CRUD\":[\"R\"]}]},
          {\"ability\":\"memberContactInfo\",\"roleCRUDS\":[{\"role\":\"boardMembers\",\"CRUD\":[\"R\"]},{\"role\":\"employees\",\"CRUD\":[\"R\"]}]},
          {\"ability\":\"chariteerAttendance\",\"roleCRUDS\":[{\"role\":\"employees\",\"CRUD\":[\"C\",\"R\",\"U\",\"D\"]}]},
          {\"ability\":\"bypassAgePolicy\",\"roleCRUDS\":[{\"role\":\"trustedMinors\",\"CRUD\":[\"C\",\"R\",\"U\",\"D\"]}]},
          {\"ability\":\"quest\",\"roleCRUDS\":[{\"role\":\"employees\",\"CRUD\":[\"C\",\"R\",\"U\",\"D\"]},{\"role\":\"managers\",\"CRUD\":[\"C\",\"R\",\"U\",\"D\"]},{\"role\":\"coordinators\",\"CRUD\":[\"C\",\"R\",\"U\",\"D\"]},{\"role\":\"captains\",\"CRUD\":[\"R\",\"U\"]},{\"role\":\"trustedMinors\",\"CRUD\":[\"R\"]}]}
        ]
      }
      `;

      var RBACObject = JSON.parse(testRBACstring);

      return RBACObject;
    },
    managedProviderGroupDataObject() {
      // Let's search Props for the Membership Data we care about.
      let i = 0;
      let groupMembershipData = null;

      for (i; i < this.managedProviderMemberships.length; i++) {
        if (
          this.managedProviderMemberships[i].ManagedProviderId ==
          this.selectedManagedProviderId
        ) {
          console.log(
            "We found data for Group " +
              this.managedProviderMemberships[i].ManagedProviderName
          );
          groupMembershipData = this.managedProviderMemberships[i];
        }
      }

      if (groupMembershipData == null) {
        console.log("We couldn't find any membership data object.");
        return null;
      }

      console.log(
        "In the Mgmt Group for " +
          groupMembershipData.ManagedProviderName +
          " (" +
          this.selectedManagedProviderId +
          "), user's Roles are: "
      );
      for (i = 0; i < groupMembershipData.Roles.length; i++) {
        console.log(
          groupMembershipData.Roles[0].RoleName +
            " / " +
            groupMembershipData.Roles[0].RoleId
        );
      }

      return groupMembershipData;
    },
    userCanCreateQuests() {
      const userIsAllowedToCreateQuests = isChariteerAllowedToPerformAction(
        this.managedProviderGroupDataObject,
        this.guildRBACPolicyObject,
        "quest",
        "C",
        "create Quests"
      );
      return userIsAllowedToCreateQuests;
    },
    userCanCancelQuests() {
      const userIsAllowedToCancelQuests = isChariteerAllowedToPerformAction(
        this.managedProviderGroupDataObject,
        this.guildRBACPolicyObject,
        "quest",
        "D",
        "cancel Quests"
      );
      return userIsAllowedToCancelQuests;
    },
    userCanEditQuests() {
      const userIsAllowedToEditQuests = isChariteerAllowedToPerformAction(
        this.managedProviderGroupDataObject,
        this.guildRBACPolicyObject,
        "quest",
        "U",
        "edit Quests"
      );
      return userIsAllowedToEditQuests;
    },
    currentlySelectedLocationConfig: {
      get() {
        console.log(
          "Getting the currently selected location config...",
          this.$store.state.currentlySelectedLocationConfig
        );
        return this.$store.state.currentlySelectedLocationConfig;
      },
      set(value) {
        this.$store.state.currentlySelectedApprovedLocations = value;
      }
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.vc-day {
  border: 1px solid rgb(200, 200, 200);
}
</style>
