import { createRouter, createWebHistory } from "vue-router";
import Splash from "../views/Splash.vue";
import Subscribe from "../views/Subscribe.vue";
import Noob from "../views/Noob.vue";
import LinkMenu from "../views/LinkMenu.vue";
import AccountRemoval from "../views/AccountRemoval.vue";
import Home from "../views/Home.vue";
import CreateAccount from "../views/CreateAccount.vue";
import CreateChariteerAccount from "../views/CreateChariteerAccount.vue";
import Login from "../views/Login.vue";
import PasswordReset from "../views/PasswordReset.vue";
import CharityList from "../views/CharityList.vue";
import QuestList from "../views/QuestList.vue";
import QuestDetails from "../views/QuestDetails.vue";
import OSVQuestDetails from "../views/OSVQuestDetails.vue";
import ApplicationDetails from "../views/ApplicationDetails.vue";
import ProviderQuestDetails from "../views/ProviderQuestDetails.vue";
import ProviderPastQuestDetails from "../views/ProviderPastQuestDetails.vue";
import Calendar from "../views/Calendar.vue";
import Quests from "../views/Quests.vue";
import Chariteers from "../views/Chariteers.vue";
import Groups from "../views/Groups.vue";
import Guilds from "../views/Guilds.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import TermsOfService from "../views/TermsOfService.vue";
import EmailVerified from "../views/EmailVerified.vue";
import PageNotFound from "../views/PageNotFound.vue";
import GuildDetails from "../views/GuildDetails.vue";
import Contest from "../views/Contest.vue";
import District from "../views/District.vue";
import OnTheWay from "../views/OnTheWay.vue";

const routes = [
  {
    path: "/",
    name: "Splash",
    component: Splash
  },
  {
    path: "/subscribe",
    name: "Subscribe",
    component: Subscribe
  },
  {
    path: "/noob",
    name: "Noob",
    component: Noob
  },
  {
    path: "/link-menu",
    name: "LinkMenu",
    component: LinkMenu
  },
  {
    path: "/account-removal",
    name: "Account Removal",
    component: AccountRemoval
  },
  {
    path: "/home",
    name: "Home",
    component: Home
  },
  {
    path: "/createAccount",
    name: "CreateAccount",
    component: CreateAccount
  },
  {
    path: "/createChariteerAccount",
    name: "CreateChariteerAccount",
    component: CreateChariteerAccount
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/passwordReset",
    name: "PasswordReset",
    component: PasswordReset
  },
  {
    path: "/charityList",
    name: "CharityList",
    component: CharityList
  },
  {
    path: "/questList/:providerID",
    name: "QuestList",
    component: QuestList,
    props: true
  },
  {
    path: "/questList/:providerID/quest/:id",
    name: "OSVQuestDetails",
    component: OSVQuestDetails,
    props: true
  },
  {
    path: "/quest/:id",
    name: "ApplicationDetails",
    component: ApplicationDetails,
    props: true
  },
  {
    path: "/quest/:id",
    name: "ProviderQuestDetails",
    component: ProviderQuestDetails,
    props: true
  },
  {
    path: "/quest/:id",
    name: "ProviderPastQuestDetails",
    component: ProviderPastQuestDetails,
    props: true
  },
  {
    path: "/district/:id",
    name: "DistrictDetails",
    component: District,
    props: true
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: Calendar
  },
  {
    path: "/quests",
    name: "Quests",
    component: Quests
  },
  {
    path: "/chariteers",
    name: "Chariteers",
    component: Chariteers
  },
  {
    path: "/chariteers/:chariteerID",
    name: "ChariteerDetails",
    component: OnTheWay
  },
  {
    path: "/groups",
    name: "Groups",
    component: Groups
  },
  {
    path: "/guilds",
    name: "Guilds",
    component: Guilds
  },
  {
    path: "/guilds/:guildID",
    name: "GuildDetails",
    component: GuildDetails,
    props: true
  },
  {
    path: "/contest",
    name: "Contest",
    component: Contest
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/privacy",
    name: "Privacy Policy",
    component: PrivacyPolicy
  },
  {
    path: "/terms",
    name: "Terms of Service",
    component: TermsOfService
  },
  {
    path: "/verifiedEmail",
    name: "Email Verified",
    component: EmailVerified
  },
  // catchall 404
  {
    path: "/:catchAll(.*)*",
    name: "PageNotFound",
    component: PageNotFound
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
