<template>
  <!-- This will be for Stamps and Bundles (painting on the calendar) -->
  <!-- This is the old hardcoded one actually! -->
  <div v-show="false" class="bg-white rounded-lg m-5 shadow">
    <p class="text-black text-center font-bold">Your Quest Stamps</p>
  </div>

  <!-- New and Awesome -->
  <div class="bg-white rounded-lg m-5 shadow">
    <!-- TODO: LOOP THROUGH ALL BUNDLES -->
    <!-- -->
    <!-- Each Page of the Stamp Book -->
    <template v-if="this.currentlySelectedStampConfigFile?.stampBook">
      <div
        class="mx-5 my-2 py-1"
        v-for="page in this.currentlySelectedStampConfigFile.stampBook"
        :key="page.name"
      >
        <p class="text-black text-center font-bold">{{ page.name }} Stamps</p>
        <!-- Single Page of the Stamp Book -->
        <div
          class="mx-5 my-2 py-1"
          v-for="stamp in page.stamps"
          :key="`${stamp.id}`"
        >
          <!-- Stamp is Selected -->
          <template v-if="stamp.selected == true">
            <button
              class="flex flex-row items-center bg-cq-orange text-gray-800 w-48 truncate py-1 pl-2 border border-orange-400 hover:border-orange-300 rounded-md shadow-md cursor-pointer"
              @click="e => handleStampClick(stamp, e)"
            >
              <IconPicker class="" :name="stamp.icon" size="tiny" />
              <span v-if="stamp.stampTitle != null" class="ml-2">{{
                stamp.stampTitle
              }}</span>
              <span v-else class="ml-2 font-medium">Untitled Stamp</span>
            </button>
          </template>
          <!-- The default, when a Stamp is not Selected -->
          <template v-else>
            <button
              class="flex flex-row items-center bg-white w-48 text-gray-700 truncate py-1 pl-2 border border-gray-100 hover:border-gray-400 rounded-md shadow-md cursor-pointer"
              @click="e => handleStampClick(stamp, e)"
            >
              <IconPicker class="" :name="stamp.icon" size="tiny" />
              <span v-if="stamp.stampTitle != null" class="ml-2">{{
                stamp.stampTitle
              }}</span>
              <span v-else class="ml-2">Untitled Stamp</span>
            </button>
          </template>
        </div>
      </div>
    </template>
    <template
      v-else-if="
        this.$store.state.currentlySelectedStampCollection?.stamps.length > 0
      "
    >
      <div
        class="mx-5 my-2 py-1"
        v-for="stamp in this.$store.state.currentlySelectedStampCollection
          .stamps"
        :key="`${stamp.index}-${stamp.stampTitle}`"
      >
        <!-- TO DO: Each Stamp needs a "view this" Button on the left side that lets you see how the Stamp will appear on mobile. -->
        <template v-if="stamp.selected == true">
          <!-- TO DO: Clicking a Stamp Button sets your "Current Stamp ID" to a Quest ID -->
          <button
            class="flex flex-row items-center bg-cq-orange text-gray-800 w-48 truncate py-1 pl-2 border border-orange-400 hover:border-orange-300 rounded-md shadow-md cursor-pointer"
            @click="e => handleStampClick(stamp, e)"
          >
            <!-- <IconPicker :name="stamp.icon" size="tiny"/> -->
            <IconPicker class="" :name="stamp.icon" size="tiny" />
            <span class="ml-2 font-medium">{{ stamp.stampTitle }}</span>
          </button>
        </template>
        <!-- The default, when a Stamp is not selected -->
        <template v-else>
          <button
            class="flex flex-row items-center bg-white w-48 text-gray-700 truncate py-1 pl-2 border border-gray-100 hover:border-gray-400 rounded-md shadow-md cursor-pointer"
            @click="e => handleStampClick(stamp, e)"
          >
            <!-- <IconPicker :name="stamp.icon" size="tiny"/> -->
            <IconPicker class="" :name="stamp.icon" size="tiny" />
            <span class="ml-2">{{ stamp.stampTitle }}</span>
          </button>
        </template>
      </div>
    </template>
    <template v-else>
      <p class="px-5">
        Your charity does not have any stamps yet. Stamps allow you to post new
        quests to the system based on preset descriptions. Send me an email at
        frank@charityquest.io and I'll help set this up for you!
      </p>
    </template>
  </div>
</template>

<script>
import IconPicker from "@/components/IconPicker.vue";
import apolloClient from "../../main";
import { useQuery, provideApolloClient } from "@vue/apollo-composable";
import { useRoute } from "vue-router";
import gql from "graphql-tag";
import { useCookies } from "vue3-cookies";
import { watch, ref } from "vue";
import { ApolloClient } from "@apollo/client/core";
import { useStore } from "vuex";

// Stamps and Quests are different because...
// There are many "Pack Bags" Quests for the pantry, but only one "Pack Bags" stamp.
// Stamps have a cute little symbol (truck, can of food, chef hat, egg) to the left of them
// Stamps have a tag, like "SEASONAL" or "PROGRAMS" or "FUNDRAISING" or "MARKETING"
// ok actually...
// STAMPS AND QUESTS ARE THE SAME, DUH!
// But here's what the deal is... each charity needs something called like StampSettings
// and what StampSettings does is it says that i.e. Quests 540, 879, and 1149 are "stamps" and assigns them tags and cute icons.
// These Quests have never happened. They were created as fake Jan 1 1970 Quests purely as templates. They can be edited.
// but what do I do for right now?

// BUNDLES
// Bundles are tougher because they only exist on Provider accounts in some JSON blob
// I guess Bundles have an ID... or maybe an array index

export default {
  name: "Stamps",
  components: {
    IconPicker
  },
  props: ["selectedManagedProviderId"],
  watch: {
    selectedManagedProviderId: {
      async handler(newProviderID) {
        // React to changes in the Provider ID:
        console.log("Selected Provider has changed to ", newProviderID);

        // Choose the Stamp Collection again!
        await this.chooseStampCollection(newProviderID);
      },
      immediate: true // This triggers the handler immediately when the component is created
    }
  },
  async created() {
    await this.chooseStampCollection(null);
  },
  data() {
    return {
      hardcodedStampsEmpty: {
        stamps: [],
        bundles: []
      },
      hardcodedStampsForHobokenShelter: {
        stamps: [
          {
            index: "6979BBE94C41FFA5",
            selected: false,
            stampTitle: "Newsletter",
            icon: "stamp_envelope_01",
            eventType: "OSV",
            eventTitle: "Newsletter Mailing",
            description:
              "We're mailing out our quarterly newsletter! We need volunteers to help fold the newsletters, stuff them into envelopes, and get them all ready to be mailed. We do this newsletter four times a year, and it's a great way to inform our donors and volunteers what we're up to and what donations we need.",
            serviceTags: ["treasure"],
            flexibleMinimumTime: null,
            locationId: 14,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 2,
            agePolicyIndex: 0,
            equipment: "",
            giverName: ""
          },
          {
            index: "6979BBE94C41FFA5",
            selected: false,
            stampTitle: "Breakfast",
            icon: "stamp_bread_01",
            eventType: "OSV",
            eventTitle: "Serve Breakfast",
            description:
              "Every morning we serve a nutritious breakfast to our unsheltered homeless Guests. We need help setting up for breakfast so that we can serve everyone at 9 am. Clean up finishes by 10 am.",
            serviceTags: ["food"],
            flexibleMinimumTime: null,
            locationId: 14,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 2,
            agePolicyIndex: 0,
            equipment:
              "Casual clothing, Hat, Closed-toe shoes. Gloves provided.",
            giverName: "Front Desk"
          },
          // 1, Lunch Feast (same as lunch?)
          {
            index: "6979BBE94C41FFA5",
            selected: false,
            stampTitle: "Lunch",
            icon: "stamp_salad_01",
            eventType: "OSV",
            eventTitle: "Serve Lunch",
            description:
              "Every day we serve a delicious hot lunch, distribute canned goods and clothes, and provide showers to our unsheltered homeless Guests. We need help setting up for lunch and showers, which includes sorting canned goods and clothes to be distributed at 12 pm; setting up the tables, preparing food, and serving lunch at 1:30 pm. Clean up finishes by 2 pm.",
            serviceTags: ["food"],
            flexibleMinimumTime: null,
            locationId: 14,
            resourceMinNumber: 3,
            resourceDesiredNumber: 5,
            resourceMaxNumber: 8,
            agePolicyIndex: 0,
            equipment:
              "Casual clothing, Hat, Closed-toe shoes. Gloves provided.",
            giverName: "Front Desk"
          },
          // 3, Dinner Feast (same as Dinner?)
          {
            index: "6979BBE94C41FFA5",
            selected: false,
            stampTitle: "Dinner",
            icon: "stamp_knife_01",
            eventType: "OSV",
            eventTitle: "Serve Dinner",
            description:
              "We need help with the dinner shift in our soup kitchen. Individuals are welcome to stop by any evening to prepare and serve dinner, sort and distribute canned goods and clothes, and clean the facility and grounds.\n\nIf you’re planning on driving to our shelter, please plan ahead and give yourself 15-30 minutes to find a spot.\n\nAre you part of a group that is bringing your own food? Be sure to come at 3 pm to cook it, because dinner is served promptly at 5:30 pm.",
            serviceTags: ["food"],
            flexibleMinimumTime: null,
            locationId: 14,
            resourceMinNumber: 3,
            resourceDesiredNumber: 5,
            resourceMaxNumber: 8,
            agePolicyIndex: 0,
            equipment:
              "Casual clothing, Hat, Closed-toe shoes. Gloves provided.",
            giverName: "Front Desk"
          },
          // 5, Food Delivery (truck)
          {
            index: "6979BBE94C41FFA5",
            selected: false,
            stampTitle: "Sunday Workshop",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Spiritual Group Workshop",
            description:
              "Every Sunday, we hold a workshop for our unsheltered homeless Guests about their spiritual health and morals to help them make good decisions in life. If you're interested in leading this workshop and can provide any necessary slide presentation or handouts, please sign up here!\n\nPlease know that as long as you are passionate about the subject, you are qualified to hold this workshop! Even if you don't feel like you know the subject on an expert level, your knowledge will really benefit our Guests.\n\nHave any questions for us before you sign up here? Call or email Rev at anthony.forbes@hobokenshelter.org (201-620-4792).",
            serviceTags: ["soul"],
            flexibleMinimumTime: null,
            locationId: 14,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 1,
            agePolicyIndex: 1,
            equipment: "Handouts or Presentation Slides",
            giverName: "Front Desk"
          },
          {
            index: 7,
            selected: false,
            stampTitle: "Monday Workshop",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Finance Literacy Workshop",
            description:
              "Every Monday, we hold a workshop for our unsheltered homeless Guests about how they can better manage their personal finances and make smart decisions. If you're interested in leading this workshop and can provide any necessary slide presentation or handouts, please sign up here!\n\nPlease know that as long as you are passionate about the subject, you are qualified to hold this workshop! Even if you don't feel like you know the subject on an expert level, your knowledge will really benefit our Guests.\n\nHave any questions for us before you sign up here? Call or email Rev at anthony.forbes@hobokenshelter.org (201-620-4792).",
            serviceTags: ["treasure"],
            flexibleMinimumTime: null,
            locationId: 14,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 1,
            agePolicyIndex: 1,
            equipment: "Handouts or Presentation Slides",
            giverName: "Front Desk"
          },
          {
            index: 8,
            selected: false,
            stampTitle: "Tuesday Workshop",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Health & Hygiene Workshop",
            description:
              "Every Tuesday, we hold a workshop for our unsheltered homeless Guests about making smart personal healthcare decisions and personal cleanliness. If you're interested in leading this workshop and can provide any necessary slide presentation or handouts, please sign up here!\n\nPlease know that as long as you are passionate about the subject, you are qualified to hold this workshop! Even if you don't feel like you know the subject on an expert level, your knowledge will really benefit our Guests.\n\nHave any questions for us before you sign up here? Call or email Rev at anthony.forbes@hobokenshelter.org (201-620-4792).",
            serviceTags: ["cure"],
            flexibleMinimumTime: null,
            locationId: 14,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 1,
            agePolicyIndex: 1,
            equipment: "Handouts or Presentation Slides",
            giverName: "Front Desk"
          },
          {
            index: 9,
            selected: false,
            stampTitle: "Thursday Workshop",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Job & Employment Workshop",
            description:
              "Every Thursday, we hold a workshop for our unsheltered homeless Guests about different career opportunities they can apply for, as well as their rights as employees. If you're interested in leading this workshop and can provide any necessary slide presentation or handouts, please sign up here!\n\nPlease know that as long as you are passionate about the subject, you are qualified to hold this workshop! Even if you don't feel like you know the subject on an expert level, your knowledge will really benefit our Guests.\n\nHave any questions for us before you sign up here? Call or email Rev at anthony.forbes@hobokenshelter.org (201-620-4792).",
            serviceTags: ["mind"],
            flexibleMinimumTime: null,
            locationId: 14,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 1,
            agePolicyIndex: 1,
            equipment: "Handouts or Presentation Slides",
            giverName: "Front Desk"
          },
          {
            index: 10,
            selected: false,
            stampTitle: "Friday Workshop",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Substance Abuse Workshop",
            description:
              "Every Friday, we hold a workshop for our unsheltered homeless Guests to help them with their mental health and any ongoing struggles with substance abuse. If you're interested in leading this workshop and can provide any necessary slide presentation or handouts, please sign up here!\n\nPlease know that as long as you are passionate about the subject, you are qualified to hold this workshop! Even if you don't feel like you know the subject on an expert level, your knowledge will really benefit our Guests.\n\nHave any questions for us before you sign up here? Call or email Rev at anthony.forbes@hobokenshelter.org (201-620-4792).",
            serviceTags: ["toxin"],
            flexibleMinimumTime: null,
            locationId: 14,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 1,
            agePolicyIndex: 1,
            equipment: "Handouts or Presentation Slides",
            giverName: "Front Desk"
          },
          {
            index: 11,
            selected: false,
            stampTitle: "Saturday Workshop",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Support Group Workshop",
            description:
              "Every Saturday, we hold a workshop for our unsheltered homeless Guests to give them the floor to discuss anything they need help with. This session is more of a discussion than a presentation. Your goal will be to get them to open up and communicate with the group about the problems they are currently facing. If you're interested in leading this workshop and can provide any necessary slide presentation or handouts, please sign up here!\n\nPlease know that as long as you are passionate about the subject, you are qualified to hold this workshop! Even if you don't feel like you know the subject on an expert level, your knowledge will really benefit our Guests.\n\nHave any questions for us before you sign up here? Call or email Rev at anthony.forbes@hobokenshelter.org (201-620-4792).",
            serviceTags: ["speech"],
            flexibleMinimumTime: null,
            locationId: 14,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 1,
            agePolicyIndex: 1,
            equipment: "Handouts or Presentation Slides",
            giverName: "Front Desk"
          },
          {
            index: 12,
            selected: false,
            stampTitle: "Shoe & Bra Day",
            icon: "stamp_people_01",
            eventType: "OSV",
            eventTitle: "Shoe & Bra Day",
            description:
              "When we have a lot of donations of shoes, bras, and other essential clothing items, we have a large organization / distribution day at our shelter. Our staff needs help from volunteers to sort these donated garments by size and organize them into piles for men and women.\n\nWhen our guests arrive, we will need your help distributing the clothing and helping our neighbors make their selection.",
            serviceTags: ["garment"],
            flexibleMinimumTime: null,
            locationId: 14,
            resourceMinNumber: 4,
            resourceDesiredNumber: 6,
            resourceMaxNumber: 8,
            agePolicyIndex: 0,
            equipment: "",
            giverName: "Front Desk"
          },
          {
            index: 13,
            selected: false,
            stampTitle: "Farmer's Market",
            icon: "stamp_bag_01",
            eventType: "OSV",
            eventTitle: "Collect Fresh Produce",
            description:
              "The local farmer's market is kind enough to let us collect unsold fresh produce from their stalls at the close of the day. We need volunteers to take a grocery cart from our shelter and walk a few blocks over to Church Square Park to collect the donated produce, then come back and bring everything into our kitchen.\n\nThis will involve pushing heavy carts of food and carrying heavy items!",
            serviceTags: ["body"],
            flexibleMinimumTime: null,
            locationId: 14,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 2,
            agePolicyIndex: 0,
            equipment: "Gloves",
            giverName: "Front Desk"
          },
          {
            index: 14,
            selected: false,
            stampTitle: "New Quest",
            icon: "stamp_people_01",
            eventType: "OSV",
            eventTitle: "Help The Shelter",
            description: "Description pending.",
            serviceTags: ["food"],
            flexibleMinimumTime: null,
            locationId: 14,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 4,
            agePolicyIndex: 0,
            equipment: "",
            giverName: "Front Desk"
          }
        ],
        bundles: []
      },
      hardcodedStampsForHCCPantry: {
        stamps: [
          {
            index: "847F21FAD0FFC647",
            // The third Tuesday of every month...
            selected: false,
            stampTitle: "CFBNJ Delivery",
            icon: "stamp_truck_01",
            eventType: "OSV",
            eventTitle: "Food Bank Delivery",
            description:
              "We're expecting our monthly shipment of food from the Community Food Bank of New Jersey. This is a very large delivery, usually around 10 pallettes of food. Help us bring the food inside, organize the delivery, and put everything away. We'll organize the pantry while we await the delivery.\n\nThis Quest involves a lot of lifting heavy items and walking!",
            serviceTags: ["body"],
            flexibleMinimumTime: null,
            locationId: 4,
            resourceMinNumber: 8,
            resourceDesiredNumber: 10,
            resourceMaxNumber: 12,
            agePolicyIndex: 1,
            equipment:
              "Comfortable clothing that can get dirty, closed-toed shoes, water bottle.",
            giverName: ""
          },
          {
            index: "847F21FAD0FFC647",
            // Regular Nubby delivery
            selected: false,
            stampTitle: "Dry Goods Delivery",
            icon: "stamp_truck_01",
            eventType: "OSV",
            eventTitle: "Food Delivery",
            description:
              "We're expecting a shipment of food. Help us unload the truck, and open the packages. We'll organize the pantry while we await the delivery.\n\nThis Quest involves a lot of lifting heavy items and walking!",
            serviceTags: ["body"],
            flexibleMinimumTime: null,
            locationId: 4,
            resourceMinNumber: 4,
            resourceDesiredNumber: 5,
            resourceMaxNumber: 5,
            agePolicyIndex: 1,
            equipment:
              "Comfortable clothing that can get dirty, closed-toed shoes, water bottle.",
            giverName: ""
          },
          {
            index: "847F21FAD0FFC647",
            selected: false,
            stampTitle: "Pack Bags",
            icon: "stamp_bag_01",
            eventType: "OSV",
            eventTitle: "Pack Bags",
            description:
              "We're packing bags for distribution to our neighbors struggling with food insecurity. You'll help us place items in bags, check that they have the proper contents, and organize them for distribution.",
            serviceTags: ["food"],
            flexibleMinimumTime: null,
            locationId: 4,
            resourceMinNumber: 4,
            resourceDesiredNumber: 5,
            resourceMaxNumber: 6,
            agePolicyIndex: 0,
            equipment:
              "Comfortable clothing that can get dirty, closed-toed shoes, water bottle.",
            giverName: ""
          },
          {
            index: 3,
            selected: false,
            stampTitle: "Produce Delivery",
            icon: "stamp_truck_01",
            eventType: "OSV",
            eventTitle: "Produce Delivery",
            description:
              "We're expecting a shipment of fresh produce. Help us unload the truck, and open the packages. We'll organize the pantry while we await the delivery.\n\nThis Quest involves a lot of lifting heavy items and walking!",
            serviceTags: ["body"],
            flexibleMinimumTime: null,
            locationId: 4,
            resourceMinNumber: 3,
            resourceDesiredNumber: 4,
            resourceMaxNumber: 5,
            agePolicyIndex: 1,
            equipment:
              "Comfortable clothing that can get dirty, closed-toed shoes, water bottle.",
            giverName: ""
          },
          {
            index: 4,
            selected: false,
            stampTitle: "Distribution Prep",
            icon: "stamp_shelf_01",
            eventType: "OSV",
            eventTitle: "Distribution Prep",
            description:
              "This shift is our chance to prepare the pantry for distribution day tomorrow. We need volunteers to handle assorted organizing tasks like documenting donated items, restocking our warehouse shelves with products, and discarding any expired goods.\n\nThis Quest involves a lot of lifting heavy items, stairs, and walking!",
            serviceTags: ["food"],
            flexibleMinimumTime: null,
            locationId: 4,
            resourceMinNumber: 3,
            resourceDesiredNumber: 3,
            resourceMaxNumber: 4,
            agePolicyIndex: 0,
            equipment:
              "Comfortable clothing that can get dirty, closed-toed shoes, water bottle.",
            giverName: ""
          },
          {
            index: 5,
            selected: false,
            stampTitle: "Load Bags",
            icon: "stamp_bag_01",
            eventType: "OSV",
            eventTitle: "Load Bags",
            description:
              "Help us load bags into trucks that will be sent to another distribution location. A different team at that location will handle unloading the bags.\n\nNOTE: This Quest involves carrying items, walking, and some stairs.",
            serviceTags: ["body"],
            flexibleMinimumTime: null,
            locationId: 4,
            resourceMinNumber: 3,
            resourceDesiredNumber: 4,
            resourceMaxNumber: 5,
            agePolicyIndex: 1,
            equipment:
              "Comfortable clothing that can get dirty, closed-toed shoes, water bottle.",
            giverName: ""
          },
          {
            index: 6,
            selected: false,
            stampTitle: "Unload MSC",
            icon: "stamp_truck_01",
            eventType: "OSV",
            eventTitle: "Unload Bags",
            description:
              "To prepare for distribution day, we're sending out a truck one day early to this location. Help us set up the room and unload the truck so we're ready for tomorrow's distribution day. After we unload the food, we'll divide the produce boxes evenly into single family bags of produce.\n\nNOTE: This Quest involves lifting very heavy items and lots of walking!",
            serviceTags: ["body"],
            flexibleMinimumTime: null,
            locationId: 5,
            resourceMinNumber: 4,
            resourceDesiredNumber: 5,
            resourceMaxNumber: 6,
            agePolicyIndex: 0,
            equipment:
              "Comfortable clothing that can get dirty, closed-toed shoes, water bottle.",
            giverName: ""
          },
          {
            index: 7,
            selected: false,
            stampTitle: "Unload HHA",
            icon: "stamp_truck_01",
            eventType: "OSV",
            eventTitle: "Unload and Set Up",
            description:
              "To prepare for distribution day, a truck will arrive early in the morning with non-perishable food items and produce. Help us unload the truck at this location, set up the tables, and assorted other tasks to prepare for distribution day.\n\nNOTE: This Quest involves lifting very heavy items and lots of walking!",
            serviceTags: ["body"],
            flexibleMinimumTime: null,
            locationId: 15,
            resourceMinNumber: 2,
            resourceDesiredNumber: 3,
            resourceMaxNumber: 4,
            agePolicyIndex: 1,
            equipment:
              "Comfortable clothing that can get dirty, closed-toed shoes, water bottle.",
            giverName: ""
          },
          {
            index: 8,
            selected: false,
            stampTitle: "Distribution",
            icon: "stamp_people_01",
            eventType: "OSV",
            eventTitle: "Distribution Day",
            description:
              "Every two weeks, our pantry distributes food to our neighbors at three different locations across Hoboken. All locations must open by 10 am, so we need volunteers there early to help with last minute set up tasks, and orientation for first-time volunteers. You'll help us manage the line, check people in, and give them bags of food.\n\nPlease note that this event involves lots of walking, lifting, and standing.",
            serviceTags: ["food"],
            flexibleMinimumTime: null,
            locationId: 4,
            resourceMinNumber: 2,
            resourceDesiredNumber: 3,
            resourceMaxNumber: 3,
            agePolicyIndex: 2,
            equipment:
              "Comfortable clothing that can get dirty, closed-toed shoes, water bottle.",
            giverName: ""
          },
          {
            index: 9,
            selected: false,
            stampTitle: "Translate Spanish",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Translate Spanish",
            description:
              "Many of the clients who come to our food distribution only speak Spanish. Help our volunteers translate so we can get our clients' information recorded accurately and address their concerns. We need to open promptly at 10 am, so we need volunteers there early to help with last minute set up tasks and orientation for first-time volunteers.\n\nPlease only volunteer for this Quest if you're good at Spanish!",
            serviceTags: ["speech"],
            flexibleMinimumTime: null,
            locationId: 4,
            resourceMinNumber: 1,
            resourceDesiredNumber: 2,
            resourceMaxNumber: 2,
            agePolicyIndex: 2,
            equipment:
              "Comfortable clothing that can get dirty, closed-toed shoes, water bottle.",
            giverName: ""
          },
          {
            index: 10,
            selected: false,
            stampTitle: "Stock PCP",
            icon: "stamp_shelf_01",
            eventType: "OSV",
            eventTitle: "Stock Shelves",
            description:
              "Once per month, we open our Personal Care Pantry up to those in need of hygienic supplies, household goods, pet products, and other non-food related items. We need volunteers to help us organize the shelves to prepare for our next distribution.",
            serviceTags: ["hygiene"],
            flexibleMinimumTime: null,
            locationId: 4,
            resourceMinNumber: 3,
            resourceDesiredNumber: 4,
            resourceMaxNumber: 5,
            agePolicyIndex: 0,
            equipment:
              "Comfortable clothing that can get dirty, closed-toed shoes, water bottle.",
            giverName: ""
          },
          {
            index: 11,
            selected: false,
            stampTitle: "PCP",
            icon: "stamp_people_01",
            eventType: "OSV",
            eventTitle: "Personal Care Pantry",
            description:
              "We have a Personal Care Pantry that is open to anyone in need of hygiene and baby supplies, pet products, and other non-food related items.\n\nTasks for this shift include helping clients fill out their shopping checklists, bagging the items they need, checking the bags, distributing additional items, and restocking the shelves.",
            serviceTags: ["hygiene"],
            flexibleMinimumTime: null,
            locationId: 4,
            resourceMinNumber: 5,
            resourceDesiredNumber: 7,
            resourceMaxNumber: 9,
            agePolicyIndex: 2,
            equipment:
              "Comfortable clothing that can get dirty, closed-toed shoes, water bottle.",
            giverName: ""
          },
          {
            index: 12,
            selected: false,
            stampTitle: "PCP Spanish",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Translate Spanish",
            description:
              "Many of the clients we distribute to in our community only speak Spanish. Help our volunteers translate so we can get our clients' information recorded accurately and address their concerns.\n\nPlease only volunteer for this Quest if you're good at Spanish!",
            serviceTags: ["speech"],
            flexibleMinimumTime: null,
            locationId: 4,
            resourceMinNumber: 3,
            resourceDesiredNumber: 4,
            resourceMaxNumber: 6,
            agePolicyIndex: 2,
            equipment:
              "Comfortable clothing that can get dirty, closed-toed shoes, water bottle.",
            giverName: ""
          },
          {
            index: 13,
            selected: false,
            stampTitle: "County Delivery",
            icon: "stamp_truck_01",
            eventType: "OSV",
            eventTitle: "Food Delivery",
            description:
              "We're expecting a shipment of food. Help us unload the truck, and open the packages. We'll organize the pantry while we await the delivery.\n\nThis Quest involves a lot of lifting heavy items and walking!",
            serviceTags: ["body"],
            flexibleMinimumTime: null,
            locationId: 4,
            resourceMinNumber: 3,
            resourceDesiredNumber: 3,
            resourceMaxNumber: 4,
            agePolicyIndex: 1,
            equipment:
              "Comfortable clothing that can get dirty, closed-toed shoes, water bottle.",
            giverName: ""
          },
          {
            index: 14,
            selected: false,
            stampTitle: "Shelve Delivery",
            icon: "stamp_shelf_01",
            eventType: "OSV",
            eventTitle: "Shelve Delivery",
            description:
              "Our monthly delivery of non-perishables arrived from the Community Food Bank of New Jersey earlier today. We need volunteers to put all of this food on shelves where it belongs and take inventory. At the end of the shift, we'll clean up around the pantry to get it ready for the week.\n\nThis Quest involves a lot of lifting heavy items and walking!",
            serviceTags: ["body"],
            flexibleMinimumTime: null,
            locationId: 4,
            resourceMinNumber: 3,
            resourceDesiredNumber: 4,
            resourceMaxNumber: 6,
            agePolicyIndex: 1,
            equipment:
              "Comfortable clothing that can get dirty, closed-toed shoes, water bottle.",
            giverName: ""
          },
          {
            index: 15,
            selected: false,
            stampTitle: "Drop Off Hours",
            icon: "stamp_box_01",
            eventType: "OSV",
            eventTitle: "Drop Off Hours",
            description:
              "During our public drop off hours, people can come by to bring us donations. We need volunteers to help move boxes, open packages, and organize the pantry.\n\nWhile we wait for donations to arrive, we will consolidate the items we already have on our shelves to make space for more deliveries.\n\nIf you're donating items, you don't need to sign up here. This is only for the volunteers helping us during this shift.",
            serviceTags: ["body"],
            flexibleMinimumTime: null,
            locationId: 4,
            resourceMinNumber: 2,
            resourceDesiredNumber: 3,
            resourceMaxNumber: 4,
            agePolicyIndex: 0,
            equipment:
              "Comfortable clothing that can get dirty, closed-toed shoes, water bottle.",
            giverName: ""
          },
          {
            index: 16,
            selected: false,
            stampTitle: "Main St. Pops",
            icon: "stamp_people_01",
            eventType: "OSV",
            eventTitle: "Main St. Pops",
            description:
              "The HCC is going to be tabling at the Main Street Pops Artisan Market. Help our Pantry leadership team tell the community about our ways to support and volunteer with the HCC Pantry, as well as talk about #GivingTuesday and our HCC Holiday Market for clients on December 9th. The table will also be staffed with a Pantry leadership team member and another Pantry volunteer.\n\nGood for anyone comfortable talking to market goers about the HCC and our programs. Talking points & Pantry info materials will be provided.\n\n<b>This shift is for experienced HCC Pantry volunteers and members of the HCC Event Squad.</b>",
            serviceTags: ["speech"],
            flexibleMinimumTime: null,
            locationId: 21,
            resourceMinNumber: 1,
            resourceDesiredNumber: 2,
            resourceMaxNumber: 2,
            agePolicyIndex: 3,
            equipment: "Comfortable HCC Pantry clothing, water bottle.",
            giverName: "",
            prerequisiteMinutes: 1200
          }
        ],
        bundles: [
          // There will probably be 3 HCCP bundles: PCP Week, Food Week, and CFBNJ Food Week
        ]
      },
      hardcodedStampsForC4P: {
        stamps: [
          {
            index: 0,
            selected: false,
            stampTitle: "Refurbish Computers",
            icon: "stamp_wrench_01",
            eventType: "OSV",
            eventTitle: "Refurbish Computers",
            description:
              "We collect, refurbish, and donate computers to individuals and organizations in need. We need individuals that are willing to learn, or that have experience configuring computer (laptop/desktop) hardware and installing Windows 10 Pro OS. You may be asked to help with incoming donations, equipment sorting, inventory counting, wire & cable sorting and physical moving of electronic equipment.\n\nThe tasks each week depend on how many computers we are donating and to who!\n\nIf you need to call Hector, his number is (201) 669-3062 x 105. If you're feeling sick, please stay home.",
            serviceTags: ["craft"],
            flexibleMinimumTime: 60,
            locationId: 13,
            resourceMinNumber: 1,
            resourceDesiredNumber: 2,
            resourceMaxNumber: 3,
            agePolicyIndex: 0,
            equipment: "",
            giverName: "Hector Ramos"
          },
          {
            index: 1,
            selected: false,
            stampTitle: "Promote Pepper Fest",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Promote Pepper Fest",
            description:
              "Pepper Fest 2024, our annual pepper eating contest & fundraiser, is returning to Hoboken on October 5th! We need volunteers to join our very own Pepper King (Sacha Chavez) around town. We will provide you a pepper costume that you can change into at our headquarters, but you must bring it back!\n\nWe need volunteers to help the Pepper King hand out flyers, talk to potential fundraiser guests, record videos and photos for social media, and invite them to try some of our spicy peppers once they are done growing.",
            serviceTags: ["speech"],
            locationId: 13,
            resourceMinNumber: 2,
            resourceDesiredNumber: 4,
            resourceMaxNumber: 6,
            agePolicyIndex: 1,
            equipment: "",
            giverName: "Nichool Castro"
          },
          {
            index: 2,
            selected: false,
            stampTitle: "Pepper Fest",
            icon: "stamp_people_01",
            eventType: "OSV",
            eventTitle: "Pepper Fest",
            description:
              "Our fundraiser the Hoboken Pepper Festival returns again this year! It's a hot pepper eating competition to raise money for our charity. We need volunteers to help set up the event, take tickets at the door, sell raffle tickets, manage the event, and clean up.\n\nThere will be 10 rounds, each round contestants will need to eat and swallow an entire chili pepper without drinking water/milk. Chili peppers will increase in heat each round. The last standing contestant wins!\n\nThe Festival starts at 1 pm, and the Competition starts at 3 pm.",
            serviceTags: ["treasure"],
            locationId: 25,
            resourceMinNumber: 10,
            resourceDesiredNumber: 12,
            resourceMaxNumber: 15,
            agePolicyIndex: 3,
            equipment: "Poncho",
            giverName: "Dylan Zajac"
          }
        ],
        bundles: []
      },
      hardcodedStampsForC4PMassachusetts: {
        stamps: [
          {
            index: 0,
            selected: false,
            stampTitle: "Refurbish Computers",
            icon: "stamp_wrench_01",
            eventType: "OSV",
            eventTitle: "Refurbish Computers",
            description:
              "We collect, refurbish, and donate computers to individuals and organizations in need. We need individuals that are willing to learn, or that have experience configuring computer (laptop/desktop) hardware and installing Windows 10 Pro OS. You may be asked to help with incoming donations, equipment sorting, inventory counting, wire & cable sorting and physical moving of electronic equipment.\n\nThe tasks each week depend on how many computers we are donating and to who!",
            serviceTags: ["craft"],
            flexibleMinimumTime: 60,
            locationId: 75,
            resourceMinNumber: 1,
            resourceDesiredNumber: 2,
            resourceMaxNumber: 3,
            agePolicyIndex: 0,
            equipment: "",
            giverName: "Dylan Zajac"
          }
        ],
        bundles: []
      },
      hardcodedStampsForDvineKonektion: {
        stamps: [
          {
            index: 0,
            selected: false,
            stampTitle: "Monthly Delivery",
            icon: "stamp_truck_01",
            eventType: "OSV",
            eventTitle: "Unload Food Delivery",
            description:
              "Our pantry is receiving our large monthly delivery of non-perishable food, produce, frozen meat, and hygienic supplies. We need volunteers to carry these heavy items up the steps into our pantry and put them away.\n\nWe'll also need help setting up our large space for distribution by arranging the tables properly, and by placing food at the proper stations.\n\nFinally, we'll consolidate the food in our refrigerators so there's room to add more, and then take out any remaining trash.",
            serviceTags: ["body"],
            flexibleMinimumTime: 60,
            locationId: 11,
            resourceMinNumber: 4,
            resourceDesiredNumber: 8,
            resourceMaxNumber: 10,
            agePolicyIndex: 0,
            equipment: "Comfortable shoes",
            giverName: "Carol Deribeprey"
          },
          {
            index: 1,
            selected: false,
            stampTitle: "Distribution",
            icon: "stamp_people_01",
            eventType: "OSV",
            eventTitle: "Distribute Food",
            description:
              "We need volunteers to help us manage our clients during distribution days. First, we'll do any last minute necessary preparation (taking frozen meat out of the freezer, reviewing the limits on certain items with volunteers, etc) before the doors open at 10 am. Our pantry is a free choice model, which means our clients can choose which items they want to take.\n\nWe'll put you in charge of a designated food category to hand out. You will be in charge of replenishing your supply during the day with the items under the table, and in our back room.\n\nPlease note that all volunteers must remain standing during the entire distribution day.",
            serviceTags: ["food"],
            locationId: 11,
            resourceMinNumber: 8,
            resourceDesiredNumber: 10,
            resourceMaxNumber: 15,
            agePolicyIndex: 0,
            equipment: "Comfortable shoes",
            giverName: "Carol Deribeprey"
          },
          {
            index: 2,
            selected: false,
            stampTitle: "Distribution",
            icon: "stamp_people_01",
            eventType: "OSV",
            eventTitle: "Clean Up",
            description:
              "Atfer distribution ends, we always need a relief team of volunteers to help us reset our pantry. We need help taking down tables, sweeping the floor, putting excess food properly back into our warehouse, throwing out trash, and breaking down boxes.\n\nAt the end, we will mop the floor so it's ready for our next distribution.",
            serviceTags: ["hygiene"],
            locationId: 11,
            resourceMinNumber: 4,
            resourceDesiredNumber: 5,
            resourceMaxNumber: 7,
            agePolicyIndex: 0,
            equipment: "Comfortable shoes",
            giverName: "Carol Deribeprey"
          }
        ],
        bundles: []
      },
      hardcodedStampsForTheOpenDoorNJNY: {
        stamps: [
          {
            index: 0,
            selected: false,
            stampTitle: "English Class",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Teach English",
            description:
              "We teach English as a Second Language (ESL) to Spanish speakers in our neighborhood! We are looking for volunteers to help make the evening go smoothly.\n\nWe will assign you to a particular room based on your Spanish skill, and the number of students that arrive. We need volunteer teaching assistants to help our professors instruct students on vocabulary, pronunciation, conversation skills, and writing in English. During the semester, we will need you to help proctor exams as well. We also need volunteers to take attendance for the students, greet our students, and watch young children (6 y.o. and older) in the kids room.\n\nIf you don't speak Spanish, don't worry! Volunteers with little or no Spanish speaking ability will be paired with our most advanced students (Levels 3 & 4).",
            serviceTags: ["voyage"],
            locationId: 54,
            resourceMinNumber: 10,
            resourceDesiredNumber: 12,
            resourceMaxNumber: 15,
            agePolicyIndex: 0,
            equipment: "",
            giverName: "Jaime Miranda",
            prerequisiteMinutes: 120
          },
          {
            index: 1,
            selected: false,
            stampTitle: "Math Class",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Teach Math",
            description:
              "We teach math to Spanish speakers in our neighborhood so they can pass their General Educational Development (GED) exam. This is essentially a high school degree for adults.\n\nThe pastor that teaches this course can always use a few teaching assistants to help with this course. Spanish speaking ability & knowledge of high school subjects, particularly mathematics, are critical!",
            serviceTags: ["voyage"],
            locationId: 55,
            resourceMinNumber: 1,
            resourceDesiredNumber: 2,
            resourceMaxNumber: 2,
            agePolicyIndex: 0,
            equipment: "",
            giverName: "Jaime Miranda",
            prerequisiteMinutes: 120
          },
          {
            index: 2,
            selected: false,
            stampTitle: "Conversation Night",
            icon: "stamp_people_01",
            eventType: "OSV",
            eventTitle: "Conversation Night",
            description:
              "We teach English as a Second Language (ESL) to Spanish speakers in our neighborhood! We are looking for volunteers to help make the evening go smoothly.\n\nDuring our monthly conversation nights, we break up into groups in the upstairs room & downstairs in the multi-purpose room. We need volunteers to speak to our students in English so they can practice what they have been learning during our night classes. We'll rotate you between different student groups throughout the evening.\n\nIf you don't speak Spanish, don't worry! Volunteers with little or no Spanish speaking ability will be paired with our most advanced students (Levels 3 & 4). We just need you to be patient with our students, gently correct their pronounciation, and help them learn new words.",
            serviceTags: ["voyage"],
            locationId: 54,
            resourceMinNumber: 20,
            resourceDesiredNumber: 22,
            resourceMaxNumber: 25,
            agePolicyIndex: 0,
            equipment: "",
            giverName: "Jaime Miranda",
            prerequisiteMinutes: 120
          }
        ],
        bundles: []
      },
      hardcodedStampsForTempleBethElJC: {
        stamps: [
          {
            index: 0,
            selected: false,
            stampTitle: "Distribute Food",
            icon: "stamp_people_01",
            eventType: "OSV",
            eventTitle: "Distribute Food",
            description:
              "Every week, we open our synagogue's pantry to our hungry neighbors in need. We distribute grocery bags of non-perishable goods like fruit cups, canned goods, snacks, and crackers. We also have a limited supply of baby food for parents with small children as well as some hygienic items.\n\nHelp us distribute food to the community and then pack more bags for the upcoming week. Finally, organize the pantry with us before closing down for the evening.",
            serviceTags: ["food"],
            locationId: 3,
            resourceMinNumber: 1,
            resourceDesiredNumber: 2,
            resourceMaxNumber: 3,
            agePolicyIndex: 0,
            equipment: "",
            giverName: "Karen Seemen Pinn"
          }
        ],
        bundles: []
      },
      hardcodedStampsForAmazingTruthSociety: {
        stamps: [
          {
            index: 0,
            selected: false,
            stampTitle: "Homework Help",
            icon: "stamp_people_01",
            eventType: "OSV",
            eventTitle: "Homework Help",
            description:
              "Help teach math, reading, and other elementary school lessons to our kids after school. You'll be tutoring kids in the kitchen and we have four laptops you can use if needed. At least 15 children come to our program each day, but not all of them will need homework help.\n\nMake sure you feel comfortable with your grade school math, and in particular, the ability to communicate this knowledge to the kids. (Math is something they struggle with the most!)\n\nWhen you arrive we will prep the learning environment for the kids before they get here at 4 pm. Then there will be two hours of quiet study and one-on-one tutoring as needed.",
            serviceTags: ["mind"],
            locationId: 20,
            resourceMinNumber: 2,
            resourceDesiredNumber: 3,
            resourceMaxNumber: 4,
            agePolicyIndex: 0,
            equipment: "Notebook and pen or pencil",
            giverName: "Shihan Ray Rodriguez"
          },
          {
            index: 1,
            selected: false,
            stampTitle: "Teach Guitar",
            icon: "stamp_people_01",
            eventType: "OSV",
            eventTitle: "Teach Guitar",
            description:
              "Help teach guitar lessons to our kids after school. Most of them have guitars that they can borrow from our program, and we have a teacher who is able to make most of our sessions. We are looking for someone who can fill in for him and also assist him during class. If you love music and teaching skills to kids, we would love to have you!",
            serviceTags: ["craft"],
            locationId: 20,
            resourceMinNumber: 1,
            resourceDesiredNumber: 2,
            resourceMaxNumber: 2,
            agePolicyIndex: 0,
            equipment: "Guitar and pick",
            giverName: "Shihan Ray Rodriguez"
          }
        ],
        bundles: []
      },
      hardcodedStampsForStMaryAdvocates: {
        stamps: [
          {
            index: 0,
            selected: false,
            stampTitle: "Thrift Store",
            icon: "stamp_shelf_01",
            eventType: "OSV",
            eventTitle: "Thrift Store",
            description:
              'When our thrift store is open to the public, we need our team members to cover the front of the store or organize donations in the back. The Lead Volunteer at each shift will assign you to different roles as needed, which will help you learn about all the different parts of the store.\n\n<b>Front of Store</b>\n<u>Floor Support:</u> Help accept new donations and replenish shelves.\n<u>Cashier Support:</u> Support the cashier as a "bagger" and assist with front of store maintenance (tidying racks, limiting overcrowding inside)\n<u>Cashier:</u> Open and close the store, handle purchases, close out the register at the end of the day.\n\n<b>Back of Store</b>\n<u>Sorter:</u> Pull pre-sorted items from holding bins. Tag, hang, and replenish the front of the store.\n<u>Lead Sorter:</u> Pre-sort newly donated items into appropriate holding bins.',
            serviceTags: ["garment"],
            flexibleMinimumTime: 240,
            locationId: 65,
            resourceMinNumber: 2,
            resourceDesiredNumber: 4,
            resourceMaxNumber: 6,
            agePolicyIndex: 0,
            equipment: "Stylish, professional clothing",
            giverName: "Sandra DiMatteo",
            prerequisiteMinutes: 240
          },
          {
            index: 1,
            selected: false,
            stampTitle: "Outside Event",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Event Table",
            description:
              "We need volunteers to help us at this outdoor event! You'll help us set up our display and communicate our mission to the public.",
            serviceTags: ["speech"],
            locationId: 65,
            resourceMinNumber: 1,
            resourceDesiredNumber: 2,
            resourceMaxNumber: 3,
            agePolicyIndex: 0,
            equipment: "Stylish, professional clothing",
            giverName: "Sandra DiMatteo",
            prerequisiteMinutes: 240
          }
        ],
        bundles: []
      },
      hardcodedStampsForVolunteerFoodRescue: {
        stamps: [
          {
            index: 0,
            selected: false,
            stampTitle: "Prato Bakery",
            icon: "stamp_bread_01",
            eventType: "OSV",
            eventTitle: "Rescue Food",
            description:
              "This bakery has generously decided to allow our volunteer team to pick up unsold items from them at the end of the day and bring them to a nearby charity, instead of throwing perfectly good food in the garbage!\n\nWe need volunteers to arrive at the bakery promptly right before closing time and present themselves to the Store Manager. Please make a record of how many items are being donated, place them into a cart, and head directly to the Hoboken Shelter to donate everything. There is a form that the staff at the Hoboken Shelter need to sign to confirm the donation.\n\n<b>Warning:</b> The manager of the store checks Charity Quest each day to see if a volunteer is coming. If no one is signed up, the staff will assume no one is coming and throw the food in the trash.",
            serviceTags: ["food"],
            locationId: 69,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 1,
            agePolicyIndex: 0,
            equipment: "Work gloves",
            giverName: "Store Manager"
          }
        ],
        bundles: []
      },
      hardcodedStampsForLetsCelebrate: {
        stamps: [
          // M-F brunch duty
          {
            index: 0,
            selected: false,
            stampTitle: "Pantry Hours",
            icon: "stamp_bag_01",
            eventType: "OSV",
            eventTitle: "Help Clients Shop",
            description:
              "Twice a week, we open our free choice pantry to hungry residents of the community. They are allowed to browse the shelves of our pantry, which is set up like a grocery store, and take limited quantities of items. We need a few volunteers to help them pick out items, reach things on high shelves, get heavy bags into their carts, enforce our rules, and bring their carts down the steps.",
            serviceTags: ["food"],
            locationId: 42,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 1,
            agePolicyIndex: 0,
            equipment: "Comfortable, closed-toed shoes",
            giverName: "Angelique Williams"
          },
          {
            index: 1,
            selected: false,
            stampTitle: "Translate Arabic",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Translate Arabic",
            description:
              "Twice a week, our free choice food pantry is open to residents of the community. We have noticed a large increase in the number of Arabic-speaking neighbors and we would like to have at least one volunteer who can assist them. You will help clients browse for groceries in our pantry, and if anyone needs to be registered we can take care of that on a different day.",
            serviceTags: ["speech"],
            locationId: 42,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 1,
            agePolicyIndex: 0,
            equipment: "Comfortable, closed-toed shoes",
            giverName: "Angelique Williams"
          },
          {
            index: 2,
            selected: false,
            stampTitle: "Translate Spanish",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Translate Spanish",
            description:
              "Twice a week, our free choice food pantry is open to residents of the community. We have many Spanish-speaking neighbors and we would like to have at least one volunteer who can assist them. You will help clients browse for groceries in our pantry, and if anyone needs to be registered we can take care of that on a different day.",
            serviceTags: ["speech"],
            locationId: 42,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 1,
            agePolicyIndex: 0,
            equipment: "Comfortable, closed-toed shoes",
            giverName: "Angelique Williams"
          },
          {
            // ALL mondays and tuesdays 11:15 to 1:30 pm
            index: 3,
            selected: false,
            stampTitle: "Serve Lunch",
            icon: "stamp_salad_01",
            eventType: "OSV",
            eventTitle: "Serve Lunch",
            description:
              "Every week day, our charity gives out a grab-and-go style hot lunch to anyone who comes to us. Our chef will prepare the food, but we need volunteers to plate everything and serve them to our clients. You'll be checking clients in at the lunch window so we have a record of how many people we served, and then handing them the food through the window.\n\nAt the end of lunch, we will need your help with clean up as well.",
            serviceTags: ["food"],
            locationId: 42,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 2,
            agePolicyIndex: 0,
            equipment:
              "Comfortable, closed-toed shoes - HAIRNET AND GLOVES PROVIDED",
            giverName: "Angelique Williams"
          },
          {
            index: 4,
            selected: false,
            stampTitle: "Monthly Delivery",
            icon: "stamp_truck_01",
            eventType: "OSV",
            eventTitle: "Food Delivery",
            description:
              "Once a month, our free choice pantry receives a big delivery of food from the Community Food Bank of New Jersey. This is a massive shipment of non-perishable canned goods that will go on our shelves. We need volunteers who can held us unload everything from the truck, check for any missing items, and put things away where they belong.",
            serviceTags: ["body"],
            locationId: 42,
            resourceMinNumber: 2,
            resourceDesiredNumber: 4,
            resourceMaxNumber: 6,
            agePolicyIndex: 0,
            equipment: "Closed-toed shoes and gloves",
            giverName: "Angelique Williams"
          }
        ],
        bundles: []
      },
      hardcodedStampsForGoodSamaritanGroup: {
        stamps: [
          {
            index: 0,
            selected: false,
            stampTitle: "CFBNJ Delivery",
            icon: "stamp_truck_01",
            eventType: "OSV",
            eventTitle: "Unload Food",
            description:
              "Our pantry is receiving our large monthly delivery of non-perishable food, produce, frozen meat, and hygienic supplies. We need volunteers to carry these heavy items into our pantry and put them away.\n\nWe'll also need help setting up our large space for distribution by arranging the tables properly, and by placing food at the proper stations.\n\nFinally, we'll consolidate the food in our refrigerators so there's room to add more, and then take out any remaining trash.",
            serviceTags: ["body"],
            locationId: 63,
            resourceMinNumber: 2,
            resourceDesiredNumber: 4,
            resourceMaxNumber: 6,
            agePolicyIndex: 0,
            equipment: "Closed-toed shoes, work gloves",
            giverName: "Albert Aziz"
          },
          {
            index: 1,
            selected: false,
            stampTitle: "Distribution Prep",
            icon: "stamp_people_01",
            eventType: "OSV",
            eventTitle: "Distribution Prep",
            description:
              "Every two weeks, our church has a food distribution for the community. It is set up like a market that our neighbors can walk through, with their bag, and take the items they wish.\n\nTo prepare this market style distribution, we need volunteers to help us set up the tables. You will unbox food items, and arrange them on the proper tables. Our food market has eight different stations.\n\nWhen we finish with this shift, we will break down any cardboard boxes and do a final cleaning before we open for clients.",
            serviceTags: ["food"],
            locationId: 63,
            resourceMinNumber: 2,
            resourceDesiredNumber: 3,
            resourceMaxNumber: 5,
            agePolicyIndex: 0,
            equipment: "Closed-toed shoes, work gloves",
            giverName: "Albert Aziz"
          },
          {
            index: 2,
            selected: false,
            stampTitle: "Distribute Food",
            icon: "stamp_people_01",
            eventType: "OSV",
            eventTitle: "Distribute Food",
            description:
              "Every two weeks, our church has a food distribution for the community. It is set up like a market that our neighbors can walk through, with their bag, and take the items they wish. We have a variety of different positions we may put you in if you sign up. You might be handling volunteer intake, or handing out food in the line, or replenishing supplies on the tables as they run out.",
            serviceTags: ["food"],
            locationId: 63,
            resourceMinNumber: 4,
            resourceDesiredNumber: 6,
            resourceMaxNumber: 8,
            agePolicyIndex: 0,
            equipment: "Closed-toed shoes, work gloves",
            giverName: "Albert Aziz"
          },
          {
            index: 3,
            selected: false,
            stampTitle: "Translate Spanish",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Translate Spanish",
            description:
              "Every two weeks, our church has a food distribution for the community. It is set up like a market that our neighbors can walk through, with their bag, and take the items they wish.\n\nIf you speak fluent Spanish, please sign up for this shift so we know about your language skills. You will help us with registering new clients and checking in anybody who does not speak English.",
            serviceTags: ["speech"],
            locationId: 63,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 2,
            agePolicyIndex: 0,
            equipment: "Closed-toed shoes, work gloves",
            giverName: "Albert Aziz"
          }
        ],
        bundles: []
      },
      hardcodedStampsForCrossRoadFoundation: {
        stamps: [
          {
            index: 0,
            selected: false,
            stampTitle: "Open Pantry Hours",
            icon: "stamp_bag_01",
            eventType: "OSV",
            eventTitle: "Open Pantry Hours",
            description:
              "Cross Road Foundation runs a mom-and-baby pantry for families in our area. We need volunteers to help sort & hang clothing, sort toys, and stock our shelves with donated food.\n\nFrom 10 am to 12 pm, moms will come into the pantry and we need someone to walk them through the pantry and help them pick out items.\n\nOnce the moms leave, we need volunteers to help us clean up and prepare for our next open hours!",
            serviceTags: ["life"],
            flexibleMinimumTime: 60,
            locationId: 49,
            resourceMinNumber: 4,
            resourceDesiredNumber: 5,
            resourceMaxNumber: 6,
            agePolicyIndex: 0,
            equipment: "",
            giverName: "Kim Carbonaro"
          },
          {
            index: 1,
            selected: false,
            stampTitle: "Clean Up",
            icon: "stamp_shelf_01",
            eventType: "OSV",
            eventTitle: "Clean Up",
            description:
              "Cross Road Foundation runs a mom-and-baby pantry for families in our area. During days when our pantry isn't open to clients, we take the opportunity to clean up and organize. We need volunteers to help sort & hang clothing, sort toys, and stock our shelves with donated food.\n\nWe'll wipe down all surfaces and sweep the floor to help us prepare for our next open hours.",
            serviceTags: ["hygiene"],
            flexibleMinimumTime: 60,
            locationId: 49,
            resourceMinNumber: 4,
            resourceDesiredNumber: 5,
            resourceMaxNumber: 6,
            agePolicyIndex: 0,
            equipment: "",
            giverName: "Kim Carbonaro"
          }
        ],
        bundles: []
      },
      hardcodedStampsForStatenIslandCatholicWorker: {
        stamps: [
          {
            index: 0,
            selected: false,
            stampTitle: "Decorate Room",
            icon: "stamp_bread_01",
            eventType: "OSV",
            eventTitle: "Decorate Room",
            description:
              "This is the day of our monthly Catholic Worker meal! We invite guests into the basement of our chuch for a free hot meal and access to community resources. We need volunteers to set the tables up and decorate the room. Each table needs sets of silverware rolled in napkins, water pitchers, bread, and floral centerpieces. There's a separate station for dessert and drinks that volunteers also need to set up.\n\nWe also need help preparing the resource tables where we give out pamphlets to local organizations, clothing, and spiritual items.",
            serviceTags: ["food"],
            locationId: 47,
            resourceMinNumber: 5,
            resourceDesiredNumber: 6,
            resourceMaxNumber: 8,
            agePolicyIndex: 0,
            equipment: "",
            giverName: "Deborah Sucich"
          },
          {
            index: 1,
            selected: false,
            stampTitle: "Manage Buffet Line",
            icon: "stamp_knife_01",
            eventType: "OSV",
            eventTitle: "Manage Buffet Line",
            description:
              "This is the day of our monthly Catholic Worker meal! We invite guests into the basement of our chuch for a free hot meal and access to community resources. We need very strong volunteers to man the buffet line, since this involves carrying heavy boiling hot trays of water. You'll help us set up the food before our guests arrive around 12:30 pm, and you'll be in charge of the buffet line during lunch.",
            serviceTags: ["body"],
            locationId: 47,
            resourceMinNumber: 5,
            resourceDesiredNumber: 6,
            resourceMaxNumber: 10,
            agePolicyIndex: 0,
            equipment: "",
            giverName: "Deborah Sucich"
          },
          {
            index: 2,
            selected: false,
            stampTitle: "Serve Lunch",
            icon: "stamp_salad_01",
            eventType: "OSV",
            eventTitle: "Serve Lunch",
            description:
              "This is the day of our monthly Catholic Worker meal! We invite guests into the basement of our chuch for a free hot meal and access to community resources.\n\nWe need volunteers to help us wait on tables during lunch. Each table will be assigned two servers, and we can provide you with a clipboard & menu so you can take their orders. (We usually have about 15 tables)\n\nYou also might be assigned to a resource table that has pamphlets for different mental health / spirituality services in the community. There are various giveaway tables that have winter clothing, rosaries, medals, and other items that you can hand out to our guests.",
            serviceTags: ["body"],
            locationId: 47,
            resourceMinNumber: 30,
            resourceDesiredNumber: 40,
            resourceMaxNumber: 50,
            agePolicyIndex: 0,
            equipment: "",
            giverName: "Deborah Sucich"
          },
          {
            index: 3,
            selected: false,
            stampTitle: "Post-Meal Clean Up",
            icon: "stamp_bag_01",
            eventType: "OSV",
            eventTitle: "Post-Meal Clean Up",
            description:
              "This is the day of our monthly Catholic Worker meal! We invite guests into the basement of our chuch for a free hot meal and access to community resources.\n\nWe need volunteers to help us clean up after the guests leave. We'll be washing silverware, dishes, pots, pans, and any trays that can be salvaged. The food heaters will need to be disposed of safely once they cool down, and we'll wipe down all of the tables. Finally, we will sweep the floor to make sure we leave it cleaner than we found it.",
            serviceTags: ["hygiene"],
            locationId: 47,
            resourceMinNumber: 5,
            resourceDesiredNumber: 6,
            resourceMaxNumber: 8,
            agePolicyIndex: 0,
            equipment: "",
            giverName: "Deborah Sucich"
          },
          {
            index: 4,
            selected: false,
            stampTitle: "Fundraiser Setup",
            icon: "stamp_people_01",
            eventType: "OSV",
            eventTitle: "Arts & Crafts Fundraiser",
            description:
              "This is the day of our Arts & Crafts Fundraiser! To set up for this event, we need volunteers to help set up tables for artists and craftsman. You will help us carry their homemade arts, crafts, and paintings inside from their cars.\n\nFinally, we will finish up any last-minute decorating we need to do before the doors open at 10 am!",
            serviceTags: ["treasure"],
            locationId: 47,
            resourceMinNumber: 4,
            resourceDesiredNumber: 5,
            resourceMaxNumber: 6,
            agePolicyIndex: 0,
            equipment: "",
            giverName: "Deborah Sucich"
          },
          {
            index: 5,
            selected: false,
            stampTitle: "Fundraiser",
            icon: "stamp_people_01",
            eventType: "OSV",
            eventTitle: "Arts & Crafts Fundraiser",
            description:
              "This is the day of our Arts & Crafts Fundraiser! During the event, we need volunteers to help the day go smoothly. Some volunteers will be assigned to the greeting table to accept donations, and others will be raffle runners. We will assign you to a spot when you arrive!",
            serviceTags: ["treasure"],
            locationId: 47,
            resourceMinNumber: 4,
            resourceDesiredNumber: 5,
            resourceMaxNumber: 6,
            agePolicyIndex: 0,
            equipment: "",
            giverName: "Deborah Sucich"
          },
          {
            index: 6,
            selected: false,
            stampTitle: "Fundraiser Breakdown",
            icon: "stamp_people_01",
            eventType: "OSV",
            eventTitle: "Arts & Crafts Fundraiser",
            description:
              "This is the day of our Arts & Crafts Fundraiser! After the event ends, we need volunteers to help take down the tables and decorations. You will help our guests carry their remaining artwork back into their cars.\n\nFinally, we will sweep the floor to make sure we leave it cleaner than we found it.",
            serviceTags: ["treasure"],
            locationId: 47,
            resourceMinNumber: 4,
            resourceDesiredNumber: 5,
            resourceMaxNumber: 6,
            agePolicyIndex: 0,
            equipment: "",
            giverName: "Deborah Sucich"
          },
          {
            index: 7,
            selected: false,
            stampTitle: "Homeless Outreach",
            icon: "stamp_bread_01",
            eventType: "OSV",
            eventTitle: "Park Outreach",
            description:
              "Every last Saturday of the month, we have an outreach volunteer event at a nearby park. Earlier today we made some sandwiches to hand out. We need volunteers to join us as we meet, greet, talk, or pray with those in need as we distribute sandwiches.\n\nIf the weather is really bad, we will all meet at the nearby ferry terminal instead!",
            serviceTags: ["soul"],
            locationId: 71,
            resourceMinNumber: 6,
            resourceDesiredNumber: 8,
            resourceMaxNumber: 10,
            agePolicyIndex: 0,
            equipment: "",
            giverName: "Deborah Sucich"
          }
        ],
        bundles: []
      },
      hardcodedStampsForSistersOfLife: {
        stamps: [
          {
            index: 0,
            selected: false,
            stampTitle: "Sample Quest",
            icon: "stamp_bag_01",
            eventType: "OSV",
            eventTitle: "Sample Quest",
            description:
              "Quest descriptions should be detailed and thorough. Volunteers are more likely to sign up if they have a clear idea of what they are being asked to do.",
            serviceTags: ["life"],
            locationId: 62,
            resourceMinNumber: 2,
            resourceDesiredNumber: 2,
            resourceMaxNumber: 3,
            agePolicyIndex: 0,
            equipment: "Comfortable, closed-toed shoes",
            giverName: "Sr. Maria Frassati"
          }
        ],
        bundles: []
      },
      hardcodedStampsForStPhilipNeriBronxNY: {
        stamps: [
          {
            index: 0,
            selected: false,
            stampTitle: "Prepare Bags",
            icon: "stamp_bag_01",
            eventType: "OSV",
            eventTitle: "Prepare Bags",
            description:
              "Our church runs a mom-and-baby pantry for families in our area. On distribution days, we need volunteers to help prepare bags for local moms with diapers, wipes, baby clothes, kids clothes, and clothes for teens. The supplies each family gets depends on the items they need, their childrens' clothing sizes, and whether they are boys or girls.",
            serviceTags: ["life"],
            locationId: 61,
            resourceMinNumber: 2,
            resourceDesiredNumber: 2,
            resourceMaxNumber: 3,
            agePolicyIndex: 0,
            equipment: "Comfortable, closed-toed shoes",
            giverName: "Sr. Maria Frassati"
          },
          {
            index: 1,
            selected: false,
            stampTitle: "Greeter",
            icon: "stamp_people_01",
            eventType: "OSV",
            eventTitle: "Greeter",
            description:
              "Our church runs a mom-and-baby pantry for families in our area. On distribution days, we need one volunteer posted outside to direct women inside. You'll keep an eye out for moms who may not know where the entrance is, and help them bring their strollers down the parish steps + the steps into the basement. Many women who come to us only speak Spanish, so we are looking for a volunteer with passable Spanish skill.\n\nThis role includes a lot of heavy lifting, standing, and walking up and down steps. Please only sign up for this if you are healthy enough to handle that!",
            serviceTags: ["defender"],
            locationId: 61,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 1,
            agePolicyIndex: 0,
            equipment: "Comfortable, closed-toed shoes",
            giverName: "Sr. Maria Frassati"
          },
          {
            index: 2,
            selected: false,
            stampTitle: "Runner",
            icon: "stamp_bag_01",
            eventType: "OSV",
            eventTitle: "Runner",
            description:
              "Our church runs a mom-and-baby pantry for families in our area. On distribution days, we need volunteers to help pack bags & hand out bags to our clients.\n\nAlthough we encourage women to sign up ahead of time, we also get walk-ins and we need volunteers to pack bags of baby clothes, baby items, and hygiene supplies quickly.\n\nOnce all the orders have been filled, we'll need your help to clean the pantry up so it's ready for the next distribution.",
            serviceTags: ["life"],
            locationId: 61,
            resourceMinNumber: 1,
            resourceDesiredNumber: 2,
            resourceMaxNumber: 3,
            agePolicyIndex: 0,
            equipment: "Comfortable, closed-toed shoes",
            giverName: "Sr. Maria Frassati"
          },
          {
            index: 3,
            selected: false,
            stampTitle: "Meet Women",
            icon: "stamp_bag_01",
            eventType: "OSV",
            eventTitle: "Meet Women",
            description:
              "Our church runs a mom-and-baby pantry for families in our area. On distribution days, we need volunteers to meet on-site with women and their children. You will talk to them to get basic information like how they are doing, what items they need, and direct them to other resources in the area.\n\nVolunteers who sign up for this particular role must be Safe Environment Trained and go through our orientation.\n\nFor more information on our Safe Environment Training, please email coworkersoflife@sistersoflife.org.",
            serviceTags: ["life"],
            locationId: 61,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 2,
            agePolicyIndex: 0,
            prerequisiteMinutes: 6000,
            equipment: "Comfortable, closed-toed shoes",
            giverName: "Sr. Maria Frassati"
          },
          {
            index: 4,
            selected: false,
            stampTitle: "Translate Spanish",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Translate Spanish",
            description:
              "Our church runs a mom-and-baby pantry for families in our area. On distribution days, we need a few bilingual volunteers who speak fluent English and Spanish to help us register new clients and communicate with anyone who doesn't speak English.",
            serviceTags: ["speech"],
            locationId: 61,
            resourceMinNumber: 1,
            resourceDesiredNumber: 2,
            resourceMaxNumber: 3,
            agePolicyIndex: 0,
            equipment: "Comfortable, closed-toed shoes",
            giverName: "Sr. Maria Frassati"
          },
          {
            index: 5,
            selected: false,
            stampTitle: "Organize Donations",
            icon: "stamp_shelf_01",
            eventType: "OSV",
            eventTitle: "Organize Donations",
            description:
              "Our church runs a mom-and-baby pantry for families in our area. During our public drop off hours, people can come by to bring us donations. We need volunteers to help move boxes, open packages, and organize the pantry.\n\nWhile we wait for donations to arrive, we will consolidate the items we already have on our shelves to make space for more deliveries.\n\n(If you're donating items, you don't need to sign up here. This is only for the volunteers helping us during this shift.)",
            serviceTags: ["body"],
            locationId: 61,
            resourceMinNumber: 2,
            resourceDesiredNumber: 3,
            resourceMaxNumber: 4,
            agePolicyIndex: 0,
            equipment: "Comfortable, closed-toed shoes",
            giverName: "Sr. Maria Frassati"
          },
          {
            index: 6,
            selected: false,
            stampTitle: "Tech Specialist",
            icon: "stamp_wrench_01",
            eventType: "OSV",
            eventTitle: "Tech Specialist",
            description:
              "Our church runs a mom-and-baby pantry for families in our area. On distribution days, we need a volunteer to track Mommy appointments, record data, and place orders through Target or Amazon for the moms as needs arise. This volunteer should be somewhat savvy with technology and Google Workspace programs like Sheets, Docs, and Drive.\n\nYou may need to bring your own mobile device or computer, on occasion. English required. ",
            serviceTags: ["craft"],
            locationId: 61,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 1,
            agePolicyIndex: 0,
            equipment: "Comfortable, closed-toed shoes",
            giverName: "Sr. Maria Frassati"
          }
        ],
        bundles: []
      },
      hardcodedStampsForShirleyLArmstrongScholarshipFund: {
        stamps: [
          {
            index: 0,
            selected: false,
            stampTitle: "Serve Lunch",
            icon: "stamp_salad_01",
            eventType: "OSV",
            eventTitle: "Serve Lunch",
            description:
              "The Shirley Leota Foreman Armstrong Scholarship Fund (SLFASF) is charitable Soup Kitchen set up in memory of the founder's mother. We serve over 75 people every two weeks and we need volunteers to make sure this feeding ministry succeeds.\n\nFirst, we need to set up the tables for our guests to eat and for us to place the buffet. Then we'll prepare a hot balanced meal (protein, vegetables, and grains) that will be served buffet line style once we begin. We also need volunteers to pack about 65 lunch bags with sandwiches, water, juice, chips, fruit, and a dessert.\n\nFinally, we'll clean up the room and bring the tables back upstairs.",
            serviceTags: ["food"],
            locationId: 80,
            resourceMinNumber: 4,
            resourceDesiredNumber: 6,
            resourceMaxNumber: 8,
            agePolicyIndex: 0,
            equipment: "Comfortable clothing",
            giverName: "Jeanette Lawrence"
          },
          {
            index: 1,
            selected: false,
            stampTitle: "Distribute Food",
            icon: "stamp_bag_01",
            eventType: "OSV",
            eventTitle: "Distribute Food",
            description:
              "Our church has a food pantry that opens on the third Friday of each month, every other month. We need strong volunteers to help us move the tables in our basement dining hall outside so we can create a free-choice pantry for our neighbors. After we set up the tables, we'll create stations for different food items and assign you to one of the tables. We will give you further instructions about the amount of food that our neighbors can take from each table. We also need help checking in neighbors when they come to the door and bringing food out to their cars.<br><br>We will clean up as we go to make sure we are finished by 2 pm. Excess food will be stored in our back room for the next distribution day.",
            serviceTags: ["food"],
            locationId: 79,
            resourceMinNumber: 2,
            resourceDesiredNumber: 3,
            resourceMaxNumber: 4,
            agePolicyIndex: 0,
            equipment: "Closed-toed shoes (Gloves provided)",
            giverName: "Kim Riddick"
          }
        ],
        bundles: []
      },
      hardcodedStampsForForMyGoodWorks: {
        stamps: [
          {
            index: 0,
            selected: false,
            stampTitle: "Unload Food Delivery",
            icon: "stamp_truck_01",
            eventType: "OSV",
            eventTitle: "Unload Food Delivery",
            description:
              "Every two weeks on the day before our food distribution, our pantry receives a large delivery of non-perishable food, produce, frozen meat, and hygienic supplies. We need volunteers to carry these items into our pantry and stock the shelves.<br><br>Finally, we'll consolidate the food in our refrigerators so there's room to add more, and then take out any remaining trash.",
            serviceTags: ["body"],
            locationId: 78,
            resourceMinNumber: 3,
            resourceDesiredNumber: 4,
            resourceMaxNumber: 5,
            agePolicyIndex: 0,
            equipment: "Comfortable shoes",
            giverName: "Peter Connolly"
          },
          {
            index: 1,
            selected: false,
            stampTitle: "Distribute Food",
            icon: "stamp_people_01",
            eventType: "OSV",
            eventTitle: "Distribute Food",
            description:
              "We need volunteers to help us manage our clients during distribution days. First, we'll do any last minute necessary preparation (taking frozen meat out of the freezer, reviewing the limits on certain items with volunteers, etc) before the doors open at 10 am. Our pantry is a free choice model, which means our clients can choose which items they want to take.<br><br>We'll put you in charge of a designated food category to hand out. You will be in charge of replenishing your supply during the day with the items under the table, and in our back room. We also need volunteers to help carry groceries to our neighbors' cars.<br><br>Please note that all volunteers must remain standing during the entire distribution day.",
            serviceTags: ["food"],
            locationId: 78,
            resourceMinNumber: 4,
            resourceDesiredNumber: 6,
            resourceMaxNumber: 8,
            agePolicyIndex: 0,
            equipment: "Comfortable shoes",
            giverName: "Kimberly Foy"
          },
          {
            index: 2,
            selected: false,
            stampTitle: "Translate Spanish",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Translate Spanish",
            description:
              "We need volunteers to help us manage our clients during distribution days. During distribution, we need a few bilingual volunteers who speak fluent English and Spanish to help us register new clients and communicate with anyone who doesn't speak English.<br><br>Please note that all volunteers must remain standing during the entire distribution day.",
            serviceTags: ["speech"],
            locationId: 78,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 2,
            agePolicyIndex: 0,
            equipment: "Comfortable shoes",
            giverName: "Kimberly Foy"
          }
        ],
        bundles: []
      },
      hardcodedStampsForRadiantChurchPopUpFoodPantry: {
        stamps: [
          {
            index: 0,
            selected: false,
            stampTitle: "Unload Food",
            icon: "stamp_truck_01",
            eventType: "OSV",
            eventTitle: "Carry Food",
            description:
              "Our church runs a pop-up food pantry once a month for our hungry neighbors using the parking lot of the Embassy Suites in Piscataway. Before we open to the public, we need to set up our market style food pantry. Since our food is inside a big box truck on wooden pallets, we need strong volunteers to help us get this heavy food out of the truck using a pallet jack. (Don't worry if you've never used one before, we can show you how they work!)\n\nOnce the pallets are off the truck, we will need your help setting up the food at different stations in our market-style open air pop-up food pantry. The cases of food themselves are heavy, so make sure you're able to carry at least 25 lbs.",
            serviceTags: ["body"],
            locationId: 84,
            resourceMinNumber: 2,
            resourceDesiredNumber: 3,
            resourceMaxNumber: 4,
            agePolicyIndex: 0,
            equipment: "Gloves and steel-toed boots",
            giverName: "Lori Collins"
          },
          {
            index: 1,
            selected: false,
            stampTitle: "Translate Spanish",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Translate Spanish",
            description:
              "Our church runs a pop-up food pantry once a month for our hungry neighbors using the parking lot of the Embassy Suites in Piscataway. When we are open to the public, we need a few bilingual volunteers to help our staff communicate with clients that only speak Spanish.",
            serviceTags: ["speech"],
            locationId: 84,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 2,
            agePolicyIndex: 1,
            equipment: "",
            giverName: "Lori Collins"
          },
          {
            index: 2,
            selected: false,
            stampTitle: "Put Food Back",
            icon: "stamp_truck_01",
            eventType: "OSV",
            eventTitle: "Carry Food",
            description:
              "Our church runs a pop-up food pantry once a month for our hungry neighbors using the parking lot of the Embassy Suites in Piscataway. At the end of distribution, we typically have food left over and it has to be brought back to our warehouse. All of the food is placed on wooden pallets, so we need strong volunteers to help us get this heavy food into the back of the truck using a pallet jack. (Don't worry if you've never used one before, we can show you how they work!)\n\nOnce the pallets are on the truck, we will need your help taking down the other heavy items we have like our tables, chairs, and tents and getting everything cleared out of the parking lot. To volunteer for this, please make sure you're able to carry at least 25 lbs.",
            serviceTags: ["body"],
            locationId: 84,
            resourceMinNumber: 2,
            resourceDesiredNumber: 3,
            resourceMaxNumber: 4,
            agePolicyIndex: 0,
            equipment: "Gloves and steel-toed boots",
            giverName: "Lori Collins"
          }
        ],
        bundles: []
      },
      hardcodedStampsForNewLifePantry: {
        stamps: [
          {
            index: 0,
            selected: false,
            stampTitle: "Unload Food Delivery",
            icon: "stamp_truck_01",
            eventType: "OSV",
            eventTitle: "Unload Food Delivery",
            description:
              "Every month on the day before our 4th Saturday food distribution, our pantry receives a large delivery of non-perishable food, produce, frozen meat, and hygienic supplies. We need volunteers to carry these items into our pantry and stock the shelves.\n\nWe'll consolidate the food in our refrigerators so there's room to add more, and then take out any remaining trash.",
            serviceTags: ["body"],
            locationId: 87,
            resourceMinNumber: 2,
            resourceDesiredNumber: 3,
            resourceMaxNumber: 5,
            agePolicyIndex: 0,
            equipment: "Gloves",
            giverName: "James Dann"
          },
          {
            index: 1,
            selected: false,
            stampTitle: "Distribute Food",
            icon: "stamp_people_01",
            eventType: "OSV",
            eventTitle: "Distribute Food",
            description:
              "We need volunteers to help us manage our clients during distribution days. First, we'll do any last minute necessary preparation (taking frozen meat out of the freezer, reviewing the limits on certain items with volunteers, etc) before the doors open at 12 noon. Our pantry is a free choice model, which means our clients can choose which items they want to take.\n\nWe'll put you in charge of a designated food category to hand out. You will be in charge of replenishing your supply during the day with the items under the table, and in our back room. We also need volunteers to help carry groceries to our neighbors' cars. One volunteer will be assigned to handling the client paperwork we need to fill out for the food bank, which is entered into the computer manually.\n\nPlease note that all volunteers must remain standing during the entire distribution day, and be able to carry 25 lbs of weight up a short flight of stairs.",
            serviceTags: ["food"],
            locationId: 87,
            resourceMinNumber: 1,
            resourceDesiredNumber: 2,
            resourceMaxNumber: 3,
            agePolicyIndex: 0,
            equipment: "Comfortable shoes",
            giverName: "James Dann"
          },
          {
            index: 2,
            selected: false,
            stampTitle: "Translate Spanish",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Translate Spanish",
            description:
              "We need volunteers to help us manage our clients during distribution days. During distribution, we need a few bilingual volunteers who speak fluent English and Spanish to help us register new clients and communicate with anyone who doesn't speak English.\n\nPlease note that all volunteers must remain standing during the entire distribution day.",
            serviceTags: ["speech"],
            locationId: 87,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 1,
            agePolicyIndex: 0,
            equipment: "Comfortable shoes",
            giverName: "James Dann"
          }
        ],
        bundles: []
      },
      hardcodedStampsForMomsFoodPantryBergenfield: {
        stamps: [
          {
            index: 0,
            selected: false,
            stampTitle: "Food Bank Delivery",
            icon: "stamp_truck_01",
            eventType: "OSV",
            eventTitle: "Food Bank Delivery",
            description:
              "We're expecting our monthly shipment of food from the Community Food Bank of New Jersey. This is a very large delivery, usually around 5 pallettes of food. Help us bring the food inside, organize the delivery, and put everything away. While we await the delivery, we'll make room on our shelves for the incoming canned goods.\n\nPlease note that this quest involves carrying 25 pounds and walking up a short flight of stairs into our storefront pantry!",
            serviceTags: ["body"],
            locationId: 90,
            resourceMinNumber: 2,
            resourceDesiredNumber: 3,
            resourceMaxNumber: 5,
            agePolicyIndex: 0,
            equipment: "Closed-toed shoes, work gloves",
            giverName: "Shahrin Hoque"
          },
          {
            index: 1,
            selected: false,
            stampTitle: "Table to Table",
            icon: "stamp_truck_01",
            eventType: "OSV",
            eventTitle: "Produce Delivery",
            description:
              "We're expecting our weekly shipment of fresh produce from a volunteer driver from Table to Table. Help us bring the food inside, organize the delivery, and put everything away. While we await the delivery, we'll make room on our shelves for the incoming produce.\n\nPlease note that this quest involves carrying 25 pounds and walking up a short flight of stairs into our storefront pantry!",
            serviceTags: ["body"],
            locationId: 90,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 2,
            agePolicyIndex: 0,
            equipment: "Closed-toed shoes, work gloves",
            giverName: "Shahrin Hoque"
          },
          {
            index: 2,
            selected: false,
            stampTitle: "Distribute Food",
            icon: "stamp_shelf_01",
            eventType: "OSV",
            eventTitle: "Distribute Food",
            description:
              "Every week, our pantry opens its doors to the community to distribute free food. We're open from 10 am to 1 pm and our pantry operates according to a free-choice model, so we welcome our neighbors inside to choose the items from our shelves that they want for their families.\n\nBecause our pantry is in a small urban storefront without a ramp, we need volunteers to help carry our neighbors' carts in and out of the pantry. One volunteer will be posted inside the main food room to politely remind clients of our item limits when necessary. Other volunteers will be posted at the main table to handle all of the check-in paperwork that is required, or on line management duty to make sure that our neighbors don't interfere with the businesses next door.\n\nWhen distribution is over, we will reset the pantry for our next delivery day before locking up.",
            serviceTags: ["food"],
            locationId: 90,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 3,
            agePolicyIndex: 0,
            equipment: "Closed-toed shoes, work gloves",
            giverName: "Shahrin Hoque"
          },
          {
            index: 3,
            selected: false,
            stampTitle: "Translate Spanish",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Translate Spanish",
            description:
              "Every week, our pantry opens its doors to the community to distribute free food. We're open from 10 am to 1 pm and our pantry operates according to a free-choice model, so we welcome our neighbors inside to choose the items from our shelves that they want for their families.\n\nDuring distribution we need at least one volunteer who can speak Spanish to help check in new clients and sign in existing ones. There is a sizeable Spanish-speaking population in our area of Bergenfield and no one at our pantry speaks the language. You will help us with the necessary paperwork that we need to fill out for the government and private charitable food banks. You may also accompany a neighbor as he or she picks food items to translate the names of specific food items.",
            serviceTags: ["speech"],
            locationId: 90,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 1,
            agePolicyIndex: 0,
            equipment: "Closed-toed shoes",
            giverName: "Shahrin Hoque"
          },
          {
            index: 4,
            selected: false,
            stampTitle: "Translate Arabic",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Translate Arabic",
            description:
              "Every week, our pantry opens its doors to the community to distribute free food. We're open from 10 am to 1 pm and our pantry operates according to a free-choice model, so we welcome our neighbors inside to choose the items from our shelves that they want for their families.\n\nDuring distribution we need at least one volunteer who can speak Arabic to help check in new clients and sign in existing ones. There is a sizeable Arabic-speaking population in our area of Bergenfield and no one at our pantry speaks the language. You will help us with the necessary paperwork that we need to fill out for the government and private charitable food banks. You may also accompany a neighbor as he or she picks food items to translate the names of specific food items.",
            serviceTags: ["speech"],
            locationId: 90,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 1,
            agePolicyIndex: 0,
            equipment: "Closed-toed shoes",
            giverName: "Shahrin Hoque"
          },
          {
            index: 5,
            selected: false,
            stampTitle: "Translate Filipino",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Translate Filipino",
            description:
              "Every week, our pantry opens its doors to the community to distribute free food. We're open from 10 am to 1 pm and our pantry operates according to a free-choice model, so we welcome our neighbors inside to choose the items from our shelves that they want for their families.\n\nDuring distribution we need at least one volunteer who can speak Filipino to help check in new clients and sign in existing ones. There is a sizeable Filipino-speaking population in our area of Bergenfield and no one at our pantry speaks the language. You will help us with the necessary paperwork that we need to fill out for the government and private charitable food banks. You may also accompany a neighbor as he or she picks food items to translate the names of specific food items.",
            serviceTags: ["speech"],
            locationId: 90,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 1,
            agePolicyIndex: 0,
            equipment: "Closed-toed shoes",
            giverName: "Shahrin Hoque"
          },
          {
            index: 6,
            selected: false,
            stampTitle: "Restock Shelves",
            icon: "stamp_truck_01",
            eventType: "OSV",
            eventTitle: "Restock Shelves",
            description:
              "Every week, our pantry opens its doors to the community to distribute free food. We need volunteers to help us organize the shelves to prepare for our next distribution and keep the center aisle clear so our neighbors can walk inside with their carts and pick items.\n\nYou'll help us rotate our canned goods so that products closer to their expiration date are moved out first. We'll also throw out anything that is expired or severely dented.\n\nAnything that was not put away during a food delivery will be placed on the proper rack and section in our market.",
            serviceTags: ["food"],
            locationId: 90,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 2,
            agePolicyIndex: 0,
            equipment: "Closed-toed shoes, work gloves",
            giverName: "Shahrin Hoque"
          },
          {
            index: 7,
            selected: false,
            stampTitle: "Event Table",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Event Table",
            description:
              "Mom's Food Pantry is going to be tabling at the NAME OF EVENT. Help our pantry leadership team tell the community about our ways to support and volunteer with our charity, as well as talk about SOME OTHER UPCOMING THING. The table will also be staffed with a Mom's Food Pantry leadership team member.\n\nGood for anyone comfortable talking to market goers about our programs. Talking points & pantry info materials will be provided to you!",
            serviceTags: ["speech"],
            locationId: 90,
            resourceMinNumber: 1,
            resourceDesiredNumber: 1,
            resourceMaxNumber: 1,
            agePolicyIndex: 0,
            equipment: "Clothing without other logos / emblems",
            giverName: "Shahrin Hoque"
          }
        ],
        bundles: []
      },
      hardcodedStampsForZionCaresJC: {
        stamps: [
          {
            index: 0,
            selected: false,
            stampTitle: "CFBNJ Delivery",
            icon: "stamp_truck_01",
            eventType: "OSV",
            eventTitle: "Food Bank Delivery",
            description:
              "We're expecting our monthly shipment of food from the Community Food Bank of New Jersey. This is a very large delivery, usually around 8 pallettes of food. Help us bring the food inside, organize the delivery, and put everything away. While we await the delivery, we'll make room on our shelves for the incoming canned goods.\n\nPlease note that this quest involves carrying 25 pounds and walking up a short flight of stairs into our pantry!",
            serviceTags: ["body"],
            locationId: 91,
            resourceMinNumber: 8,
            resourceDesiredNumber: 10,
            resourceMaxNumber: 15,
            agePolicyIndex: 1,
            equipment: "Closed-toed shoes, work gloves",
            giverName: "Tanisha Wright"
          },
          {
            index: 1,
            selected: false,
            stampTitle: "Meat Delivery",
            icon: "stamp_truck_01",
            eventType: "OSV",
            eventTitle: "Meat Delivery",
            description:
              "We're expecting our fresh produce and frozen meat. If this happens during a distribution day, you'll help us arrange it on our tables properly.\n\nIf it's not a distribution day, then you can help us bring the food inside, organize the delivery, and put everything away. While we await the delivery, we'll make room on our shelves for the incoming food.\n\nPlease note that this quest involves carrying 25 pounds and walking up a short flight of stairs into our pantry!",
            serviceTags: ["body"],
            locationId: 91,
            resourceMinNumber: 1,
            resourceDesiredNumber: 3,
            resourceMaxNumber: 5,
            agePolicyIndex: 0,
            equipment: "Closed-toed shoes, work gloves",
            giverName: "Tanisha Wright"
          },
          {
            index: 2,
            selected: false,
            stampTitle: "Distribute Food",
            icon: "stamp_people_01",
            eventType: "OSV",
            eventTitle: "Distribute Food",
            description:
              "Once a month, our church opens its doors to the community to distribute free food. We're open from 10 am to 1 pm and our pantry operates according to a free-choice model, so we welcome our neighbors to choose the items from our tables that they want for their families.\n\nWe begin the day by setting up around 20 different tables that will hold the various food categories.\n\nSince people line up early, we'll assess how much of each item everyone can take by counting the people in line. When distribution begins, you will be assigned to a station so you can hand food to clients. (For many reasons, we don't want them grabbing the items themselves). Strong volunteers will be assigned to place food into people’s cars or grocery carts.\n\nAs we wind down, we will all clean up any trash on our sidewalk, and break down all the cardboard boxes.",
            serviceTags: ["food"],
            locationId: 91,
            resourceMinNumber: 30,
            resourceDesiredNumber: 35,
            resourceMaxNumber: 40,
            agePolicyIndex: 0,
            equipment: "Closed-toed shoes, work gloves",
            giverName: "Tanisha Wright"
          },
          {
            index: 3,
            selected: false,
            stampTitle: "Translate Spanish",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Translate Spanish",
            description:
              "Once a month, our church opens its doors to the community to distribute free food. During distribution we need volunteers who can speak Spanish to help check in new clients and sign in existing ones. There is a sizeable Spanish-speaking population in our area of Jersey City and not many members of our church speak the language. You will also help us with the necessary digital filing that we need to fill out for the government and private charitable food banks. (Don't worry about your computer skill - the process is very easy and we will show you how it works).\n\nAt least one volunteer will walk through the line, helping new clients with their first-time registration.",
            serviceTags: ["speech"],
            locationId: 91,
            resourceMinNumber: 1,
            resourceDesiredNumber: 2,
            resourceMaxNumber: 3,
            agePolicyIndex: 0,
            equipment: "Comfortable shoes",
            giverName: "Tanisha Wright"
          },
          {
            index: 4,
            selected: false,
            stampTitle: "Translate Arabic",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Translate Arabic",
            description:
              "Once a month, our church opens its doors to the community to distribute free food. During distribution we need volunteers who can speak Arabic to help check in new clients and sign in existing ones. There is a sizeable Arabic-speaking population in our area of Jersey City and not many members of our church speak the language. You will also help us with the necessary digital filing that we need to fill out for the government and private charitable food banks. (Don't worry about your computer skill - the process is very easy and we will show you how it works).\n\nAt least one volunteer will walk through the line, helping new clients with their first-time registration.",
            serviceTags: ["speech"],
            locationId: 91,
            resourceMinNumber: 1,
            resourceDesiredNumber: 2,
            resourceMaxNumber: 3,
            agePolicyIndex: 0,
            equipment: "Comfortable shoes",
            giverName: "Tanisha Wright"
          },
          {
            index: 5,
            selected: false,
            stampTitle: "Translate Creole",
            icon: "stamp_speech_01",
            eventType: "OSV",
            eventTitle: "Translate Creole",
            description:
              "Once a month, our church opens its doors to the community to distribute free food. During distribution we need volunteers who can speak Creole to help check in new clients and sign in existing ones. There is a sizeable Creole-speaking population in our area of Jersey City and not many members of our church speak the language. You will also help us with the necessary digital filing that we need to fill out for the government and private charitable food banks. (Don't worry about your computer skill - the process is very easy and we will show you how it works).\n\nAt least one volunteer will walk through the line, helping new clients with their first-time registration.",
            serviceTags: ["speech"],
            locationId: 91,
            resourceMinNumber: 1,
            resourceDesiredNumber: 2,
            resourceMaxNumber: 3,
            agePolicyIndex: 0,
            equipment: "Comfortable shoes",
            giverName: "Tanisha Wright"
          }
        ],
        bundles: []
      },
      hardcodedStampsForTransitionProfessionals: {
        stamps: [
          {
            index: 0,
            selected: false,
            stampTitle: "Sort Clothing",
            icon: "stamp_shelf_01",
            eventType: "OSV",
            eventTitle: "Sort Clothing",
            description:
              "Our location has two closets (one for gentlemen, one for ladies) for individuals in need in the community, like those transitioning from incarceration, homeless, residing in a family shelter, or a domestic violence shelter. Our dedicated staff works every day to reduce recidivism and provide second chances. Part of our services include giving our clients clothing.\n\nWe need volunteers to come help us keep these closets tidy. You'll help fold clothing, organize them on shelves and racks, and help our clients find their sizes. Sometimes you may need to get specific items from our clothing storage room on the third floor. Once the closets are clean, we will move on to that third-floor room to organize it.\n\nWe also need volunteers to sort incoming donations and discard anything we cannot distribute. People typically donate men's clothes, women's clothes, bed linens, and other household goods.",
            serviceTags: ["garment"],
            locationId: 88,
            resourceMinNumber: 1,
            resourceDesiredNumber: 2,
            resourceMaxNumber: 3,
            agePolicyIndex: 0,
            equipment: "",
            giverName: "Emily Shinn"
          },
          {
            index: 1,
            selected: false,
            stampTitle: "Yard Sale (Setup)",
            icon: "stamp_box_01",
            eventType: "OSV",
            eventTitle: "Set Up Yard Sale",
            description:
              "We're having a Yard Sale to raise money for our charity! We will be selling lots of women's clothing, and some other household goods. All of our clothing is upstairs on the third floor and it has to get safely down the steps to our yard. We need strong volunteers to help us carry all of this heavy stuff. Once it's all downstairs we will need your help setting up display tables and racks for the yard sale.",
            serviceTags: ["body"],
            locationId: 88,
            resourceMinNumber: 2,
            resourceDesiredNumber: 3,
            resourceMaxNumber: 4,
            agePolicyIndex: 0,
            equipment: "",
            giverName: "Emily Shinn"
          },
          {
            index: 2,
            selected: false,
            stampTitle: "Yard Sale",
            icon: "stamp_people_01",
            eventType: "OSV",
            eventTitle: "Yard Sale",
            description:
              "We're having a Yard Sale to raise money for our charity! We will be selling lots of women's clothing, and some other household goods that we cannot give to our clients. We need volunteers to help us keep an eye on all of the items, attend to customers, and perform financial transactions.",
            serviceTags: ["treasure"],
            locationId: 88,
            resourceMinNumber: 2,
            resourceDesiredNumber: 3,
            resourceMaxNumber: 4,
            flexibleMinimumTime: 120,
            agePolicyIndex: 0,
            equipment: "",
            giverName: "Emily Shinn"
          },
          {
            index: 3,
            selected: false,
            stampTitle: "Yard Sale (End)",
            icon: "stamp_box_01",
            eventType: "OSV",
            eventTitle: "Tear Down Yard Sale",
            description:
              "After our Yard Sale ends, we need strong volunteers to help us carry all of the remaining items back inside. The clothing needs to be returned to the third floor where we warehouse all of the excess clothing that people donate to our charity. Once it's all upstairs we will need your help tearing down display tables and bringing miscellaneous items back inside the office.",
            serviceTags: ["body"],
            locationId: 88,
            resourceMinNumber: 2,
            resourceDesiredNumber: 3,
            resourceMaxNumber: 4,
            agePolicyIndex: 0,
            equipment: "",
            giverName: "Emily Shinn"
          }
        ],
        bundles: []
      },
      hardcodedStampsForStFrancisHoboken: {
        stamps: [
          {
            index: 0,
            selected: false,
            stampTitle: "Bag Preparation",
            icon: "stamp_bag_01",
            eventType: "OSV",
            eventTitle: "Bag Preparation",
            description:
              "Our church runs a food pantry in honor of our late pastor, Fr. Michael, thanks to the donations of our parishioners. Once a month, our church opens up the food pantry to anyone in the community who needs assistance.\n\nTo prepare for distribution day, we need volunteers to help pack bags full of non-perishable groceries. After we finish, we'll organize the pantry to prepare for distribution and tidy up.",
            serviceTags: ["food"],
            locationId: 40,
            resourceMinNumber: 2,
            resourceDesiredNumber: 3,
            resourceMaxNumber: 4,
            agePolicyIndex: 0,
            equipment: "",
            giverName: "Theresa Howard"
          },
          {
            index: 1,
            selected: false,
            stampTitle: "Food Distribution",
            icon: "stamp_people_01",
            eventType: "OSV",
            eventTitle: "Food Distribution",
            description:
              "Our church runs a food pantry in honor of our late pastor, Fr. Michael, thanks to the donations of our parishioners. Once a month, our church opens up the food pantry to anyone in the community who needs assistance.\n\nWe need volunteers to help with some setup tasks that can only be done on distribution day. Then, some volunteers will handle check in, others will focus on distributing food, and some will help with translating / maintaining order in the line. After we close for the day, we need help putting everything away and organizing the pantry.",
            serviceTags: ["food"],
            locationId: 40,
            resourceMinNumber: 4,
            resourceDesiredNumber: 6,
            resourceMaxNumber: 8,
            agePolicyIndex: 1,
            equipment: "",
            giverName: "Theresa Howard"
          }
        ],
        bundles: []
      },
      hardcodedStampsForTestProvider: {
        stamps: [
          {
            index: 0,
            selected: false,
            stampTitle: "Weapons",
            icon: "stamp_truck_01",
            eventType: "OSV",
            eventTitle: "Weapon Delivery",
            description:
              "A big truck is coming to give us a shipment of swords, axes, lances, bows, and other weapons.\n\nKeep your head on a swivel, volunteers!",
            serviceTags: ["war"],
            flexibleMinimumTime: null,
            locationId: 28,
            resourceMinNumber: 3,
            resourceDesiredNumber: 4,
            resourceMaxNumber: 8,
            agePolicyIndex: 0,
            equipment: "Gloves and a back brace",
            giverName: "Frank DiCola"
          },
          {
            index: 1,
            selected: false,
            stampTitle: "Potions",
            icon: "stamp_shelf_01",
            eventType: "OSV",
            eventTitle: "Organize Potions",
            description:
              "Our potion collection is a complete mess. We need people to organize the potions by type and put them on the shelf.",
            serviceTags: ["toxin"],
            locationId: 28,
            resourceMinNumber: 3,
            resourceDesiredNumber: 4,
            resourceMaxNumber: 5,
            agePolicyIndex: 0,
            equipment: "Gloves and eyewear",
            giverName: "Frank DiCola"
          }
        ],
        bundles: []
      }
    };
  },
  setup(props) {
    console.log("Stamps setup.");

    // const notifyError = text => {
    //   toast.error(text, {
    //     autoClose: false
    //   }); // ToastOptions
    // };

    // const notifySuccess = text => {
    //   toast.success(text, {
    //     autoClose: 10000
    //   }); // ToastOptions
    // };

    console.log("What's our Provider ID??");

    const route = useRoute();
    // const { result, loading, error, refetch } = useQuery(
    // gql`
    //   query GetSpecificQuests($GQI: GetQuestsInput!) {
    //     getSpecificQuests(getQuestsInput: $GQI) {
    //       id
    //       provider
    //       eventType
    //       publishingStatus
    //       eventTitle
    //       description
    //       serviceTags
    //       startedAt
    //       endedAt
    //       flexibleMinimumTime
    //       locationId
    //       location {
    //         id
    //         name
    //         address
    //         detail
    //         latitude
    //         longitude
    //       }
    //       resourceMaxNumber
    //       giverName
    //     }
    //   }
    // `,
    // Variables
    //   {
    //     GQI: {
    //       specificProvider: "814AE59680A238D2",
    //       isTemplate: true
    //     }
    //   }
    // );
    // console.log("Refetching Templates!");
    // console.log(result);
    // console.log("Can we see the Quest Templates we refetched?");
    // console.log(error);
    const { cookies } = new useCookies();
    const { store } = useStore();
    return {
      id: route.params.id,
      cookies,
      store
    };
  },
  methods: {
    async queryProvider(providerID) {
      // const { result, loading } = useQuery(gql`
      //   {
      //     provider(id:"${providerID}"){
      //     questConfiguration
      //       }
      //   }
      // `); //refactor to not use useQuery
      const { data: provider } = await this.$apollo.query({
        query: gql`
          {
            provider(id: "${providerID}") {
              questConfiguration
            }
          }
        `
      });
      if (!provider.provider?.questConfiguration) {
        return null;
      }
      const { data: quests } = await this.$apollo.query({
        query: gql`
          query GetSpecificQuests($GQI: GetQuestsInput!) {
            getSpecificQuests(getQuestsInput: $GQI) {
              id
              eventType
              publishingStatus
              eventTitle
              description
              serviceTags
              startedAt
              endedAt
              flexibleMinimumTime
              locationId
              resourceMinNumber
              resourceDesiredNumber
              resourceMaxNumber
              agePolicyIndex
              equipment
              giverName
            }
          }
        `,
        variables: {
          GQI: {
            specificProvider: providerID,
            isTemplate: true
          }
        }
      });
      let config = JSON.parse(provider.provider?.questConfiguration);
      config.questConfiguration.stampBook.forEach(category => {
        category.stamps.forEach(stamp => {
          const quest = quests.getSpecificQuests.find(q => q.id === stamp.id);
          if (quest) {
            Object.keys(quest).forEach(key => {
              stamp[key] = quest[key];
            });
          }
        });
      });
      return config.questConfiguration;
    },
    handleStampClick(stamp, e) {
      console.log('Current Stamp was... "' + stamp.stampTitle + '"');

      // If we had a currently selected stamp, make it not selected anymore
      if (
        this.$store.state.currentlySelectedStamp != undefined &&
        this.$store.state.currentlySelectedStamp != null
      ) {
        this.$store.state.currentlySelectedStamp.selected = false;

        // Are we actually just deselecting a Stamp?
        if (
          this.$store.state.currentlySelectedStamp.stampTitle ==
          stamp.stampTitle
        ) {
          this.$store.state.currentlySelectedStamp = null;
          console.log(
            "This is a deselect action. Stamp is now " +
              this.$store.state.currentlySelectedStamp
          );
          return;
        }
      }

      stamp.selected = true;
      this.$store.state.currentlySelectedStamp = stamp;
      console.log('Current Stamp is now "' + stamp.stampTitle + '"');
    },
    async chooseStampCollection(providerID) {
      let theProviderID = null;

      if (providerID == null) {
        // We probably just loaded the scene. Everything is fine!
        theProviderID = this.selectedManagedProviderId;
        console.log("ProviderID is null, setting it to " + theProviderID);
      } else {
        // We probably chose a new provider from the drop down.
        theProviderID = providerID;
      }

      this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsEmpty;
      // console.log("Choosing a stamp collection...");
      console.log("Selecting the stamps for Provider " + theProviderID + "?");
      console.log("Querying the provider...");
      const config = await this.queryProvider(theProviderID);

      if (config != null) {
        // this.store.commit("SET_CURRENTLY_SELECTED_STAMPCONFIG", {
        //   stampConfig: config
        // });
        this.$store.state.currentlySelectedStampConfigFile = config;

        if (config) {
          // How about a function that takes...
          // The Provider config file (with the stampBook and the bundleBook)
          // This array of quest templates
          // And converts it into something that displays nicely?
          this.tryTheStampFormatWithoutDBMigrationYet();
        }
      } else {
        this.$store.state.currentlySelectedStampConfigFile = null;
        if (theProviderID == "6979BBE94C41FFA5")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForHobokenShelter;
        else if (theProviderID == "847F21FAD0FFC647")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForHCCPantry;
        else if (theProviderID == "4DD7296EFD66BEB9")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForC4P;
        else if (theProviderID == "B092AC88B3BBFC01")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForC4PMassachusetts;
        else if (theProviderID == "32666A142E725B16")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForDvineKonektion;
        else if (theProviderID == "14D28C7C8E55F14A")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForTheOpenDoorNJNY;
        else if (theProviderID == "20527FEBCC2CDFC5")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForStFrancisHoboken;
        else if (theProviderID == "29A2D4143AD32231")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForTempleBethElJC;
        else if (theProviderID == "1BBBF60741F9E7F3")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForAmazingTruthSociety;
        else if (theProviderID == "7F6860372DB1C8E0")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForStMaryAdvocates;
        else if (theProviderID == "EEE6EFD9AF6AA248")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForVolunteerFoodRescue;
        else if (theProviderID == "5378D57B798F2822")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForLetsCelebrate;
        else if (theProviderID == "AC0D97CB181D32B8")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForGoodSamaritanGroup;
        else if (theProviderID == "C0AB2EFF8CF5A32")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForCrossRoadFoundation;
        else if (theProviderID == "3B9DE08B449B39D0")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForStatenIslandCatholicWorker;
        else if (theProviderID == "946902D69E55037D")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForSistersOfLife;
        else if (theProviderID == "DA683CCDD07621BE")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForStPhilipNeriBronxNY;
        else if (theProviderID == "53E56B7FF5ABE676")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForShirleyLArmstrongScholarshipFund;
        else if (theProviderID == "779B67D089D0E093")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForForMyGoodWorks;
        else if (theProviderID == "1312EC849B2C70D2")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForRadiantChurchPopUpFoodPantry;
        else if (theProviderID == "F14001AE1164A6F8")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForNewLifePantry;
        else if (theProviderID == "911E74C3DAF9F8B6")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForMomsFoodPantryBergenfield;
        else if (theProviderID == "A484E05A5358B7CF")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForZionCaresJC;
        else if (theProviderID == "AA315C88EF59E453")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForTransitionProfessionals;
        else if (theProviderID == "FD0320648E13DFB1")
          this.$store.state.currentlySelectedStampCollection = this.hardcodedStampsForTestProvider;
      }
    },
    tryTheStampFormatWithoutDBMigrationYet() {
      // Go through the Stamp Book (and the Bundle Book!) and prepare them.
      // PRETEND WE DOWNLOADED THE STAMP CONFIG FROM DB

      // This code is not running for some reason...
      for (
        let i = 0;
        i <
        this.$store.state.currentlySelectedStampConfigFile?.stampBook?.length;
        i++
      ) {
        for (
          let j = 0;
          j <
          this.$store.state.currentlySelectedStampConfigFile.stampBook.stamps
            ?.length;
          j++
        ) {
          this.$store.state.currentlySelectedStampConfigFile.stampBook[
            i
          ].stamps[j].selected = false;

          if (
            this.$store.state.currentlySelectedStampConfigFile.stampBook[i]
              .stamps[j].stampTitle == null
          )
            this.$store.state.currentlySelectedStampConfigFile.stampBook[
              i
            ].stamps[j].stampTitle = "Untitled";
        }
      }
      console.log(this.$store.state.currentlySelectedStampConfigFile);
    }
  },
  computed: {
    currentlySelectedStampConfigFile: {
      get() {
        return this.$store.state.currentlySelectedStampConfigFile;
      },
      set(value) {
        this.$store.state.currentlySelectedStampConfigFile = value;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
